


import * as fromJukeboxData from '../actions/jukebox-data.actions';
import { JukeboxData } from '../../models/jukebox.model';


export interface JukeboxDataState {
  jukeboxData: JukeboxData;
}

const initState: JukeboxDataState = {
  jukeboxData: null
};

export function jukeboxDataReducer( 
                  state = initState, 
                  action: fromJukeboxData.Actions ): JukeboxDataState {

    switch ( action.type ) {

        case fromJukeboxData.SET_JUKEBOX_DATA:
          
            return {
              jukeboxData: {
                ...action.jukeboxData
              }
            };
    
        case fromJukeboxData.UPDATE_JUKEBOX_DATA:

            return {
                ...state
            };

        case fromJukeboxData.UNSET_JUKEBOX_DATA:

            return {
              jukeboxData: null
            };

        default:
            return state;
    }
}