
<div class="container">

  <br>

  <div class="row">

    <div class="col-1">
      <a class="navbar-brand" routerLink="/admin/jukebox-music">
        <i class="fa fa-arrow-circle-left fa-2x" style="cursor: pointer;" aria-hidden="true"></i>
      </a>
    </div>
    <div class="col-11">
      <h2>&nbsp;&nbsp; Elige la playlist</h2>
    </div>
  </div>
    
  <div *ngIf="isLoading" class="container text-center">
    <div class="spinner-border" role="status" style="color: purple">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  
  <div *ngIf="!isLoading">    

    <div class="row">
      <div class="col-12">
        <form [formGroup]="playlistConfigForm"
              (ngSubmit)="onPlaylistConfigFormSubmit()">
          
          <br>
          <ul class="list-group">
              <li class="list-group-item" *ngFor="let playlist of playlists" style="color: black">
                  <!--<div class="form-check"> </div> (click)="savePlaylist(playlist)"  -->
                  &nbsp; <input class="form-check-input position-static" type="radio" [id]="playlist.name" name="playlist" formControlName="playlist" [value]="playlist">
                  <label class="form-check-label" [for]="playlist.name">&nbsp; {{ playlist.name }}</label>
                  <span class="badge float-right" style="text-align: right">({{ playlist.tracks.total }})</span>
              </li>
          </ul>

          <button type="submit" class="btn btn-primary float" [disabled]="!playlistConfigForm.valid">Aceptar</button>  
        </form>
      </div>
    </div>    
  </div>
</div>

