import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from 'src/app/shared/services/admin.service';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  showSidebar: boolean = false;

  toggleSidebarSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger, 
               private store: Store<AppState>,
               private adminService: AdminService ) { }

  ngOnInit() {

    this.adminService.initAdminListener();
    
    this.toggleSidebarSubscription =  this.store.select('ui')
        .subscribe( ui => {
          this.showSidebar = ui.showSidebar;
        });
  }


  /*********************
   *** LIFECYCLE   ****
   *********************/

  ngOnDestroy() {
    this.toggleSidebarSubscription.unsubscribe();
  }

}
