


<!-- <div class="row" style="color: whitesmoke">
  <div class="col-12 col-sm-12 col-md-12">
    
    <div class="footer-copyright pull-right text-center py-3">
      <a href="mailto:hola@paviado.com?Subject=Hola%20Rockers" target="_top">        
          <i class="fas fa-envelope-square fa-3x"></i>
      </a>
    </div>
  </div>    
</div> -->
<footer class="main-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <p>Paviado &copy; 2019</p>
      </div>
      <div class="col-sm-6 text-right">
        <p>Podemos mejorar! <a href="mailto:hola@paviado.com?Subject=Idea!" target="_top">Envía tus ideas aquí por favor</a></p>
      </div>
    </div>
  </div>
</footer>