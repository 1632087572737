import { Routes } from '@angular/router';
import { JukeboxConfigComponent } from './jukebox-config/jukebox-settings/jukebox-config.component';
import { ProfileComponent } from './profile/profile.component';
import { PlaylistsComponent } from './spotify/playlists/playlists.component';
import { JukeboxTracksComponent } from './jukebox-config/jukebox-tracks/jukebox-tracks.component';
import { PromoCodesComponent } from './jukebox-config/promo-codes/promo-codes.component';
import { PromoImagesComponent } from './jukebox-config/promo-images/promo-images.component';
import { HomeAdminComponent } from './home/home-admin.component';
import { JukeboxMusicComponent } from './jukebox-config/jukebox-music/jukebox-music.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { SaleListComponent } from './sale-list/sale-list.component';
import { SubscriptionBasicGuard } from '../shared/guard/subscription-basic.guard';
import { HtmlEditorComponent } from './jukebox-config/mail-campaign/html-editor.component';


export const adminRoutes: Routes = [

  { path: '', component: HomeAdminComponent },

  { path: 'profile', component: ProfileComponent },
  { path: 'playlists', component: PlaylistsComponent },
  { path: 'jukebox-music', component: JukeboxMusicComponent },
  { path: 'jukebox-tracks', component: JukeboxTracksComponent },
  // Subscription required --> , canActivate: [SubscriptionBasicGuard]
  { path: 'monitor', component: SaleListComponent },
  { path: 'statistics', component: StatisticsComponent },
  { path: 'promo-codes', component: PromoCodesComponent },
  { path: 'promo-images', component: PromoImagesComponent },
  { path: 'mail-campaign', component: HtmlEditorComponent },
  { path: 'jukebox-config', component: JukeboxConfigComponent }
  
]