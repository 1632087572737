import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../../shared/services/app-config.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { SpotifyService } from '../../shared/services/spotify.service';

@Component({
  selector: 'app-main-panel',
  templateUrl: './main-panel.component.html',
  styleUrls: ['./main-panel.component.css']
})
export class MainPanelComponent implements OnInit, OnDestroy {

  showSidebar: boolean = false;
  uiSubscription: Subscription = new Subscription();

  constructor( private router: Router,
               private store: Store<AppState>,
               private spotifyService: SpotifyService,
               private appConfigService: AppConfigService ) { }

  ngOnInit() {

    if ( this.appConfigService.getSubdomain() == 'mi' ) {      
      this.router.navigateByUrl('/admin');
    }

    this.uiSubscription =  this.store.select('ui')
        .subscribe( ui => {
          this.showSidebar = ui.showSidebar;
        });

  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
  }

}
