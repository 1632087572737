export class PromoCode {
  
    id: string;
    code: string;
    content: string;
    used: boolean;
    usedDate: Date;
    createDate: Date;

    
    constructor() {
    }

    setFirebaseId() {
    }


    getId():string {
      return this.id;
    }

    setId(id:string) {
      this.id = id;
    }
    
    getCode():string {
      return this.code;
    }
    
    setCode(code:string) {
      this.code = code;
    }

    getContent():string {
      return this.content;
    }
    
    setContent(content:string) {
      this.content = content;
    }


}