import { AbstractControl } from '@angular/forms';
import { NgfireHelperService } from '../../../shared/services/ngfire-helper.service';

export class PromoCodeValidator {

  static validatePromoCode(ngFireHelperService: NgfireHelperService, partyId: string) {

    return (control: AbstractControl) => {      
      const promoCode = control.value;
      return ngFireHelperService.validatePromoCode(partyId, promoCode);
    }
  }
}