import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  signInForm: FormGroup;

  constructor(public authService: AuthService) { }

  ngOnInit() {

    this.signInForm = new FormGroup({
      'username': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required])
    });
  }


  /**
   * 
   */
  public onSignInFormSubmit() {

    if (this.signInForm.valid) {

      let username = this.signInForm.get('username').value;
      let password = this.signInForm.get('password').value;

      this.authService.SignIn(username, password);      
    }
  }

}
