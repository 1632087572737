import { Component, OnInit, OnDestroy } from '@angular/core';
import { Sale } from '../../shared/models/sale.model';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { LoadingChangeAction } from '../../shared/redux/actions/ui.actions';
import { filter } from 'rxjs/operators';
import { JukeboxData } from '../../shared/models/jukebox.model';

@Component({
  selector: 'app-sale-list',
  templateUrl: './sale-list.component.html',
  styleUrls: ['./sale-list.component.css']
})
export class SaleListComponent implements OnInit, OnDestroy {

  sales: Sale[] = [];
  jukeboxData: JukeboxData;
  isLoading: boolean = true;
  now = moment().locale('es');

  uiSubscription: Subscription = new Subscription();
  salesSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private adminService: AdminService ) { }

  ngOnInit() {

    this.store.dispatch( new LoadingChangeAction(true) );

    this.uiSubscription = this.store.select('ui')
        .subscribe( uiState => {
          this.isLoading = uiState.isLoading;          
        });

    this.jukeboxDataSubscription = this.store.select('jukeboxData')
        .pipe(
          filter( jukeboxState => jukeboxState.jukeboxData != null)
        )
        .subscribe( jukeboxState => {
          this.sales = [];
          this.jukeboxData = jukeboxState.jukeboxData;
          //let date = moment().subtract('days', 6);
          let date = moment().subtract('hours', 6);
          this.adminService.subscribeSales(this.jukeboxData.partiesId, date.toISOString());
        });

    this.salesSubscription = this.store.select('sales')
        .pipe(
          filter( salesState => salesState.sales != null)
        )
        .subscribe( salesState => {
          this.sales = salesState.sales;
        });   
  }

  logAnimation(_event) {
  }


  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.salesSubscription.unsubscribe();
    this.jukeboxDataSubscription.unsubscribe();
    this.adminService.unsubscribeSales();
  }


}
