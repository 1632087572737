

 
<div class="list-group-item list-group-item-action flex-column align-items-start ">

  <div class="d-flex">
    <div *ngIf="sale.lead">

      <div *ngIf="sale.song">
        <h6><span style="color: #000;"> Canción:  &nbsp; </span>
          <span>{{ sale.song.artists }} / {{ sale.song.name }}</span>       
        </h6> 
      </div>

      <div *ngIf="sale.promoCode">
        <h6><span style="color: #000;"> Código usado:  &nbsp; </span>
          <span>{{ sale.promoCode.code }}</span>
          <span style="color: darkred;" *ngIf="sale.promoCode.content == 'winer' || sale.promoCode.content == 'winner'">&nbsp;| Premiado</span>
          <!-- <span style="color: black;" *ngIf="sale.promoCode.content != 'winer' && sale.promoCode.content != 'winner'">&nbsp;| Vacío</span> -->
        </h6> 
      </div>

      <div *ngIf="sale.lead.name">
        <h6><span style="color: #000;">De: &nbsp;</span>
          <span> {{ sale.lead.name }}</span>       
        </h6> 
      </div>
      
      <!-- <div *ngIf="sale.lead.shortText">
        <h6><span style="color: #000;">Contacto: &nbsp;</span>
          <span> {{ sale.lead.shortText }}</span>
        </h6>
      </div> -->
      
      <div *ngIf="sale.lead.longText">
        <h6><span style="color: #000;">Mensaje: &nbsp;</span></h6>
        <h6 class="text-style">{{ sale.lead.longText }}</h6>
      </div>
      
    </div>

    <div *ngIf="sale.song"
         class="ml-auto p-2 text-muted">
        <small>{{ sale.date_transaction | date: 'dd/MM/yyyy HH:mm' }}</small> 
        <br>
        <img placement="left"
             ngbPopover="{{ sale.song.name }}"
             popoverTitle="{{ sale.song.artists }}"
             src="{{ sale.song.coverUrlSmall }}" alt="Canción" class="rounded-circle" style="cursor: pointer; max-width: 80px;">
    </div>    

  </div>

  <br>
  <div class="d-flex justify-content-end">
    <button (click)="onDeleteSale()"
            type="button" class="btn btn-labeled btn-danger">
      <span class="btn-label"><i class="fas fa-trash"></i></span>Borrar
    </button>
  </div>

</div>
