import { Song } from './song.model';
import { QueuedTrack } from './queued-track.model';

export class Sale {
  
    public id: string;
    public song: QueuedTrack;
    public songs: QueuedTrack[];
    public source: string;  // web, tablet, 
    public provider: string;  // promo_code, lead, 
    public date_transaction: string;
    public date: Date;    

    public promoCode: any;    
    public lead: any;

    public paypal_data: any;    
    public stripe_data: any;
    
    public isDeletedOnMonitor: boolean;
    public monitorStatus: string; // deleted, bookmark, 


    constructor() {
    }


}