import { Pipe, PipeTransform } from '@angular/core';
import { Sale } from '../models/sale.model';

@Pipe({
  name: 'saleFilter'
})
export class SaleFilterPipe implements PipeTransform {

  
  transform(items: Sale[], sale: string): any {
    
    if (!items || !sale) {
        return items;
    }
    // sale items array, items which match and return true will be kept, false will be filtered out
    return items.filter( (item: Sale) => {
      return !item.isDeletedOnMonitor == true && item.id;
    });

  }

}
