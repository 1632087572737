


<div class="container-fluid">

  <div *ngIf="isLoading || !jukeboxConfiguration" class="container text-center">
    <br><br>
    <div class="spinner-border" role="status" style="color: purple">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="progress" *ngIf="uploadProgress && isLoading">
    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" 
          [style.width]="(uploadProgress | async) + '%'" 
          [attr.aria-valuenow]="(uploadProgress | async)" 
          aria-valuemin="0" aria-valuemax="100">
    </div>
  </div>

  <div *ngIf="!isLoading && jukeboxConfiguration"
       class="container">  
    
    <br>

    <div class="row">
      <div class="col-12">
        <div>
          <h1>Pop Ups</h1> 
          <p>Estas imágenes aparecerán cada {{ timeElapsedPromoPopupSeconds }} segundos. Se recomienda un tamaño de 1080 × 1920.</p>

        </div>

        <div *ngIf="jukeboxPopupImages.length < MAX_POPUP_IMAGES"
             class="text-center">
          <div class="upload-btn-wrapper">
            <button class="btn-upload">Subir popup</button>
            <input type="file" name="file" accept=".png,.jpg" (change)="upload($event, 'img_popup')"/>
          </div>
        </div>
        
        <br>
        
        <div class="text-center">
          
          <div *ngIf="jukeboxConfiguration.imgs_url?.popups">
            <div *ngFor="let iAdImage of jukeboxPopupImages; let i = index"
                 class="card bg-transparent">
              <div style="width:100%; text-align:center">                
                <div class="img-wrap">
                  <span (click)="onClickDeletePopup(iAdImage.id)" class="close" style="cursor: pointer;"><i class="far fa-times-circle"></i></span>
                  <img src="{{ iAdImage.url }}" style="height: 300px; width: 200px;">
                </div>
              </div>
              <div class="card-body">
                <div class="upload-wrap text-center">
                  <button *ngIf="iAdImage.isActive" type="button" class="btn btn-success" (click)="onClickToogleIsActive(iAdImage.id)">Activa</button>
                  <button *ngIf="!iAdImage.isActive" type="button" class="btn btn-danger" (click)="onClickToogleIsActive(iAdImage.id)">Inactiva</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <br>

    <div class="row">      
      <div class="col-12">
        <h1>Diálogos</h1>
        <p>Esta imágen aparecerá como fondo de los formularios. Se recomienda un tamaño de 1080 × 1920 con fondo oscuro.</p>
        <div class="text-center">

          <div class="card bg-transparent" joyrideStep="step-15" [stepContent]="customJoyrideStepContent2">
            <h4 class="card-header bg-transparent">Modo Jukebox</h4>
            <div style="width:100%; text-align:center">
              <img class="card-img-top" src="{{ urlImageBk }}" alt="" style="height: 300px; width: 200px;" >
            </div>
            <div class="card-body">
              <div class="upload-btn-wrapper text-center">
                <button type="button" class="btn btn-primary">Cambiar</button>
                <input class="upload-btn input-wrap" type="file" name="file" accept=".png,.jpg" (change)="upload($event, 'img_dialog')">
              </div>
            </div>
          </div>
          
          <div class="card bg-transparent">
            <h4 class="card-header bg-transparent">Modo Publicidad</h4>
            <div style="width:100%; text-align:center">
              <img class="card-img-top" src="{{ urlImgAdsModeBk }}" alt="" style="height: 300px; width: 200px;" >
            </div>
            <div class="card-body">
              <div class="upload-btn-wrapper text-center">
                <button type="button" class="btn btn-primary">Cambiar</button>
                <input class="upload-btn" type="file" name="file" accept=".png,.jpg" (change)="upload($event, 'img_dialog_ad')">
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>

    <br>

    <div class="row">   
      <div class="col-12">
        <h1>Banners</h1>
        <p>Esta imagen aparecerá en la parte superior de las diferentes vistas</p>

        <div class="text-center">

          <div class="card bg-transparent">
            <h4 class="card-header bg-transparent">Ahora sonando</h4>
            <div style="width:100%; text-align:center">
              <img class="card-img-top" src="{{ urlImgBanner }}" alt="" style="width: 200px; height: 50px;" >
            </div>
            <div class="card-body">
              <p class="card-text" style="width: 200px;">Esta imágen aparecerá como banner en la vista princial. Se recomienda un tamaño de 1080 × 288.</p>
              <div class="upload-wrap text-center">
                <button type="button" class="btn btn-primary">Cambiar</button>
                <input class="upload-btn" type="file" name="file" accept=".png,.jpg" (change)="upload($event, 'img_banner')">
              </div>
            </div>
          </div>

          <div class="card bg-transparent">
            <h4 class="card-header bg-transparent">Playlist</h4>
            <div style="width:100%; text-align:center">
              <img class="card-img-top" src="{{ urlImgHeader }}" alt="" style="width: 200px; height: 50px;" >
            </div>
            <div class="card-body">
              <p class="card-text" style="width: 200px;">Esta imágen aparecerá sobre tu playlist. Se recomienda un tamaño de 1080 × 388</p>
              <div class="upload-wrap text-center">
                <button type="button" class="btn btn-primary">Cambiar</button>
                <input class="upload-btn" type="file" name="file" accept=".png,.jpg" (change)="upload($event, 'img_header')">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>


<ng-template #customJoyrideStepContent2>
  <i class="fas fa-images"></i>
  Aquí podrás cambiar las imágenes: 
  <ol>
    <li>Popup: Publicidad que aparece cada 40 segundos si no se está usando la pantalla.</li>
    <li>Diálogo: Es el fondo de los formularios.</li>
    <li>Banner: Se muestra de forma fija en la vista principal.</li>
    <li>Header: Aparece en la vista de selección de canción.</li>
  </ol>
</ng-template>