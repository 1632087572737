import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-spotify-callback',
  templateUrl: './spotify-callback.component.html',
})
export class SpotifyCallbackComponent implements OnInit {

  constructor( private route: ActivatedRoute,
               private router: Router ) { }

  ngOnInit() {

    this.route.fragment.subscribe( fragment => {

      const access_token = new URLSearchParams(fragment).get('access_token');
      const expires_in = new URLSearchParams(fragment).get('expires_in');

      const expiration_time = new Date();
      expiration_time.setSeconds(expiration_time.getSeconds() + +expires_in);

      if (access_token) {
          localStorage.setItem('spotify_token', access_token);
          localStorage.setItem('expires_in', expires_in);
          localStorage.setItem('expiration_time', expiration_time.toString());
      }

      this.router.navigate(['/admin']);
    });
  }

}
