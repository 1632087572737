import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UIService {

  loadingStateChanged = new Subject<boolean>();

  constructor( private toastr: ToastrService ) { }


  showSuccess(message: string) {
    this.toastr.success(message);
  }

  showError(message: string) {
    this.toastr.error(message, 'Upsss');
  }

  showWarning(message: string) {
    this.toastr.warning(message);
  }
}
