
<div class="card rounded-0" >

  <div class="song" style="cursor: pointer;">
        <!--img-thumbnail-->
        <img src="{{ track.album.images[0].url }}" class="img-responsive" 
                  style="height: 256px;width: 256px;" 
                  sizes="256px">

        <p class="card-title"><b>{{ track.name }}</b></p>
        <p class="card-text">{{ track.artists[0].name }}</p>
    </div>
    
</div>

