
<div class="container-fluid">
  <br>
  <div class="container">
    <a [routerLink]="['../']">
      <i class="fa fa-arrow-circle-left fa-3x" aria-hidden="true"></i>
    </a>
  </div>

  <br>

  <div *ngIf="isLoading" class="container text-center">
    <div class="spinner-border" role="status" style="color: purple">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  
  <div *ngIf="!isLoading && jukeboxConfiguration" class="container">
    <div class="row">
      <div class="col-12">
        <div class="card align-items-center">
          <div class="card-body">
            <h3 class="card-title">Gracias!</h3>
            <p class="card-text">{{ displayText }}</p>
            
            <a *ngIf="jukeboxConfiguration.instagramUser"
               (click)="clickOnInstagramLink()"
               [href]="getSantizeUrl('instagram://user?username=' + jukeboxConfiguration.instagramUser)">
               <hr>
               ¿Tienes Instagram? ¡Sígue a {{ jukeboxConfiguration.instagramUser }} aquí!
            </a>
            <br>
          </div>
        </div>
      </div>
    </div>    
  </div>

  <!-- RATING -->
  <div *ngIf="!isLoading && jukeboxConfiguration" class="container">
    <div class="row">
      <div class="col-12">
        <div class="card align-items-center">

          <div *ngIf="jukeboxConfiguration.ratingSiteUrl && isRateActiveForUser" 
                 class="text-center">
            <br>
            <h3 class="card-title">¿Qué tal tu experiencia?</h3>
            
            <ngb-rating [(rate)]="currentRate" max="5" [readonly]="alreadyClickRate" (click)="onClickRate()">
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100">&#9733;</span>
              </ng-template>
            </ngb-rating>
            
            <div *ngIf="alreadyClickRate">
              <hr>
              ¡Gracias por tu respuesta! <br>
              <div *ngIf="currentRate < 5">
                <a (click)="clickOnFeedbackLink()" 
                   routerLink="/feedback"> 
                   Por favor, dinos qué podemos mejorar <i class="fas fa-comments"></i>
                </a>
              </div>
              <div *ngIf="currentRate > 4">                
                <a (click)="clickOnRatingLink()"
                   [href]="getSantizeUrl(jukeboxConfiguration.ratingSiteUrl)">
                  ¿Nos ayudas con una valoración? 
                </a>
              </div>
            </div>

            <br>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

