
<header class="header">
  <nav class="navbar">
    <div class="container-fluid">

      <div class="navbar-holder d-flex align-items-center justify-content-between">
        <div class="navbar-header">
          <a id="toggle-btn" (click)="toggleSidebar()" class="menu-btn">
            <i class="fas fa-bars fa-2x"></i>
          </a>
          <a (click)="toggleSidebar()" class="navbar-brand">
            <div class="brand-text d-none d-md-inline-block">              
              <strong class="text-primary">PAVIADO</strong>
            </div>
          </a>
        </div>
        <div class="navbar-nav mr-auto" style="display: inline-block;">
          <a href="https://www.instagram.com/paviado_com/" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-instagram" style="color: white;padding: 10px;"></i>
          </a>
        </div>
        
        <ul *ngIf="this.jukeboxService.jukeboxes.length > 1 && isJukeboxSubscribed"
            class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
          <li class="nav-item">

            <!-- <span class="d-none d-sm-inline-block" style="color: white;">
              {{ jukeboxActiveDisplayName | truncate: 22 }}
            </span> -->

            <span (click)="onClickJukeboxChange()"
                  class="d-none d-sm-inline-block" style="color: white; cursor: pointer;">
              Volver
            </span>
            <!-- <a id="languages" rel="nofollow" style="color: white;"
              data-target="#" 
              data-toggle="dropdown" aria-haspopup="true" 
              aria-expanded="false" class="nav-link dropdown-toggle language cursor">
              
            </a>
            <ul aria-labelledby="languages" 
                class="dropdown-menu" 
                [ngClass]="{ 'show': isJukeboxListExpanded }">

              <li *ngFor="let jukebox of jukeboxes">
                <a (click)="onJukeboxSelect(jukebox.jukebox_data.id)"
                  rel="nofollow" 
                  class="dropdown-item"> 
                  <span>{{ jukebox.jukebox_configuration.displayName | truncate: 50 }}</span>
                </a>
              </li>
            </ul> -->
          </li>
        </ul>
       
      </div>

    </div>
  </nav>
</header>