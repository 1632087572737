


<app-sidebar></app-sidebar>

<div class="page " [ngClass]="{ 'active-sm': showSidebar }">

  <app-navbar></app-navbar>
    
  <router-outlet></router-outlet>

  <app-footer></app-footer>
  
</div>