import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { AppState } from 'src/app/app.reducers';
import { User } from '../../shared/models/user.model';
import { Jukebox, JukeboxConfig } from '../../shared/models/jukebox.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService } from '../../shared/services/admin.service';
import { UIService } from '../../shared/services/ui.service';
import { JukeboxRealtime } from '../../shared/models/jukebox-realtime.model';
import * as moment from 'moment';

@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.css']
})
export class HomeAdminComponent implements OnInit, OnDestroy {

  user: User;
  isLoading = false;

  jukeboxRealtime: JukeboxRealtime;
  jukeboxConfiguration: JukeboxConfig;

  realtimeStartedTimestamp: string;
  realtimeExpectedEndTimestamp: string;

  authSubscription: Subscription = new Subscription();
  jukeboxRealtimeSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();

  musicProviderForm: FormGroup;

  constructor( private alog: NGXLogger,     
               private uiService: UIService,         
               private store: Store<AppState>,
               private adminService: AdminService ) { }


  ngOnInit() {
    this.isLoading = true;

    this.musicProviderForm = new FormGroup({
      'username': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required])
    });    

    this.authSubscription = 
      this.store.select('auth')
        .pipe(
          filter( auth => auth.user != null )
        )
        .subscribe( auth => {
          this.user = auth.user;
          this.isLoading = false;
        });

    this.jukeboxConfigurationSubscription =  this.store.select('jukeboxConfiguration')
        .subscribe( (jukeboxState) => {
          this.jukeboxConfiguration = jukeboxState.jukeboxConfiguration;
        });

    this.jukeboxRealtimeSubscription =  this.store.select('jukeboxRealtime')
        .subscribe( (jukeboxState) => {
          this.jukeboxRealtime = jukeboxState.jukeboxRealtime;
          if (this.jukeboxRealtime && this.jukeboxRealtime.track && this.jukeboxRealtime.track.started_timestamps) {
            this.realtimeExpectedEndTimestamp = moment(this.jukeboxRealtime.track.started_timestamps).add(this.jukeboxRealtime.track.duration_ms, 'milliseconds').toString();
          }
        });
    
  }


  onJukeboxOnlineToogle() {
    this.jukeboxConfiguration.isJukeboxOnline = !this.jukeboxConfiguration.isJukeboxOnline;
    this.adminService.updateJukeboxConfig(this.jukeboxConfiguration);
  }


  /*********************
   *** LIFECYCLE   ****
   *********************/

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.jukeboxRealtimeSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
  }
}
