


import * as fromUI from '../actions/ui.actions';

export interface UIState {
    isLoading: boolean;
    showSidebar: boolean;
}

const initState: UIState = {
    isLoading: false,
    showSidebar: false
};

export function uiReducer( state = initState, 
                           action: fromUI.acciones ): UIState {

    switch ( action.type ) {

        case fromUI.LOADING_CHANGE:
            return {
                ...state,
                isLoading: action.loading
            };

        case fromUI.TOGGLE_SIDEBAR:
            return {
              ...state,
              showSidebar: !state.showSidebar
            };
    
        default:
            return state;
    }
}