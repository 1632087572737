


<app-sidebar-admin></app-sidebar-admin>

<div class="page " [ngClass]="{ 'active-sm': showSidebar }"> <!--active-sm-->
    
  <app-navbar-admin></app-navbar-admin>

  <router-outlet></router-outlet>

  <app-footer-admin></app-footer-admin>
  
</div>
