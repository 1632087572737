import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SongFilterPipe } from './pipes/song-filter.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AppRoutingModule } from '../app-routing.module';
import { SaleFilterPipe } from './pipes/sale-filter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AuthService } from './services/auth.service';
import { AuthAnonymousService } from '../users/shared/resolvers/auth-anonymous.resolver';



@NgModule({
  declarations: [
    SongFilterPipe,
    TruncatePipe,
    SaleFilterPipe,
    SafeHtmlPipe,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
  ],
  exports: [
    SongFilterPipe,
    SaleFilterPipe,
    TruncatePipe,
    SafeHtmlPipe,
  ]
})
export class SharedModule { }
