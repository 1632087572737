import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UIService } from '../../../../shared/services/ui.service';
import { JukeboxService } from '../../../../shared/services/jukebox.service';
import { Track } from '../../../../shared/models/track.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QueuedTrack } from '../../../../shared/models/queued-track.model';
import { Lead } from '../../../../shared/models/lead.model';
import { Sale } from '../../../../shared/models/sale.model';
import * as firebase from 'firebase/app';
import * as moment from 'moment';

@Component({
  selector: 'app-form-leads',
  templateUrl: './form-leads.component.html',
  styleUrls: ['./form-leads.component.css']
})
export class FormLeadsComponent implements OnInit {

  @Input() trackSelected: Track;
  @Input() partyId: string;

  leadBasicForm: FormGroup;

  analytics = firebase.analytics();

  constructor( private router: Router,
               private uiService: UIService,
               private jukeboxService: JukeboxService ) { }

  ngOnInit() {

    // Form LEADS song
    this.leadBasicForm = new FormGroup({
      'name': new FormControl(null),
      'email': new FormControl(null, [Validators.required, Validators.email]), 
      'terms': new FormControl(null, [Validators.requiredTrue]),
    });

  }


  /**
   * Request song with Basic Form. Save on database request
   */
  onLeadBasicFormSubmit() {
    
    this.uiService.loadingStateChanged.next(true);

    if (this.leadBasicForm.valid) {

      let addedQueueBy = this.leadBasicForm.get('name').value;
      let email = this.leadBasicForm.get('email').value.trim();
      const checkbox = this.leadBasicForm.get('terms').value;

      // QueuedTrack data
      const queuedTrack: QueuedTrack = new QueuedTrack();
      queuedTrack.isQueuedInSpotify = false;

      if (addedQueueBy && addedQueueBy.trim().length > 0) {
        queuedTrack.addedQueueBy = addedQueueBy;
      }      
      queuedTrack.name = this.trackSelected.name;
      queuedTrack.durationMs = this.trackSelected.duration_ms;
      queuedTrack.artists = this.trackSelected.artists[0].name;
      queuedTrack.trackUri = this.trackSelected.uri;
      queuedTrack.coverUrlSmall = this.trackSelected.album.images[0].url;
      queuedTrack.source = "web";

      this.jukeboxService.pushQueuedTrack( this.partyId, queuedTrack );

      // Sale class
      let lead: Lead = new Lead();
        
      lead.setName(addedQueueBy);
      lead.shortText = email;
      lead.email = email;

      var now = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');
      
      let sale: Sale = new Sale();
      sale.song = queuedTrack;
      sale.provider = "lead";
      sale.source = "web";
      sale.date_transaction = now;
      sale.lead = lead;

      // this.postSongBought(sale);
      this.jukeboxService.pushSale( this.partyId, sale );

      this.jukeboxService.setRequestTimeOnLocalStorage(now);

      this.analytics.logEvent('queued_track', { track_name: queuedTrack.name, track_artist: queuedTrack.artists,
                                                dialog_type: 'leads', input_name: queuedTrack.addedQueueBy, 
                                                input_shorttext: email, input_checkbox: checkbox, source: 'web' });
    
      // Redirect to thanks
      this.router.navigate(['/jukebox', this.jukeboxService.jukeboxIdSubscribed, 'thanks']);
    }
	}


}
