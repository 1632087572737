import { Injectable } from '@angular/core';
import { UIService } from './ui.service';
import { NgfireHelperService } from './ngfire-helper.service';
import { Track } from '../models/track.model';
import { Subscription } from 'rxjs';
import { QueuedTrack } from '../models/queued-track.model';
import { Sale } from '../models/sale.model';
import { Jukebox, JukeboxData, JukeboxConfig } from '../models/jukebox.model';
import { PromoCode } from '../models/promo-code.model';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { LoadingChangeAction } from '../redux/actions/ui.actions';
import { take } from 'rxjs/operators';
import * as moment from 'moment';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { SetJukeboxDataAction } from '../redux/actions/jukebox-data.actions';
import { SetJukeboxConfigurationAction } from '../redux/actions/jukebox-configuration.actions';
import { SetQueuedTracksAction } from '../redux/actions/queued-tracks.actions';


@Injectable({
  providedIn: 'root'
})
export class JukeboxService {
    
  public readonly PLAYLIST_ORDER_RANDOM: string = 'random';
  public readonly PLAYLIST_ORDER_ARTIST: string = 'artist';

  authSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();
  queuedTracksSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();

  jukeboxes: Jukebox[] = [];
  playlistTracks: Track[] = [];
  searchOpenTracks: Track[] = [];
  searchOpenSelectedTrack: Track;

  queuedTracks: QueuedTrack[]; // TODO Move to redux
  
  jukeboxIdSubscribed: string;
  analytics = firebase.analytics();

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private uiService: UIService,
               private ngFireService: NgfireHelperService ) { }


  cancellSubscriptions() {
    this.authSubscription.unsubscribe();
    this.jukeboxDataSubscription.unsubscribe();
    this.queuedTracksSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
  }

  subscribeJukeboxData(jukeboxId: string) {

    this.alog.debug('JukeboxService > subscribeJukeboxData', jukeboxId);

    this.jukeboxDataSubscription = this.ngFireService.subscribeJukeboxData(jukeboxId)
        .subscribe(
          (jukeboxData: JukeboxData) => {
            this.alog.debug('JukeboxService > subscribeJukeboxData response', jukeboxData);
            this.store.dispatch( new SetJukeboxDataAction(jukeboxData) );
            this.observeQueuedTracks(jukeboxData.partiesId);
            this.analytics.setUserProperties( { jukebox_id: jukeboxData.id } );
            this.analytics.setUserProperties( { party_id: jukeboxData.partiesId } );
            this.analytics.setUserProperties( { platform: 'web' } );
            this.analytics.setUserProperties( { company: jukeboxData.company } );
          },
          (err) => {
            this.alog.debug('JukeboxService > subscribeJukeboxData err', err);
          }
        );
  }

  subscribeJukeboxConfiguration(jukeboxId: string) {

    this.alog.debug('JukeboxService > subscribeJukeboxConfiguration', jukeboxId);

    this.jukeboxConfigurationSubscription = this.ngFireService.subscribeJukeboxConfiguration(jukeboxId)
        .subscribe(
          (jukeboxConfiguration: JukeboxConfig) => {
            this.alog.debug('JukeboxService > subscribeJukeboxConfiguration response', jukeboxConfiguration);
            this.store.dispatch( new SetJukeboxConfigurationAction(jukeboxConfiguration) );
            this.analytics.setUserProperties( { subdomain: jukeboxConfiguration.subdomain } );
          },
          (err) => {
            this.alog.debug('JukeboxService > subscribeJukeboxConfiguration err', err);
          }
        );
  }

  getJukebox(jukeboxesId: string): Promise<Jukebox> {

    this.alog.debug('JukeboxService > getJukebox', jukeboxesId);
    this.store.dispatch( new LoadingChangeAction(true) );

    var promise: Promise<Jukebox> = new Promise( (resolve, reject) => {

      this.ngFireService.getJukebox(jukeboxesId)
        .pipe(take(1))
        .subscribe(
          (response: any) => {
            let jukebox: Jukebox = response as Jukebox;           
            this.alog.debug('JukeboxService > getJukebox response', jukebox);
            this.store.dispatch( new LoadingChangeAction(false) ); 
            resolve(jukebox);
          },
          (err) => {
            this.alog.debug('JukeboxService > getJukebox err', err);
            this.store.dispatch( new LoadingChangeAction(false) );
            reject(err);
          }
        );
    });
    return promise;    
  }

  getPlaylistTracks(playlistId: string) {
    this.alog.debug('JukeboxService > getPlaylistTracks');
    this.store.dispatch( new LoadingChangeAction(true) );
    
    this.ngFireService.getPlaylistTracks( playlistId )
      .pipe(take(1))
      .subscribe( (tracks: Track[] ) => {
        this.alog.debug('JukeboxService > getPlaylistTracks:', tracks.length);
        var playlistTracksTemp: Track[] = this.shuffleArray(tracks);
        this.playlistTracks = playlistTracksTemp.filter( track => {
          return track.isAvailableToPlay
        });
        this.store.dispatch( new LoadingChangeAction(false) ); 
    });
  }

  observeQueuedTracks(partiesId: string) {

    this.alog.debug('JukeboxService > observeQueuedTracks', partiesId);

    this.queuedTracksSubscription = this.ngFireService.subscribeQueuedTracks(partiesId)
        .subscribe(
          (queuedTracks: QueuedTrack[]) => {
            //let queuedTracks: queuedTracks[] = response as JukeboxData;
            this.store.dispatch( new SetQueuedTracksAction(queuedTracks) );
            this.alog.debug('JukeboxService > observeQueuedTracks response', queuedTracks);
          },
          (err) => {
            this.alog.debug('JukeboxService > observeQueuedTracks err', err);
          }
        );
  }

  subscribeQueuedTracks(partiesId: string) {
    this.alog.debug('JukeboxService > subscribeQueuedTracks partiesId:', partiesId);
    return this.ngFireService.getQueuedTracks(partiesId);
  }



  /* *****************************/
  /* **** CART JUKEBOX       *****/
  /* *****************************/

  pushQueuedTrack(partiesId: string, songQueued: QueuedTrack) {

    this.uiService.loadingStateChanged.next(true);

    let promise = this.ngFireService.pushQueuedTrack(partiesId, songQueued)
      .then((_) => {
        this.uiService.loadingStateChanged.next(false);
      })
      .catch((err) => {
        this.alog.error("jukebox.service/pushQueuedTrack - catch", err);
        this.uiService.loadingStateChanged.next(false);
      })
      .finally(() => {
        this.uiService.loadingStateChanged.next(false);
      });    

      return promise;
  }

  pushSale(partiesId: string, sale: Sale) {

    this.uiService.loadingStateChanged.next(true);

    let promise = this.ngFireService.pushSale(partiesId, sale)
      .then((_) => {
        this.uiService.loadingStateChanged.next(false);
      })
      .catch((err) => {
        this.alog.error('jukebox.service/pushSale - err', err);
        this.uiService.loadingStateChanged.next(false);
      });    

      return promise;
  }


  removePromoCode(partiesId: string, promoCodeId: string): Promise<void> {

    this.uiService.loadingStateChanged.next(true);

    this.alog.debug('jukebox.service/removePromoCode - promoCodeId: ', promoCodeId);

    let promise = this.ngFireService.removePromoCode(partiesId, promoCodeId)
      .then((_) => {
        this.uiService.loadingStateChanged.next(false);
      })
      .catch((err) => {
        this.alog.error('jukebox.service/removePromoCode - catch', err);
        this.uiService.loadingStateChanged.next(false);
      });    

      return promise;
  }

  getPromoCode(partyId: string, promoCodeId: string): Promise<PromoCode> {

    this.alog.debug('JukeboxService > getPromoCode init', partyId, promoCodeId);

    this.store.dispatch( new LoadingChangeAction( true ) );

    var promise: Promise<PromoCode> = new Promise( (resolve, reject) => {

      this.ngFireService.getPromoCode(partyId, promoCodeId)
          .pipe(take(1))
          .subscribe(
            (response: PromoCode) => {
              let promoCode: PromoCode = response as PromoCode;
              this.alog.debug('JukeboxService > getPromoCode response', promoCode);
              this.store.dispatch( new LoadingChangeAction( false ) );
              resolve(promoCode);
            },
            (err) => {
              this.alog.debug('JukeboxService > getPromoCode err', err);
              this.store.dispatch( new LoadingChangeAction( false ) );
              reject(err);
            }
          );
    });

    return promise;
  }
  




  /* *****************************/
  /* **** TRACK LIST         *****/
  /* *****************************/


  pushSongRequests(partiesId: string, requestedSong: string): Promise<any> {

    this.uiService.loadingStateChanged.next(true);

    let promise = this.ngFireService.pushSongRequests(partiesId, requestedSong)
      .then((_) => {
        this.uiService.loadingStateChanged.next(false);
      })
      .catch((err) => {
        this.alog.error('jukebox.service/pushSongRequests - catch', err);
        this.uiService.loadingStateChanged.next(false);
      });    

      return promise;
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  orderByArtist(tracks: Track[]) {

    return tracks.sort( (a: Track, b: Track) => {
      if (a.artists[0].name > b.artists[0].name) {
        return 1;
      }

      if (a.artists[0].name < b.artists[0].name) {
        return -1;
      }
      return 0;
    });
  }


  getRequestedTracksByUser(): number {
    const requestedTracksByUser = localStorage.getItem('user_requested_tracks');
    this.alog.debug('JukeboxService > getRequestedTracksByUser: ', requestedTracksByUser);
    if (!requestedTracksByUser) {
      return 0;
    }
    return parseInt(requestedTracksByUser);
  }
  
  userHaveRequestedTracksRecently(): boolean {
    var now = moment().locale('es');
    const lastUserRequestTime = localStorage.getItem('last_user_request_time');
    // If user try to makes 2 requests in less than 2 minutes
    
    if ( environment.production ) {
      return moment(lastUserRequestTime).add(2, 'minutes').isAfter(now);
    } else {
      return moment(lastUserRequestTime).add(1, 'minutes').isAfter(now);
    }
  }

  setRequestTimeOnLocalStorage(now: string) {
    localStorage.setItem('last_user_request_time', now);
  }

  isUserFeedbackActive() {
    var now = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');
    const lastUserFeeback = localStorage.getItem('user_feedback_date');
    const userAccessedRatingLink = localStorage.getItem('user_accessed_rating_link');

    if ( environment.production ) {
      return !userAccessedRatingLink || (!lastUserFeeback || moment(lastUserFeeback).add(1, 'months').isBefore(now));
    } else {
      return !userAccessedRatingLink || (!lastUserFeeback || moment(lastUserFeeback).add(1, 'minutes').isBefore(now));
    }
  }

  setUserFeedbackOnLocalStorage(rate: string) {
    var now = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');
    localStorage.setItem('user_feedback_date', now);
    localStorage.setItem('user_feedback', rate);
  }

  setUserAccessedRatingLinkOnLocalStorage() {
    localStorage.setItem('user_accessed_rating_link', 'true');
  }

  getUserAccessedRatingLinkFromLocalStorage() {
    return localStorage.getItem('user_accessed_rating_link');
  }

  getUserFeedbackFromLocalStorage(): string {
    return localStorage.getItem('user_feedback') || '4';
  }

}
