


<div class="row">
  <div class="col-12 col-sm-12 col-md-12"
        style="color: whitesmoke">

      <!-- class="form-inline" -->
      <form [formGroup]="promoSongForm"
            (ngSubmit)="onPromoCodeFormSubmit()" novalidate>

            <div class="form-horizontal">

              <input type="text" class="form-control" 
                      id="name"
                      formControlName="name"
                      maxlength="32"
                      placeholder="(Opcional) Nombre">

              <input type="text" class="form-control" 
                      id="promoCode"
                      [ngClass]="{ 'is-invalid': promoSongForm.get('promoCode').invalid && promoSongForm.get('promoCode').touched,
                                   'is-valid': promoSongForm.get('promoCode').valid }"
                      minlength="2" maxlength="5"
                      oninput="if(this.value.length >= 5) { this.value = this.value.slice(0,5); }"
                      formControlName="promoCode"
                      placeholder="Código promocional">

                <div *ngIf="promoSongForm.get('promoCode').invalid 
                            && promoSongForm.get('promoCode').dirty" 
                            style="color: coral; font-size: small;"
                      class="notification is-danger">
                  No encontramos el código promocional...
                </div>
                
                <div *ngIf="promoSongForm.get('promoCode').pending" 
                style="color: coral; font-size: small;"
                      class="notification is-info">
                  Comprobando...
                </div>

              <br>              
              <div class="text-center"> 
                <button type="submit" class="btn btn-primary" [disabled]="!promoSongForm.valid">Solicitar!</button>
              </div>
            </div>
      </form>
  </div>
</div>
