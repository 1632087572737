import { Component, OnInit, OnDestroy } from '@angular/core';

import 'jspdf-autotable';
import * as jspdf from 'jspdf';  
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { UIService } from '../../../../shared/services/ui.service';
import { AdminService } from '../../../../shared/services/admin.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Jukebox, JukeboxData } from '../../../../shared/models/jukebox.model';
import { filter } from 'rxjs/operators';
import { PromoCode } from '../../../../shared/models/promo-code.model';

@Component({
  selector: 'app-promo-codes-manager',
  templateUrl: './promo-codes-manager.component.html',
  styleUrls: ['./promo-codes-manager.component.css']
})
export class PromoCodesManagerComponent implements OnInit, OnDestroy {

  isLoading = true;

  jukeboxData: JukeboxData;

  promoCodesForm: FormGroup;
  promoCodesText: string = 'by Paviado';

  uiSubscription: Subscription = new Subscription();
  authSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private uiService: UIService,
               public adminService: AdminService ) { }

  ngOnInit() {

    this.promoCodesForm = new FormGroup({
      'codesToGenerate': new FormControl(null, [Validators.required, Validators.min(1), Validators.max(500)]),
      'addWinerCodes': new FormControl(null, [Validators.required]),
      'winerCodes': new FormControl(null, [Validators.min(1), Validators.max(500)])
    });

    this.uiSubscription = this.store.select('ui')
        .subscribe( ui => {
          this.isLoading = ui.isLoading;
        });

    this.jukeboxDataSubscription = this.store.select('jukeboxData')
        .pipe(
          filter( jukeboxState => jukeboxState.jukeboxData != null)
        )
        .subscribe( jukeboxState => {
          this.isLoading = false;
          this.jukeboxData = jukeboxState.jukeboxData;
          this.getPromoCodes(this.jukeboxData.partiesId);
        });
  }


  /**
   * 
   * @param partiesId 
   */
  private getPromoCodes(partiesId: string) {
    this.adminService.getPromoCodes(partiesId);
  }


  /**
   * 
   */
  private onPromoCodesFormSubmit() {

    if (this.promoCodesForm.valid) {

      this.isLoading = true;

      let codesToGenerate: number = this.promoCodesForm.get('codesToGenerate').value;
      let addWinerCodes: string = this.promoCodesForm.get('addWinerCodes').value;
      let winerCodes: number = this.promoCodesForm.get('winerCodes').value;

      this.alog.info('codesToGenerate', codesToGenerate);
      this.alog.info('addWinerCodes', addWinerCodes);
      this.alog.info('winerCodes', winerCodes);

      if (addWinerCodes == 'no') {
        winerCodes = 0;
      }

      let count: number = 0;
      let promoCodesNumbers: string[] = [];
      let promoCodesToInsertObject = {};      

      this.adminService.promoCodes.forEach( element => {
        promoCodesNumbers.push(element.code);
        promoCodesToInsertObject[element.id] = element;  
      });
      
      do {
        // Create random String. Source: https://gist.github.com/6174/6062387
        let random = [...Array(5)].map(i=>(~~(Math.random()*10)).toString(10)).join('');
        
        // If code is not in the new list
        if ( !promoCodesNumbers.includes(random) ) {
          
          let promoCode = new PromoCode();
          promoCode.code = random;
          promoCode.id = random;
          promoCode.used = false;

          if (winerCodes > 0) {
            promoCode.content = 'winer';
            winerCodes--;

          } else {
            promoCode.content = 'empty';
          }

          promoCodesNumbers.push(random);
          promoCodesToInsertObject[random] = promoCode;
          count++;
        }

      } while (count < codesToGenerate);

      // Reoder
      /*
      var sortable = [];
      for (var vehicle in maxSpeed) {
          sortable.push([vehicle, maxSpeed[vehicle]]);
      }

      sortable.sort(function(a, b) {
          return a[1] - b[1];
      });

      var objSorted = {}
      sortable.forEach(function(item){
          objSorted[item[0]]=item[1]
      })*/

      //this.adminService.pushPromoCodes(this.jukebox.jukebox_data.partiesId, promoCodesToInsertObject)
      this.adminService.setPromoCodes(this.jukeboxData.partiesId, promoCodesToInsertObject)
        .then((_) => {
          this.uiService.showSuccess('Se han guardado los códigos');
        })
        .catch((err) => {
          this.alog.debug('admin.service/updateJukeboxConfig err', err);
          this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
        })
        .finally(() => {
          //this.uiService.loadingStateChanged.next(false);
          this.isLoading = false;
        });    

    }
  }


  /**
   * 
   */
  convertTableToPdf() {
    this.isLoading = true;
    var columns = ["PROMO CODE", "PROMO CODE", "PROMO CODE"];
    var matrix = [];
    var num_of_columns = 3;

    for(var i = 0; i < num_of_columns; i++) {
      matrix[i] = new Array();//new Array();
    }

    for(var i = 0; i < this.adminService.promoCodes.length; i++) { 
      matrix[i%num_of_columns].push(this.adminService.promoCodes[i].code + '\n' + this.promoCodesText ); //  + '\n' + 'By Paviado'
    }

    let rows = [];

    for(var i = 0; i < matrix[0].length; i++) { 
      let row = [matrix[0][i], matrix[1][i], matrix[2][i]];
      rows.push(row);
    }

    var doc = new jspdf('p', 'pt');
    //doc.setFontSize(18);
    doc.autoTable(columns, rows, {
      //startY: 25,
      theme: 'grid',
      headStyles: {
        fillColor: [98, 0, 238],
        halign: 'center',
      },
      margin: {
        horizontal: 7
      },
      bodyStyles: {
        valign: 'middle',
        halign: 'center',
        minCellHeight: 100,  
      },
      styles: {
        fontSize: 22,
        overflow: 'linebreak',
        cellWidth: 'wrap',
        tableWidth: 300,
      },
      columnStyles: {
        text: {
          cellWidth: 'auto',
        },      
      },
      rowPageBreak: 'avoid',
    });
        
    doc.save('Paviado - Promo Codes.pdf');
    this.isLoading = false;
  }


  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.jukeboxDataSubscription.unsubscribe();
    this.adminService.promoCodesSubscription.unsubscribe();
  }

}
