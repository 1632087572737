


import { Action } from '@ngrx/store'
import { QueuedTrack } from '../../models/queued-track.model';


export const SET_QUEUED_TRACKS = '[QueuedTracks] Set queued tracks';
export const UNSET_QUEUED_TRACKS = '[QueuedTracks] Unset queued tracks';
export const UPDATE_QUEUED_TRACKS = '[QueuedTracks] Update queued tracks';



export class SetQueuedTracksAction implements Action {
  readonly type = SET_QUEUED_TRACKS;

  constructor( public queuedTracks: QueuedTrack[] ) {}
}

export class UnsetQueuedTracksAction implements Action {
  readonly type = UNSET_QUEUED_TRACKS;
}



export type Actions = SetQueuedTracksAction 
                    | UnsetQueuedTracksAction;