
<div class="container">
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div class="card card-signin my-5">
        <div class="card-body">
          <form class="form-signin">
            <p class="text-center" style="color: aliceblue">Por favor introduce tu dirección de correo para solicitar resetear tu contraseña.</p>
            <div class="form-label-group">
              <input type="email" class="form-control" placeholder="Email" #passwordResetEmail required>
            </div>
            <br>            
            <button class="btn btn-lg btn-warning btn-block text-uppercase" type="button"
                    (click)="authService.ForgotPassword(passwordResetEmail.value)">Resetear Contraseña</button>
            <hr class="my-4">
          </form>
        </div>
        <div class="text-center" style="margin-bottom: 30px">
          <a routerLink="/admin/sign-in" style="color: aliceblue">Volver a inicio</a>
        </div>
      </div>
    </div>
  </div>
</div>