


<div class="container">

  <br>

  <div *ngIf="isLoading" class="container text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="!isLoading">
    
    <div *ngIf="!isLoading && partyData">

      <form [formGroup]="mailCampaignForm"
            (ngSubmit)="onMailCampaignSaveFormSubmit()">
    
        <div class="text-center">
          <button type="submit" class="btn btn-primary" 
                  [disabled]="!mailCampaignForm.valid || !mailCampaignForm.dirty">
            Guardar cambios
          </button>
        </div>
        <br>
    
        <label class="btn btn-default" style="background-color: transparent;">
          <input type="checkbox" name="conditions" formControlName="isEmailCampaignActive"
                [checked]="partyData.isEmailCampaignActive"> 
          Campaña activa (Enviará este email al recibir un correo)<br>   
        </label>
    
        <input type="text" class="form-control" formControlName="emailSubject" maxlength="128" placeholder="Asunto: ">
        <br>
    
        <angular-editor formControlName="htmlContent" [config]="editorConfig"></angular-editor>
    
        <br><br>
      </form>
    
    </div>

  </div>  

</div>


