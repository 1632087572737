


import * as fromJukeboxRealtime from '../actions/jukebox-realtime.actions';
import { JukeboxRealtime } from '../../models/jukebox-realtime.model';


export interface JukeboxRealtimeState {
  jukeboxRealtime: JukeboxRealtime;
}

const initState: JukeboxRealtimeState = {
  jukeboxRealtime: null
};

export function jukeboxRealtimeReducer( 
                  state = initState, 
                  action: fromJukeboxRealtime.Actions ): JukeboxRealtimeState {

    switch ( action.type ) {

        case fromJukeboxRealtime.SET_JUKEBOX_REALTIME:
          
            return {
              jukeboxRealtime: {
                ...action.jukeboxRealtime
              }
            };
    
        case fromJukeboxRealtime.UPDATE_JUKEBOX_REALTIME:

            return {
                ...state
            };

        case fromJukeboxRealtime.UNSET_JUKEBOX_REALTIME:

            return {
              jukeboxRealtime: null
            };

        default:
            return state;
    }
}