import { Component, OnInit, OnDestroy } from '@angular/core';
import { AdminService } from 'src/app/shared/services/admin.service';
import { NGXLogger } from 'ngx-logger';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { filter } from 'rxjs/operators';
import { JukeboxConfig } from '../../../shared/models/jukebox.model';

@Component({
  selector: 'app-jukebox-config',
  templateUrl: './jukebox-config.component.html',
  styleUrls: ['./jukebox-config.component.css']
})
export class JukeboxConfigComponent implements OnInit, OnDestroy {

  private readonly MS_IN_ONE_MINUTE: number = 60000;

  jukeboxConfiguration: JukeboxConfig;

  isLoading = false;
  loadingSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();

  jukeboxConfigForm: FormGroup;


  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private adminService: AdminService ) { }

  ngOnInit() {

    this.isLoading = true;

    this.jukeboxConfigForm = new FormGroup({
      'displayName': new FormControl(null, [Validators.required]),
      'instagramUser': new FormControl(null),
      'ratingSiteUrl': new FormControl(null),
      'timeElapsedRepeatSong': new FormControl(null, [Validators.required, Validators.min(5), Validators.max(600)]),
      'songQueueMaxSize': new FormControl(null, [Validators.required, Validators.min(1), Validators.max(50)]),
      'kioskModeExitCode': new FormControl(null, [Validators.required, Validators.min(1), Validators.max(9999)]),
      'noDataButtonActive': new FormControl(null),
      'isAdsModeActive': new FormControl(null),
      'isDataGatheringActive': new FormControl(null)
    });

    this.jukeboxConfigurationSubscription = this.store.select('jukeboxConfiguration')
        .pipe(
          filter( (jukeState) => jukeState.jukeboxConfiguration != null)
        )
        .subscribe( (jukeState) => {
          this.jukeboxConfiguration = jukeState.jukeboxConfiguration;
          this.isLoading = false;
          this.updateUI();
        });

  }

  
  private onJukeboxConfigFormSubmit() {

    if (this.jukeboxConfigForm.valid) {

      let displayName: string = this.jukeboxConfigForm.get('displayName').value;
      let instagramUser: string = this.jukeboxConfigForm.get('instagramUser').value;
      let ratingSiteUrl: string = this.jukeboxConfigForm.get('ratingSiteUrl').value;
      let timeElapsedRepeatSong: number = this.jukeboxConfigForm.get('timeElapsedRepeatSong').value;
        timeElapsedRepeatSong = timeElapsedRepeatSong * this.MS_IN_ONE_MINUTE;      
      let songQueueMaxSize: number = this.jukeboxConfigForm.get('songQueueMaxSize').value;
      let noDataButtonActive: boolean = this.jukeboxConfigForm.get('noDataButtonActive').value;
      let isAdsModeActive: boolean = this.jukeboxConfigForm.get('isAdsModeActive').value;
      let isDataGatheringActive: boolean = this.jukeboxConfigForm.get('isDataGatheringActive').value;
      let kioskModeExitCode: string = this.jukeboxConfigForm.get('kioskModeExitCode').value;

      this.jukeboxConfiguration.displayName = displayName;
      if (instagramUser) {
        this.jukeboxConfiguration.instagramUser = instagramUser;
      }
      if (ratingSiteUrl) {
        this.jukeboxConfiguration.ratingSiteUrl = ratingSiteUrl;
      }
      this.jukeboxConfiguration.timeElapsedRepeatSong = timeElapsedRepeatSong;
      this.jukeboxConfiguration.songQueueMaxSize = songQueueMaxSize;
      this.jukeboxConfiguration.ads_mode.isAdsModeActive = isAdsModeActive;
      this.jukeboxConfiguration.ads_mode.isDataGatheringActive = isDataGatheringActive;
      this.jukeboxConfiguration.adminExitCode = kioskModeExitCode.toString();

      this.jukeboxConfiguration.dialogConfiguration.secondaryButton.isActive = noDataButtonActive;

      this.adminService.updateJukeboxConfig(this.jukeboxConfiguration)
        .then((_) => {
          //this.uiService.showSuccess('v2');
        })
        .catch((err) => {
          this.alog.error('jukebox-config/updateJukeboxConfig', err);
        });
    }
  }


  updateUI() {

    if (this.jukeboxConfiguration) {
      this.jukeboxConfigForm.get('displayName').setValue(this.jukeboxConfiguration.displayName);
      this.jukeboxConfigForm.get('instagramUser').setValue(this.jukeboxConfiguration.instagramUser);
      this.jukeboxConfigForm.get('ratingSiteUrl').setValue(this.jukeboxConfiguration.ratingSiteUrl);
      this.jukeboxConfigForm.get('timeElapsedRepeatSong').setValue(this.jukeboxConfiguration.timeElapsedRepeatSong / this.MS_IN_ONE_MINUTE);
      this.jukeboxConfigForm.get('songQueueMaxSize').setValue(this.jukeboxConfiguration.songQueueMaxSize);
      this.jukeboxConfigForm.get('noDataButtonActive').setValue(this.jukeboxConfiguration.dialogConfiguration.secondaryButton.isActive);
      this.jukeboxConfigForm.get('kioskModeExitCode').setValue(this.jukeboxConfiguration.adminExitCode);
      this.jukeboxConfigForm.get('isAdsModeActive').setValue(this.jukeboxConfiguration.ads_mode.isAdsModeActive);
      this.jukeboxConfigForm.get('isDataGatheringActive').setValue(this.jukeboxConfiguration.ads_mode.isDataGatheringActive);
    }
  }


  ngOnDestroy() {
    this.jukeboxConfigurationSubscription.unsubscribe();
  }

}
