
<!-- Top navigation -->

<div class="container-fluid">

  <br>

  <div class="row">
    <div class="col-1">
      <a class="navbar-brand" routerLink="/admin/jukebox-music">
        <i class="fa fa-arrow-circle-left fa-2x" style="cursor: pointer;" aria-hidden="true"></i>
      </a>      
    </div>
    <div class="col-11 pl-4">
      Elige las canciones que quieres ocultar <small>(Esto no eliminará las canciones de la playlist actual en Spotify)</small>
    </div>
  </div>

  <div *ngIf="isLoading" class="container text-center">
    <div class="spinner-border" role="status" style="color: purple">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <br>
  
  <div *ngIf="!isLoading" 
       class="container">
      
    <div class="row text-center">      
      <div class="col-12">
          <input type="text" class="input" 
              placeholder="Filtro de búsqueda"
              [(ngModel)] = "searchText">
      </div>
    </div>

    <br>
    
    <div class="row text-center">
      <div class="col-xs-2">        
        <app-track-item-admin
          *ngFor="let song of songs | songFilter:searchText; let i = index"
          [track]="song">
        </app-track-item-admin>
      </div>
    </div>

    <button type="button" class="btn btn-danger float" 
            (click) = "deleteTracks()"
            [disabled]="adminService.tracksToDelete.length == 0">
            Ocultar Canciones ({{ adminService.tracksToDelete.length }}) 
    </button>  

  </div>

</div>

