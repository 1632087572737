import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Module
import { SharedModule } from '../shared/shared.module';


// Imports
import { NgbAccordionModule, NgbTabsetModule, NgbPopoverModule, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { JoyrideModule } from 'ngx-joyride';
import { HttpClientModule} from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';


// Components
import { adminRoutes } from '../admin/admin.routes';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { SpotifyCallbackComponent } from './spotify/spotify-callback/spotify-callback.component';
import { PlaylistsComponent } from './spotify/playlists/playlists.component';
import { JukeboxTracksComponent } from './jukebox-config/jukebox-tracks/jukebox-tracks.component';
import { PromoCodesComponent } from './jukebox-config/promo-codes/promo-codes.component';
import { FooterAdminComponent } from './shared/footer-admin/footer-admin.component';
import { JukeboxConfigComponent } from './jukebox-config/jukebox-settings/jukebox-config.component';
import { TrackItemAdminComponent } from './jukebox-config/jukebox-tracks/track-item-admin/track-item-admin.component';
import { PromoCodeItemComponent } from './jukebox-config/promo-codes/promo-code-item/promo-code-item.component';
import { PromoImagesComponent } from './jukebox-config/promo-images/promo-images.component';
import { NavbarAdminComponent } from './shared/navbar-admin/navbar-admin.component';
import { SidebarAdminComponent } from './shared/sidebar-admin/sidebar-admin.component';
import { HomeAdminComponent } from './home/home-admin.component';
import { JukeboxMusicComponent } from './jukebox-config/jukebox-music/jukebox-music.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { SaleItemComponent } from './sale-list/sale-item/sale-item.component';
import { SaleListComponent } from './sale-list/sale-list.component';
import { PromoCodesManagerComponent } from './jukebox-config/promo-codes/promo-codes-manager/promo-codes-manager.component';
import { SpotifyAuthCodeComponent } from './spotify/spotify-callback/spotify-auth-code/spotify-auth-code.component';
import { HtmlEditorComponent } from './jukebox-config/mail-campaign/html-editor.component';

const routes: Routes = [
  { 
    path: 'admin', 
    component: DashboardComponent,
    children: adminRoutes,
  },
];


@NgModule({
  declarations: [
    DashboardComponent,
    ProfileComponent,
    SpotifyCallbackComponent,
    PlaylistsComponent,
    JukeboxTracksComponent,
    PromoCodesComponent,
    FooterAdminComponent,
    JukeboxConfigComponent,
    TrackItemAdminComponent,
    PromoCodeItemComponent,
    PromoImagesComponent,
    NavbarAdminComponent,
    SidebarAdminComponent,
    HomeAdminComponent,
    JukeboxMusicComponent,
    StatisticsComponent,
    SaleListComponent,
    SaleItemComponent,
    PromoCodesManagerComponent,
    SpotifyAuthCodeComponent,
    HtmlEditorComponent,

  ],

  imports: [
    RouterModule.forChild( routes ),
    CommonModule,    
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbTabsetModule,
    NgbPopoverModule,
    NgbButtonsModule,
    NgbAccordionModule,
    ChartsModule,
    JoyrideModule.forChild(),
    HttpClientModule, 
    AngularEditorModule
  ]

})
export class AdminModule { }
