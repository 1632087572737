


<br>

<div *ngIf="isLoading"
     class="d-flex justify-content-center">

  <div >
    <div class="spinner-border" role="status" style="color: purple">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

</div>


<div *ngIf="!isLoading">

  <div *ngIf="!jukeboxConfiguration && !user.jukeboxes_parties">
    <div class="text-center">
        <div class="card">
        <div style="width:100%; text-align:center">
          <img class="card-img-top" src="../../../assets/images/ic_launcher_black.png" style="max-width: 50px;">
        </div>        
        <div class="card-body">
          <h4 class="card-title">Inicia sesión en uno de tus dispositivos para verlos en el panel de administración.</h4>
        </div>
        <div class="card-body">
          <a href="https://play.google.com/store/apps/details?id=com.paviado.jukebox&gl=ES" class="card-link">Haz click aqui si no tienes ninguno</a>
        </div>
      </div>
    </div>   
  </div>   
  
  <div *ngIf="jukeboxConfiguration" class="card" joyrideStep="step-3" [stepContent]="customJoyrideStepContent2">
    <div class="card-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-8">
            <h3 class="card-title" style="inline-block">{{ jukeboxConfiguration.displayName }}</h3>
          </div>
          <div class="col-4">
            <div class="custom-control custom-switch">
              <label *ngIf="!jukeboxConfiguration.isJukeboxOnline"
                     style="color: red;">
                Inactiva
              </label>
              <label *ngIf="jukeboxConfiguration.isJukeboxOnline"
                     style="color: green;">
                Activa
              </label>
            </div>
            <!--<div class="custom-control custom-switch">
              <checked / disabled
              <input type="checkbox" class="custom-control-input" id="customSwitches" 
                     [checked]="jukebox.jukebox_configuration.isJukeboxOnline"
                     (click)="onJukeboxOnlineToogle(jukebox.jukebox_data.id)">
              <label class="custom-control-label" for="customSwitches">Peticiones web</label>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">
      
      <div *ngIf="jukeboxConfiguration.isJukeboxOnline">
        <!-- <p>   
          <b>Id: </b> {{ jukeboxConfiguration.id }}
        </p> -->
        <!-- <p>   
          <b>Código de salida del modo Kiosko:</b> {{ jukeboxConfiguration.adminExitCode }}
        </p> -->

        <div *ngIf="jukeboxRealtime">
          <div *ngIf="jukeboxRealtime.player_state?.isPaused">
            <p>Reproductor en pausa</p>
          </div>
          <div *ngIf="!jukeboxRealtime.player_state?.isPaused && jukeboxRealtime?.track"
               style="margin-left: 10px;">

            <div class="row">              
              <h4>Reproduciendo</h4> <br>
            </div>

            <div class="row">
              <div class="col-xs-2">              
                <img src="{{jukeboxRealtime.track.images[2].url}}" 
                    class="img-responsive" 
                    style="height: 64px; width: 64px;" 
                    sizes="64px">
              </div>
      
              <div class="col-xs-10" style="margin-left: 10px;">
                  <p> 
                    {{ jukeboxRealtime.track.name }} <br>
                    {{ jukeboxRealtime.track.artists[0].name }} <br>
                    {{ jukeboxRealtime?.track?.started_timestamps | date: 'HH:mm:ss' }} / {{ realtimeExpectedEndTimestamp | date: 'HH:mm:ss' }}
                  </p> 
              </div>
            </div>
              
          </div>
        </div>

      </div>
      
    </div>

  </div>

  <div joyrideStep="step-1" [stepContent]="customJoyrideStepContent1" [stepContentParams]="{'name': user.displayName}" stepPosition="center"></div>
  <div joyrideStep="step-19" [stepContent]="customJoyrideStepContent3" stepPosition="center"></div>

  <ng-template #customJoyrideStepContent1 let-person="name">
    Hola {{person}}! <br>
    Vamos a seguir un pequeño tutorial para aprender a manejar la web de administración de tu Paviado.
  </ng-template>

  <ng-template #customJoyrideStepContent2>
    <div class="text-center">
      <i class="fas fa-home"></i><br>
    </div>
    Esta es tu pantalla de inicio. Aqui puedes ver un resumen del Paviado seleccionado:
    <ul>
      <li>Si se encuentra <span style="color: green;">Activo</span> ó <span style="color: red;">Inactivo</span></li>
      <li>El estado actual del reproductor</li>
    </ul>    
  </ng-template>

  <ng-template #customJoyrideStepContent3>
    <div class="text-center">
      <i class="fas fa-trophy"></i>
    </div>
    Genial! Has completado el tutorial. Puedes repetirlo cuando quieras desde el menú: <i class="fas fa-question-circle"></i><br>
    Y si tienes alguna pregunta, no dudes en escribirnos a <a href="mailto:hola@paviado.com?Subject=Sugerencia" target="_top">hola@paviado.com</a>
  </ng-template>
  
</div>