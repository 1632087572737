import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';

// Custom Modules
import { AuthModule } from './auth/auth.module';
import { AdminModule } from './admin/admin.module';
import { SharedModule } from './shared/shared.module';
import { JukeboxModule } from './users/jukebox.module';

import { environment } from '../environments/environment';
import { AppConfigService } from './shared/services/app-config.service';
import { JoyrideModule } from 'ngx-joyride';

// Redux
import { StoreModule } from '@ngrx/store';
import { appReducers } from './app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AuthService } from './shared/services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import localeEs from '@angular/common/locales/es';


registerLocaleData(localeEs, 'es');

export function init_app(config: AppConfigService) {
  return () => config.initializeApp();
}


@NgModule({
  declarations: [
    AppComponent,
  ],

  imports: [
    BrowserModule,
    CommonModule,
    AuthModule,
    AdminModule,
    SharedModule,
    JukeboxModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF,// serverLogLevel
      serverLogLevel: NgxLoggerLevel.OFF
      }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,    
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot( appReducers ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,                       // Retains last 25 states
      logOnly: environment.production,  // Restrict extension to log-only mode
    }),    
    JoyrideModule.forRoot()
  ],

  providers: [
    AuthService,
    AppConfigService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppConfigService], multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
  ],

  bootstrap: [AppComponent],
  entryComponents: [],
  exports: [ ]
})
export class AppModule { }
