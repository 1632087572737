


<div *ngIf="isLoading" class="container text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading">

  <ngb-accordion [closeOthers]="true">

    <ngb-panel id="static-1">
      <ng-template ngbPanelTitle>
        <h4 style="color: #6200EE" joyrideStep="step-12" [stepContent]="customJoyrideStepContent1"> 
          <b>Generar Códigos</b> </h4>
      </ng-template>

      <ng-template ngbPanelContent>

          <div class="row">
            <form [formGroup]="promoCodesForm"
                  (ngSubmit)="onPromoCodesFormSubmit()">
      
              <div class="text-left">
                <b> Cantidad de códigos a generar. </b><br><small>(Añadirá nuevos códigos sobre los actuales)</small><br>  
              </div>
              <input type="number" class="form-control" 
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                    formControlName="codesToGenerate"
                    min="1" max="500"
                    placeholder="1 - 500">
                    
              <div class="text-left">
                <b> Generar códigos premiados </b><br>  
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="yes" name="addWinerCodes" formControlName="addWinerCodes" value="yes">
                <label class="form-check-label" for="yes"> Si &nbsp;&nbsp;</label>
            
                <input type="radio" class="form-check-input" id="no" name="addWinerCodes" formControlName="addWinerCodes" value="no" >
                <label class="form-check-label" for="no" checked> No </label>
              </div>
              <br>  
              <br>  
              <div *ngIf="promoCodesForm.get('addWinerCodes').value == 'yes'">
                <div class="text-left">
                  <b> Cantidad de códigos premiados </b><br>  
                </div>
                <input type="number" class="form-control" 
                      oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                      formControlName="winerCodes"
                      min="1" max="500"
                      placeholder="1 - 500">
              </div>
              <br>
              <div class="text-center">
                <button class="btn btn-primary" type="submit" name="" id=""  
                        [disabled]="promoCodesForm.invalid || !promoCodesForm.dirty || this.adminService.promoCodes.length > 10000">Generar códigos</button>

                <div *ngIf="this.adminService.promoCodes.length > 10000" class="text-left">
                  <small> Tienes demasiados códigos. Contacta con soporte si necesitas más</small><br>  
                </div>
              </div>
            </form>          
          </div>

      </ng-template>
    </ngb-panel>
    
    <ngb-panel *ngIf="this.adminService.promoCodes 
                      && this.adminService.promoCodes.length > 0"
                id="static-2">

      <ng-template ngbPanelTitle>
        <h4 style="color: #6200EE"
            joyrideStep="step-13" [stepContent]="customJoyrideStepContent2"> 
            <b>Ver Códigos ({{ this.adminService.promoCodes.length }})</b> </h4>
      </ng-template>

      <ng-template ngbPanelContent>

        <div class="row text-center">
          <div class="btn-block text-center">  
            <div class="md-6">
              <button class="btn btn-primary" type="button" value="Descargar en PDF" (click)="convertTableToPdf()">Descargar en PDF</button>
            </div> 
              <div class="md-6">
              <input [(ngModel)] = "promoCodesText"
                     type="text" name="text-description" id="text-description" class="form-control" placeholder="By Paviado" 
                     style="text-align:center;" maxlength="16">
            </div>
          </div>
        </div>

        <div class="row">
            
          <table class="table table-striped" style="background-color: white">
            <thead>
              <tr>
                <th scope="col">Número</th>
                <th scope="col">Código</th>
                <th scope="col">Contenido</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let promoCode of adminService.promoCodes; let i = index">
                  <td> {{ i + 1 }} </td>
                  <td> {{ promoCode.code }} </td>
                  <td> {{ promoCode.content == 'empty' ? 'vacío' : 'premiado' }} </td>
              </tr>
            </tbody>
          </table>
        </div>

      </ng-template>

    </ngb-panel>

  </ngb-accordion>

</div>

<ng-template #customJoyrideStepContent1>
  <i class="fas fa-poll-h"></i>
  Elige la cantidad de códigos que quieres generar y cuantos de ellos son premiados.
</ng-template>

<ng-template #customJoyrideStepContent2>
  <i class="fas fa-poll-h"></i>
  En esta sección puedes ver los códigos no usados y generar un PDF para imprimir y repartirlos.
</ng-template>