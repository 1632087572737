import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'songFilter'
})
export class SongFilterPipe implements PipeTransform {


  transform(value: any[], searchText: string): any[] {
    
    if(!value) return [];
    if(!searchText) return value;

    searchText = searchText.toLowerCase();

    
    return value.filter( it => {
      return it['name'].toLowerCase().includes(searchText) 
            || it.artists[0].name.toLowerCase().includes(searchText) ;
    });

  }

}
