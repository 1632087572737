


import { Action } from '@ngrx/store'
import { JukeboxRealtime } from '../../models/jukebox-realtime.model';


export const SET_JUKEBOX_REALTIME = '[JukeboxRealtime] Set jukebox realtime';
export const UNSET_JUKEBOX_REALTIME = '[JukeboxRealtime] Unset jukebox realtime';
export const UPDATE_JUKEBOX_REALTIME = '[JukeboxRealtime] Update jukebox realtime';



export class SetJukeboxRealtimeAction implements Action {
  readonly type = SET_JUKEBOX_REALTIME;

  constructor( public jukeboxRealtime: JukeboxRealtime ) {}
}

export class UnsetJukeboxRealtimeAction implements Action {
  readonly type = UNSET_JUKEBOX_REALTIME;
}

export class EditJukeboxRealtimeAction implements Action {
  readonly type = UPDATE_JUKEBOX_REALTIME;

  constructor( public id: string, public jukeboxData: JukeboxRealtime ) {}
}


export type Actions = SetJukeboxRealtimeAction 
                    | UnsetJukeboxRealtimeAction 
                    | EditJukeboxRealtimeAction;