import { Component, OnInit, Input } from '@angular/core';
import { Track } from '../../../../shared/models/track.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { QueuedTrack } from '../../../../shared/models/queued-track.model';
import { Sale } from '../../../../shared/models/sale.model';
import { Lead } from 'src/app/shared/models/lead.model';
import * as firebase from 'firebase/app';
import { PromoCodeValidator } from '../promo-code-validator';
import { NgfireHelperService } from '../../../../shared/services/ngfire-helper.service';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';
import { DialogConfigurationWeb } from '../../../../shared/models/jukebox.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-form-custom',
  templateUrl: './form-custom.component.html',
  styleUrls: ['./form-custom.component.css']
})
export class FormCustomComponent implements OnInit {

  @Input() trackSelected: Track;
  @Input() dialogConfigurationWeb: DialogConfigurationWeb;
  @Input() partyId: string;

  private readonly TEXTAREA_MAX_LINES: number = 10;

  isLoading: boolean = true;

  customForm: FormGroup;
  sale: Sale = new Sale();
  queuedTrack: QueuedTrack = new QueuedTrack();
  analytics = firebase.analytics();
  formSubmitDatetime: string;

  constructor( private alog: NGXLogger,
               private router: Router,
               private modalService: NgbModal,
               private jukeboxService: JukeboxService,
               private ngFireHelperService: NgfireHelperService ) { }
  
  ngOnInit() {

    this.customForm = new FormGroup({});
    this.setupRequiredFormFields();

  }


  setupRequiredFormFields() {
    this.isLoading = true;

    if ( this.dialogConfigurationWeb.name.isActive ) {
      if ( this.dialogConfigurationWeb.name.isRequired ) {
        this.customForm.addControl('name', new FormControl('', [Validators.required] ))
      } else {
        this.customForm.addControl('name', new FormControl(''))
      }
    }

    if ( this.dialogConfigurationWeb.email.isActive ) {
      if ( this.dialogConfigurationWeb.email.isRequired ) {
        this.customForm.addControl('email', new FormControl('', [Validators.required, Validators.email] ))
      } else {
        this.customForm.addControl('email', new FormControl(''))
      }
    }

    if ( this.dialogConfigurationWeb.shortText.isActive ) {
      if ( this.dialogConfigurationWeb.shortText.isRequired ) {
        this.customForm.addControl('shortText', new FormControl('', [Validators.required]))
      } else {
        this.customForm.addControl('shortText', new FormControl(''))
      }
    }

    if ( this.dialogConfigurationWeb.longText.isActive ) {
      if ( this.dialogConfigurationWeb.longText.isRequired ) {
        this.customForm.addControl('longText', new FormControl('', [Validators.required] ))
      } else {
        this.customForm.addControl('longText', new FormControl(''))
      }
    }

    if ( this.dialogConfigurationWeb.promocode.isActive ) {
      if ( this.dialogConfigurationWeb.promocode.isRequired ) {
        this.customForm.addControl('promocode', new FormControl('', [Validators.required], PromoCodeValidator.validatePromoCode(this.ngFireHelperService, this.partyId) ))
      } else {
        this.customForm.addControl('promocode', new FormControl(''))
      }
    }

    if ( this.dialogConfigurationWeb.termsCheckbox.isActive ) {
      if ( this.dialogConfigurationWeb.termsCheckbox.isRequired ) {
        this.customForm.addControl('terms', new FormControl('', [Validators.requiredTrue] ))
      } else {
        this.customForm.addControl('terms', new FormControl(''))
      }
    }

    this.customForm.updateValueAndValidity();
    this.isLoading = false;
  }


  /**
   * 
   */
  onCustomFormSubmit() {

    this.formSubmitDatetime = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');
    this.jukeboxService.setRequestTimeOnLocalStorage(this.formSubmitDatetime);

    // 20200421 - Dedicatoria canciones online. No insertamos en queued_tracks
    if ( !this.jukeboxService.jukeboxIdSubscribed.startsWith('0000') ) {
      this.pushQueuedTrack();
    }

    this.pushSale();
    
    if ( this.dialogConfigurationWeb.promocode.isActive ) {
      let promoCodeInput = this.customForm.get('promocode').value;
      
      this.jukeboxService.getPromoCode(this.partyId, promoCodeInput)
          .then( (promoCode) => {
                
            // Delete promo code from database
            this.jukeboxService.removePromoCode(this.partyId, promoCode.id);
              
            if (promoCode.content == 'winer' || promoCode.content == 'winner') {
              alert('Código premiado!! Canjéalo en la barra!');
            } 

            // Redirect to thanks
            this.router.navigate(['/jukebox', this.jukeboxService.jukeboxIdSubscribed, 'thanks']);
      })
      .catch((err) => {
        this.alog.error('FormPromosComponent > onPromoCodeFormSubmit', err);
      });

    } else {
      this.router.navigate(['/jukebox', this.jukeboxService.jukeboxIdSubscribed, 'thanks']);
    }

    this.analytics.logEvent('queued_track', 
                            { track_name: this.queuedTrack.name, 
                              track_artist: this.queuedTrack.artists,
                              dialog_type: 'custom', 
                              input_name: this.queuedTrack.addedQueueBy, 
                              input_email: this.sale.lead.email, 
                              input_shorttext: this.sale.lead.shortText, 
                              input_longtext: this.sale.lead.longText, 
                              input_checkbox: this.sale.lead.isChecked, 
                              source: 'web' });
    
  }

  pushQueuedTrack() {
    // QueuedTrack data
    this.queuedTrack.isQueuedInSpotify = false;

    if ( this.dialogConfigurationWeb.name.isActive ) {
      let addedQueueBy = this.customForm.get('name').value;
      if (addedQueueBy && addedQueueBy.trim().length > 0) {
        this.queuedTrack.addedQueueBy = addedQueueBy;
      }
    }

    this.queuedTrack.name = this.trackSelected.name;
    this.queuedTrack.durationMs = this.trackSelected.duration_ms;
    this.queuedTrack.artists = this.trackSelected.artists[0].name;
    this.queuedTrack.trackUri = this.trackSelected.uri;
    this.queuedTrack.coverUrlSmall = this.trackSelected.album.images[0].url;
    this.queuedTrack.source = "web";

    // Post on database song queue to play
    this.jukeboxService.pushQueuedTrack(this.partyId, this.queuedTrack);
  }


  pushSale() {

    let lead: Lead = new Lead();
    this.sale.provider = "lead";

    if ( this.dialogConfigurationWeb.name.isActive ) {
      lead.name = this.customForm.get('name').value;
    }

    if ( this.dialogConfigurationWeb.email.isActive ) {
      lead.email = this.customForm.get('email').value;
      lead.shortText = this.customForm.get('email').value;
    }

    if ( this.dialogConfigurationWeb.shortText.isActive ) {
      lead.shortText = this.customForm.get('shortText').value;
    }

    if ( this.dialogConfigurationWeb.longText.isActive ) {
      lead.longText = this.customForm.get('longText').value;
    }

    if ( this.dialogConfigurationWeb.promocode.isActive ) {
      this.sale.promoCode = this.customForm.get('promocode').value;
      this.sale.provider = "promo_code";
    }

    if ( this.dialogConfigurationWeb.termsCheckbox.isActive ) {
      lead.isChecked = this.customForm.get('terms').value;
    }

    this.sale.song = this.queuedTrack;
    this.sale.source = "web";
    this.sale.date_transaction = this.formSubmitDatetime;
    this.sale.lead = lead;

    // Post on database song selected
    this.jukeboxService.pushSale(this.partyId, this.sale);
  }

  limitTextArea(evt: KeyboardEvent) {

    let textarea = evt.target as any

    let newContent: string = (textarea.value as string)
        .split('\n')
        .filter((val, row) => row < this.TEXTAREA_MAX_LINES)
        .join('\n');

    textarea.value = newContent;
  }


  onOpenPrivacyPolicy(content) {
    this.modalService.open(content, { size: 'xl' });
  }
  

  ngOnDestroy() {    
  }

}
