import { Injectable } from '@angular/core';
import { NgfireHelperService } from './ngfire-helper.service';
import { UIService } from './ui.service';
import { Subject, Subscription } from 'rxjs';
import { JukeboxConfig, JukeboxData, AdImage } from '../models/jukebox.model';
import { Jukebox } from '../models/jukebox.model';
import { Track } from '../models/track.model';
import { Playlist } from '../models/playlist.model';
import { NGXLogger } from 'ngx-logger';
import { PromoCode } from '../models/promo-code.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { filter, take } from 'rxjs/operators';
import { Sale } from '../models/sale.model';
import { LoadingChangeAction } from '../redux/actions/ui.actions';
import * as moment from 'moment';
import * as firebase from 'firebase/app';
import { User } from '../models/user.model';
import { SetSalesAction } from '../redux/actions/sale.actions';
import { SetJukeboxDataAction } from '../redux/actions/jukebox-data.actions';
import { SetJukeboxConfigurationAction } from '../redux/actions/jukebox-configuration.actions';
import { JukeboxRealtime } from '../models/jukebox-realtime.model';
import { SetJukeboxRealtimeAction } from '../redux/actions/jukebox-realtime.actions';
import { PartyData } from '../models/party-data.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
    
  authSubscription: Subscription = new Subscription();
  salesSubscription: Subscription = new Subscription();

  jukeboxDataSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();
  jukeboxRealtimeSubscription: Subscription = new Subscription();

  playlist: Playlist;
  playlistChanged = new Subject<Playlist>();

  analytics = firebase.analytics();

  tracksToDelete: Track[] = [];
  tracksToDeleteChanged = new Subject<Track[]>();

  promoCodes: PromoCode[];
  promoCodesPrint: any = [];
  promoCodesSubscription: Subscription;
  
  constructor( private alog: NGXLogger,
               private uiService: UIService,
               private store: Store<AppState>,
               private ngFireService: NgfireHelperService ) { }


          
  initAdminListener() {

    this.authSubscription = 
      this.store.select('auth')
                .pipe(
                  filter( auth => auth.user != null )
                )
                .subscribe( auth => {
                  this.alog.debug('AdminService > initAdminListener: ', auth);
                });
    
  }           


  cancellSubscriptions() {
    this.authSubscription.unsubscribe();
    this.salesSubscription.unsubscribe();
    this.jukeboxDataSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
    this.jukeboxRealtimeSubscription.unsubscribe();
  }


  subscribeJukebox(jukeboxId: string) {
    this.alog.debug('AdminService > subscribeJukebox', jukeboxId);

    this.subscribeDataJukebox(jukeboxId);
    this.subscribeJukeboxConfiguration(jukeboxId);
    this.subscribeJukeboxRealtime(jukeboxId);
    // jukeboxRealtime
  }

  subscribeDataJukebox(jukeboxId: string) {

    this.alog.debug('AdminService > subscribeDataJukebox', jukeboxId);
    this.store.dispatch( new LoadingChangeAction(true) );

    this.jukeboxDataSubscription = this.ngFireService.subscribeJukeboxData(jukeboxId)
        .subscribe(
          (jukeboxData: JukeboxData) => {
            this.alog.debug('AdminService > subscribeDataJukebox response', jukeboxData);

            this.store.dispatch( new SetJukeboxDataAction(jukeboxData) );
            this.store.dispatch( new LoadingChangeAction(false) );
            this.analytics.setUserProperties( { jukebox_id: jukeboxData.id } );
            this.analytics.setUserProperties( { party_id: jukeboxData.partiesId } );
            this.analytics.setUserProperties( { platform: 'web' } );
            this.analytics.setUserProperties( { company: jukeboxData.company } );
          },
          (err) => {
            this.store.dispatch( new LoadingChangeAction(false) );
            this.alog.error('AdminService > subscribeDataJukebox err', err);
          }
        );
  }

  subscribeJukeboxConfiguration(jukeboxId: string) {

    this.alog.debug('AdminService > subscribeJukeboxConfiguration', jukeboxId);

    this.jukeboxConfigurationSubscription = this.ngFireService.subscribeJukeboxConfiguration(jukeboxId)
        .subscribe(
          (jukeboxConfiguration: JukeboxConfig) => {
            this.alog.debug('AdminService > subscribeJukeboxConfiguration response', jukeboxConfiguration);

            this.store.dispatch( new SetJukeboxConfigurationAction(jukeboxConfiguration) );
            this.analytics.setUserProperties( { subdomain: jukeboxConfiguration.subdomain } );
          },
          (err) => {
            this.alog.debug('AdminService > subscribeJukeboxConfiguration err', err);
          }
        );
  }

  subscribeJukeboxRealtime(jukeboxId: string) {

    this.alog.debug('AdminService > subscribeJukeboxRealtime', jukeboxId);

    this.jukeboxRealtimeSubscription = this.ngFireService.subscribeJukeboxRealtime(jukeboxId)
        .subscribe(
          (jukeboxRealtime: JukeboxRealtime) => {
            this.alog.debug('AdminService > subscribeJukeboxRealtime response', jukeboxRealtime);
            this.store.dispatch( new SetJukeboxRealtimeAction(jukeboxRealtime) );
          },
          (err) => {
            this.alog.debug('AdminService > subscribeJukeboxRealtime err', err);
          }
        );
  }



  getJukebox(jukeboxesId: string): Promise<Jukebox> {

    this.store.dispatch( new LoadingChangeAction(true) );

    var promise: Promise<Jukebox> = new Promise( (resolve, reject) => {

      this.ngFireService.getJukebox(jukeboxesId)
        .pipe(take(1))
        .subscribe(
          (response: any) => {
            let jukebox: Jukebox = response as Jukebox;            
            this.alog.debug('AdminService > getJukebox response', jukebox);
            this.store.dispatch( new LoadingChangeAction(false) );
            resolve(jukebox);
          },
          (err) => {
            this.alog.error('AdminService > getJukebox err', err);
            this.store.dispatch( new LoadingChangeAction(false) );            
            reject(err);
          }
        );
    });
    return promise;    
  }

  /* *****************************/
  /* **** JUKEBOX-CONFIG  ********/
  /* *****************************/

  updateJukeboxConfig(jukeboxConfig: JukeboxConfig): Promise<any> {

    this.uiService.loadingStateChanged.next(true);

    this.alog.debug('AdminService > updateJukeboxConfig jukeboxConfig', jukeboxConfig);

    let promise = this.ngFireService.updateJukeboxConfig(jukeboxConfig)
      .then((_) => {
        this.uiService.showSuccess('Se han guardado los cambios en tu Jukebox');
        this.uiService.loadingStateChanged.next(false);
      })
      .catch((err) => {
        this.alog.error('AdminService > updateJukeboxConfig err', err);
        this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
        this.uiService.loadingStateChanged.next(false);
      });    

      return promise;
  }

  updatePopupImage(jukeboxId: string, adImage: AdImage): Promise<any> {

    this.uiService.loadingStateChanged.next(true);

    this.alog.debug('AdminService > updatePopupImage adImage', adImage);

    let promise = this.ngFireService.updatePopupImage(jukeboxId, adImage)
      .then((_) => {
        this.uiService.showSuccess('Se han guardado los cambios en tu Jukebox');
        this.uiService.loadingStateChanged.next(false);
      })
      .catch((err) => {
        this.alog.error('AdminService > updatePopupImage err', err);
        this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
        this.uiService.loadingStateChanged.next(false);
      });    

      return promise;
  }

  deletePopupImage(jukeboxId: string, adImageId: string): Promise<any> {
    this.alog.debug('AdminService > deletePopupImage adImageId', adImageId);

    this.uiService.loadingStateChanged.next(true);
    let promise = this.ngFireService.deletePopupImage(jukeboxId, adImageId)
      .then((_) => {
        this.uiService.showSuccess('Se han guardado los cambios en tu Jukebox');
        this.uiService.loadingStateChanged.next(false);
      })
      .catch((err) => {
        this.alog.error('AdminService > deletePopupImage err', err);
        this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
        this.uiService.loadingStateChanged.next(false);
      });    

      return promise;
  }

  pushImagePopup(jukeboxConfig: JukeboxConfig, adImage: AdImage): Promise<any> {

    this.uiService.loadingStateChanged.next(true);

    this.alog.debug('AdminService > pushImagePopup adImage: ', adImage);

    let promise = this.ngFireService.pushImagePopup(jukeboxConfig, adImage)
      .then((_) => {
        this.uiService.showSuccess('Se han guardado los cambios en tu Jukebox');
        this.uiService.loadingStateChanged.next(false);
      })
      .catch((err) => {
        this.alog.error('AdminService > pushImagePopup err', err);
        this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
        this.uiService.loadingStateChanged.next(false);
      });    

      return promise;
  }

  getPlaylistLoaded(playlistsId: string): Promise<string> {

    var promise: Promise<string> = new Promise((resolve, reject) => {

    this.ngFireService.getPlaylistLoaded(playlistsId)
        .pipe(take(1))
        .subscribe(
          (response: any) => {
            resolve(response);         
          },
          (err) => {
            this.alog.error('AdminService/getPlaylistLoaded err', err);
            reject(err);
          }
        );
    });

    return promise;
  }

  getSongRequests(partiesId: string): Promise<string[]> {

    var promise: Promise<string[]> = new Promise( (resolve, reject) => {

    this.ngFireService.getSongRequests(partiesId)
        .pipe( take(1) )
        .subscribe(
          (response: any) => {
            resolve(response);         
          },
          (err) => {
            this.alog.error('AdminService/getSongRequests err', err);
            reject(err);
          }
        );
    });

    return promise;
  }

  /**
   * 
   * @param partiesId 
   * @param startAtDateStr 
   */
  getSales(partiesId: string, startAtDateStr: string): Promise<Sale[]> {

    this.store.dispatch( new LoadingChangeAction(true) );

    var promise: Promise<Sale[]> = new Promise( (resolve, reject) => {

      this.ngFireService.getSalesList(partiesId, startAtDateStr)
          .pipe( take(1) )
          .subscribe(
            (response: any) => {
              // let sales = response as Sale[];  
              this.store.dispatch( new LoadingChangeAction(false) );
              resolve(response);
            },
            (err) => {
              this.alog.error('AdminService/getSales err', err);
              reject(err);
            }
      );
    });

    return promise;
  }

  /**
   * 
   * @param partiesId 
   * @param startAtDateStr 
   */
  subscribeSales(partiesId: string, startAtDateStr: string) {

    this.store.dispatch( new LoadingChangeAction(true) );

    this.salesSubscription = this.ngFireService.getSalesList(partiesId, startAtDateStr)
        .subscribe(
          (sales: Sale[]) => {
            this.store.dispatch( new SetSalesAction(sales.reverse()) ); //.reverse()
            this.store.dispatch( new LoadingChangeAction(false) );            
            this.alog.debug('AdminService/subscribeSales response', sales);
          },
          (err) => {
            this.alog.debug('AdminService/subscribeSales err', err);
          }
        );
  }

  unsubscribeSales() {
    this.salesSubscription.unsubscribe();
  }

  updateSale(sale: Sale, partiesId: string) {
    this.ngFireService.updateSale(sale, partiesId);
  }
  

  /* *****************************/
  /* **** PLAYLISTS       ********/
  /* *****************************/

  updatePlaylist(playlistId: string, playlist: any): Promise<any> {

    this.uiService.loadingStateChanged.next(true);
    
    return this.ngFireService.updatePlaylist(playlistId, playlist);
  }

  updatePlaylistData(playlistId: string, playlist: Playlist): Promise<any> {
    this.alog.debug('AdminService > updatePlaylistData', playlistId, playlist);

    this.uiService.loadingStateChanged.next(true);

    playlist.updateDate = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');

    return this.ngFireService.updatePlaylistData(playlistId, playlist);
  }

  updatePlaylistSongs(playlistId: string, songs: any): Promise<any> {
    this.alog.debug('AdminService > updatePlaylistSongs', playlistId, songs);

    this.uiService.loadingStateChanged.next(true);

    return this.ngFireService.updatePlaylistSongs(playlistId, songs);   
  }

  

  removeTracksFromPlaylist(id: string) {
    for(let iTrack of this.tracksToDelete) {
      this.ngFireService.removeTrack(id, iTrack.id);
    }
  }

  addSelectedTrack(track: Track) {
    this.tracksToDelete.push(track);
    this.tracksToDeleteChanged.next(this.tracksToDelete);
  }

  removeSelectedTrack(track: Track) {
    this.tracksToDelete = this.tracksToDelete.filter(item => track.id != item.id);
    this.tracksToDeleteChanged.next(this.tracksToDelete);
  }
  
  getSelectedTrack() {
    return this.tracksToDelete.slice();
  }

  setSelectedTrack(tracks: Track[]) {
    this.tracksToDelete = tracks;
    this.tracksToDeleteChanged.next(this.tracksToDelete);
  }

  /* *****************************/
  /* **** PROMO-CODES     ********/
  /* *****************************/

  getPromoCodes(partiesId: string) {
    this.alog.debug('AdminService/getPromoCodes partiesId', partiesId);
    this.store.dispatch( new LoadingChangeAction(true) );

    this.promoCodesSubscription = 
      this.ngFireService.getPromoCodesList(partiesId)
        .subscribe(
          (response: any) => {
            this.promoCodes = response as PromoCode[];      
            this.store.dispatch( new LoadingChangeAction(false) );
            this.alog.debug('AdminService/getPromoCodes response', this.promoCodes);
          },
          (err) => {
            this.store.dispatch( new LoadingChangeAction(false) );
            this.alog.debug('AdminService/getPromoCodes err', err);
          }
        );
  }

  /**
   * 
   * @param partiesId 
   * @param promoCodes 
   */
  setPromoCodes(partiesId: string, promoCodes: any): Promise<any> {

    this.store.dispatch( new LoadingChangeAction(true) );

    this.alog.debug('AdminService/pushPromoCodes partiesId', partiesId);

    let promise = this.ngFireService.setPromoCodes(partiesId, promoCodes)
      .then((_) => {
        this.store.dispatch( new LoadingChangeAction(false) );
      })
      .catch((err) => {
        this.alog.debug('AdminService/pushPromoCodes err', err);
        this.store.dispatch( new LoadingChangeAction(false) );
      });    

    return promise;
  }


  /* *****************************/
  /* **** USER            ********/
  /* *****************************/

  updateUser( userId: string, user: User ): Promise<any> {

    this.store.dispatch( new LoadingChangeAction(true) );
    this.alog.debug('AdminService > updateUser user', user);

    let promise = this.ngFireService.updateUser(userId, user)
      .then( (_) => {      
        this.uiService.loadingStateChanged.next(false);
      })
      .catch( (err) => {
        this.alog.error('AdminService > updateUser err', err);
        //this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
      })
      .finally( () => {
        this.store.dispatch( new LoadingChangeAction(false) );
      });

      return promise;
  }


  /* *****************************/
  /* **** PARTY DATA      ********/
  /* *****************************/

  observePartyData(partiesId: string) {
    this.alog.debug('AdminService > observePartyData partiesId', partiesId);
    return this.ngFireService.observePartyData(partiesId);
  }

  updatePartyData(partyData: PartyData): Promise<any> {
    this.alog.debug('AdminService > observePartyData partyData: ', partyData);
    this.uiService.loadingStateChanged.next(true);

    let promise = this.ngFireService.updatePartyData(partyData)
      .then((_) => {
        this.uiService.showSuccess('Se han guardado los cambios');
        this.uiService.loadingStateChanged.next(false);
      })
      .catch((err) => {
        this.alog.error('AdminService > observePartyData err', err);
        this.uiService.showError('Se ha producido un error. Vuelve a intentarlo mas tarde');
        this.uiService.loadingStateChanged.next(false);
      });    

      return promise;
  }

  
}
