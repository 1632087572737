import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { filter, take } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { QueuedTrack } from '../../../shared/models/queued-track.model';
import { JukeboxData, JukeboxConfig } from '../../../shared/models/jukebox.model';
import { DomSanitizer } from '@angular/platform-browser';
import * as firebase from 'firebase/app';


@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit, OnDestroy {
    
  jukeboxData: JukeboxData;
  jukeboxConfiguration: JukeboxConfig;

  isLoading: boolean = false;
  displayText: string = 'Cargando...';
  playQueueSize: number = 1;
  isRateActiveForUser: boolean = false;

  analytics = firebase.analytics();

  //rate: number;
  currentRate: number = 0;
  alreadyClickRate: boolean = false;

  uiSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();
  queuedTracksSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,  
               private route: ActivatedRoute,
               private store: Store<AppState>,
               private jukeboxService: JukeboxService,
               private sanitizer: DomSanitizer )  { }

  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
        .subscribe( ui => {
          this.isLoading = ui.isLoading;
    });

    this.route.params.subscribe(
      (params: Params) => {
        let jukeboxId = params['jukeboxId'];
        
        if ( jukeboxId && jukeboxId != this.jukeboxService.jukeboxIdSubscribed ) {
          this.alog.debug('ThanksComponent > jukeboxId', jukeboxId);
          this.jukeboxService.jukeboxIdSubscribed = jukeboxId;

          this.jukeboxService.subscribeJukeboxData(jukeboxId);
          this.jukeboxService.subscribeJukeboxConfiguration(jukeboxId);
        } 
      }
    );

    this.subscribeReduxJukeboxData();
    this.subscribeReduxJukeboxConfiguration();

    this.isRateActiveForUser = this.jukeboxService.isUserFeedbackActive();

    this.analytics.logEvent('web_screen', { screen: 'thanks' });
  }


  subscribeReduxJukeboxData() {
    this.jukeboxDataSubscription = this.store.select('jukeboxData')
          .pipe(
            filter( (jukeState) => jukeState.jukeboxData != null)
          )
          .subscribe( (jukeState) => {
            this.alog.debug('ThanksComponent > jukeboxDataSubscription:', this.jukeboxData);
            this.jukeboxData = jukeState.jukeboxData;

            this.subscribeQueuedTracks(jukeState.jukeboxData.partiesId);
      });
  }

  subscribeReduxJukeboxConfiguration() {
    this.jukeboxConfigurationSubscription = this.store.select('jukeboxConfiguration')
          .pipe(
            filter( (jukeState) => jukeState.jukeboxConfiguration != null)
          )
          .subscribe( (jukeState) => {
            this.alog.debug('ThanksComponent > subscribeReduxJukeboxConfiguration:', this.jukeboxData);
            this.jukeboxConfiguration = jukeState.jukeboxConfiguration;
      });
  }

  /**
   * 
   * @param partyId 
   */
  subscribeQueuedTracks(partyId: string) {

    this.queuedTracksSubscription = this.jukeboxService.subscribeQueuedTracks(partyId)
      .pipe(take(1))
      .subscribe(
        (queuedTracks: QueuedTrack[]) => {
          this.alog.debug('ThanksComponent > subscribeQueuedTrackList queuedTracks', queuedTracks);
          this.playQueueSize = queuedTracks.length;
          if (this.playQueueSize <= 1) {
            this.displayText = 'Tu canción es la siguiente. ¡Disfruta!';
          } else {
            this.displayText = 'Canción añadida con éxito. Sonará después de ' + this.playQueueSize + ' canciones. ¡Disfruta!';
          }
        },
        (err) => {
          this.alog.error('ThanksComponent > subscribeQueuedTrackList err', err);
        }
      );
  }

  public getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  clickOnInstagramLink() {
    this.alog.debug('ThanksComponent > clickOnInstagramLink');
    this.analytics.logEvent('web_button_click', { name: 'instagramLink', link: this.jukeboxConfiguration.instagramUser });
  }

  clickOnFeedbackLink() {
    this.alog.debug('ThanksComponent > clickOnFeedbackLink', this.currentRate.toString());
    this.jukeboxService.setUserAccessedRatingLinkOnLocalStorage();
    this.jukeboxService.setUserFeedbackOnLocalStorage(this.currentRate.toString());
    this.analytics.logEvent('web_button_click', { name: 'feedbackSiteLink', link: '/feedback' });
    this.analytics.logEvent('web_feedback', { screen: 'thanks', feedback_score: this.currentRate.toString() });
  }

  clickOnRatingLink() {
    this.alog.debug('ThanksComponent > clickOnRatingLink', this.currentRate.toString());
    this.jukeboxService.setUserAccessedRatingLinkOnLocalStorage();
    this.jukeboxService.setUserFeedbackOnLocalStorage(this.currentRate.toString());    
    this.analytics.logEvent('web_button_click', { name: 'ratingSiteLink', link: this.jukeboxConfiguration.ratingSiteUrl });
    this.analytics.logEvent('web_feedback', { screen: 'thanks', feedback_score: this.currentRate.toString() });
  }

  onClickRate() {
    this.alreadyClickRate = true;    
  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.jukeboxDataSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
    this.queuedTracksSubscription.unsubscribe();
  }

}
