import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpotifySong } from 'src/app/shared/models/spotify-song.model';
import { Subscription } from 'rxjs';
import { NgfireHelperService } from 'src/app/shared/services/ngfire-helper.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';
import { Jukebox, JukeboxData } from '../../../shared/models/jukebox.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-jukebox-tracks',
  templateUrl: './jukebox-tracks.component.html',
  styleUrls: ['./jukebox-tracks.component.css']
})
export class JukeboxTracksComponent implements OnInit, OnDestroy {

  songs: SpotifySong[] = [];
  isLoading: boolean = false;
  searchText: string;
  jukeboxData: JukeboxData;

  jukeboxDataSubscription: Subscription = new Subscription();
  jukeboxTracksSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               public adminService: AdminService,
               private ngFireService: NgfireHelperService ) { 

  }

  ngOnInit() {

    this.isLoading = true;

    this.jukeboxDataSubscription = this.store.select('jukeboxData')
        .pipe(
          filter( jukeboxState => jukeboxState.jukeboxData != null)
        )
        .subscribe( jukeboxState => {
          this.jukeboxData = jukeboxState.jukeboxData;
          this.getSongs(jukeboxState.jukeboxData.playlistsId);
        });
  }


  private getSongs(playlistsId: string) {

    this.jukeboxTracksSubscription = this.ngFireService.getSongs(playlistsId)
        .subscribe( (songs: SpotifySong[]) => {
          this.isLoading = false;
          this.songs = songs;
        });
  }

  deleteTracks() {
    this.adminService.removeTracksFromPlaylist(this.jukeboxData.playlistsId);
    this.adminService.setSelectedTrack([]);
  }

  ngOnDestroy() {
    this.jukeboxDataSubscription.unsubscribe();
    this.jukeboxTracksSubscription.unsubscribe();
  }
}
