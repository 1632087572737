import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Track } from 'src/app/shared/models/track.model';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { filter, take, debounceTime, catchError } from 'rxjs/operators';
import { JukeboxData, JukeboxConfig } from '../../../shared/models/jukebox.model';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { SpotifyService } from '../../../shared/services/spotify.service';

@Component({
  selector: 'app-song-list',
  templateUrl: './song-list.component.html',
  styleUrls: ['./song-list.component.css']
})
export class SongListComponent implements OnInit, OnDestroy {

  //trackList: Track[] = [];
  
  jukeboxData: JukeboxData;
  jukeboxConfiguration: JukeboxConfig;

  searchText: string = '';
  isLoading: boolean = false;
  isSearching: boolean = false;
  
  searchSongForm: FormGroup;
  requestSongForm: FormGroup;

  uiSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();
  

  constructor( private alog: NGXLogger,
               private route: ActivatedRoute,     
               private store: Store<AppState>,
               private spotifyService: SpotifyService,
               public jukeboxService: JukeboxService ) { }


  ngOnInit() {

    // Form Request song
    this.requestSongForm = new FormGroup({
      'songName': new FormControl(null, [Validators.required])
    });

    this.searchSongForm = new FormGroup({
      'songName': new FormControl(null, [Validators.required])
    });

    this.observeSearchSongFormChanges();

    this.uiSubscription = this.store.select('ui')
        .subscribe( ui => {
          this.isLoading = ui.isLoading;
    });

    this.observeRouteParamJukebox();

    this.subscribeReduxJukeboxData();
    this.subscribeReduxJukeboxConfiguration();
    
  }


  observeRouteParamJukebox() {
    this.route.params.subscribe(
      (params: Params) => {
        let jukeboxId = params['jukeboxId'];
        this.alog.debug('SongListComponent > jukeboxId', jukeboxId, this.jukeboxService.jukeboxIdSubscribed);

        if ( jukeboxId && jukeboxId != this.jukeboxService.jukeboxIdSubscribed ) {
          // TODO REMOVE subcribe to jukebox instead to data & configuration
          this.alog.debug('SongListComponent > jukeboxId', jukeboxId);
          this.jukeboxService.jukeboxIdSubscribed = jukeboxId;
          //this.jukeboxService.subscribeJukebox(jukeboxId);

          this.jukeboxService.subscribeJukeboxData(jukeboxId);
          this.jukeboxService.subscribeJukeboxConfiguration(jukeboxId);
        } 
      }
    );
  }


  observeSearchSongFormChanges() {
    this.searchSongForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe( value => {
        if ( value.songName ) {
          this.searchSongSpotify();
        }
      });
  }

  subscribeReduxJukeboxData() {
    this.jukeboxDataSubscription = this.store.select('jukeboxData')
      .pipe(
        filter( (jukeState) => jukeState.jukeboxData != null)
      )
      .subscribe( (jukeState) => {
        this.jukeboxData = jukeState.jukeboxData;
        if (this.jukeboxService.playlistTracks.length == 0) {
          this.jukeboxService.getPlaylistTracks(this.jukeboxData.playlistsId);              
        }
    });
  }

  subscribeReduxJukeboxConfiguration() {
    this.jukeboxConfigurationSubscription = this.store.select('jukeboxConfiguration')
      .pipe(
        filter( (jukeState) => jukeState.jukeboxConfiguration != null)
      )
      .subscribe( (jukeState) => {
        this.jukeboxConfiguration = jukeState.jukeboxConfiguration;
        if (this.jukeboxConfiguration.isSongSearchOpen
            && !this.spotifyService.isAuthenticatedAnonymous()) {
          this.spotifyService.login();
        }
    });
  }


  searchSongSpotify() {
    let songRequest = this.searchSongForm.get('songName').value;

    this.isSearching = true;
    this.spotifyService.searchTracks(songRequest)
      .pipe(
        take(1),
        catchError(this.spotifyService.handleUserError)
      )
      .subscribe(  (response: any) => {
        this.isSearching = false;
        this.jukeboxService.searchOpenTracks = response.items as Track[];
      });
  }

  /**
   * Request song Form. Save on database request
   */
  onRequestSongFormSubmit() {

    if (this.requestSongForm.valid) {

      let songRequest = this.requestSongForm.get('songName').value;

      var now = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');
      
      this.jukeboxService
          .pushSongRequests(this.jukeboxData.partiesId, songRequest + ' #Fecha: ' + now)
          .then( () => {
            this.requestSongForm.reset( { songName: '' } )
          });
  
      alert('Gracias!, Propondremos tu canción');
    }
  }

  onClickScrollUp() {
    window.scrollTo(0, 0); // very top
  }
  
  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.jukeboxDataSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
  }

}
