

<!-- 
<iframe src="https://surveys.hotjar.com/s?siteId=1336971&surveyId=146160" allowfullscreen></iframe> -->

<!-- <div style="position:relative;padding-top:56.25%;">
  <iframe src="https://surveys.hotjar.com/s?siteId=1336971&surveyId=146160" frameborder="0" allowfullscreen
    style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
</div> -->
<!--   
<iframe width="560" height="315" src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen></iframe> -->

<div *ngIf="this.appConfigService.getSubdomain().toLowerCase() != 'popsnbops'">

  <iframe style="border: 0; width:100%; height: 800px; overflow: auto;" src="https://surveys.hotjar.com/s?siteId=1336971&surveyId=146160"
          webkitallowfullscreen mozallowfullscreen allowfullscreen>
  </iframe>

</div>

<div *ngIf="this.appConfigService.getSubdomain().toLowerCase() == 'popsnbops'">

  <iframe style="border: 0; width:100%; height: 800px; overflow: auto;" src="https://surveys.hotjar.com/s?siteId=1336971&surveyId=151751"
          webkitallowfullscreen mozallowfullscreen allowfullscreen>
  </iframe>

</div>