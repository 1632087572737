import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription } from 'rxjs';
import * as firebase from 'firebase';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { SetUserAction } from 'src/app/shared/redux/actions/auth.actions';
import { UnsetUserAction } from '../redux/actions/auth.actions';
import { NgfireHelperService } from './ngfire-helper.service';
import { UnsetJukeboxDataAction } from '../redux/actions/jukebox-data.actions';
import { UnsetJukeboxConfigurationAction } from '../redux/actions/jukebox-configuration.actions';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  //fbUser: firebase.User; // Save logged in user data
  user: User;
  fbUserAnonymous: firebase.auth.UserCredential; // firebase.credenti .User

  private userSubscription: Subscription = new Subscription();

  readonly PATH_USERS: string = "users";

  URL_SALES: string;
  URL_SONGS_QUEUE: string;

  constructor( private router: Router,  
               private alog: NGXLogger,
               private store: Store<AppState>,
               private afAuth: AngularFireAuth,
               private ngFire: NgfireHelperService,
               private afDatabase: AngularFireDatabase ) {    
    // Saving user data in localstorage when logged in and setting up null when logged out
    this.initAuthListener();
  }

  /**
   * 
   */
  initAuthListener() {
    this.alog.debug('initAuthListener');

    this.afAuth.authState
      .subscribe( (fbUser: firebase.User) => {

        if (fbUser) {
          this.alog.debug('initAuthListener fbUser:', fbUser);

          localStorage.setItem('user', JSON.stringify(fbUser));
          
          if (fbUser.isAnonymous === false) {
            this.alog.debug('initAuthListener fbUser');

            this.userSubscription = 
              this.afDatabase.object(this.PATH_USERS + `/${fbUser.uid}`)
                .valueChanges()
                .subscribe( (usuarioObj: any) => {
                  this.alog.debug('initAuthListener > userSubscription > usuarioObj:', usuarioObj);
                  const newUser = new User( usuarioObj ); 
                  this.store.dispatch( new SetUserAction( newUser ));
                  this.user = newUser;
                  // var userFbPaviado = {...usuarioObj, ...fbUser};
                  // localStorage.setItem('user', JSON.stringify(userFbPaviado));
                });
          }

        } else {
          this.user = null;
          localStorage.setItem('user', null);
          //JSON.parse(localStorage.getItem('user'));
          this.userSubscription.unsubscribe();
        }
      });
  }

  
  /**
   * Sign in Anonymous
   */
  SignInAnonymously(): Promise<any> {

    return this.afAuth.auth.signInAnonymously()
      .then( (fbAuthUserCredential) => {
        // this.router.navigate(['/admin']);
        // TODO! FIXME Importante. Esta seccion sobreescribe valor de BD
        //this.SetAnonymousUserData(fbUser.user);
        this.fbUserAnonymous = fbAuthUserCredential;
        //this.store.dispatch( new SetUserAction( user.user ));
      })
      .catch((error) => {
        this.alog.error('AuthService > SignInAnonymously: error', error);
        //window.alert(error.message);
      });
  }

  // isAnonymousUser(): boolean {
  //   return this.au
  // }
  
  /**
   * Sign in with email/password
   * @param email 
   * @param password 
   */
  SignIn(email, password) {

    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then( (fbUser) => {
        this.router.navigate(['/admin']);
        // WARNING! Esta seccion sobreescribe valor de BD  
        const updatedUser: User = {
          uid: fbUser.user.uid,
          email: fbUser.user.email,
          photoURL: fbUser.user.photoURL,
          emailVerified: fbUser.user.emailVerified
        }
        this.ngFire.updateUser(fbUser.user.uid, updatedUser);
      })
      .catch((error) => {
        this.alog.error('AuthService > SignIn: error', error);
        window.alert(error.message);
      });
  }


  /**
   * Sign up with email/password
   * @param email 
   * @param password 
   * @param subdomain
   */
  SignUp(email, password, subdomain) {

    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then( (result) => {
        this.SendVerificationMail();
        this.setInitialUserData(result.user, subdomain);
      }).catch( (error) => {
        this.alog.error('AuthService > SignUp: error', error);
        window.alert(error.message)
      });
  }

  /**
   * Send email verfificaiton when new user sign up
   */
  SendVerificationMail() {

    return this.afAuth.auth.currentUser
      .sendEmailVerification()
      .then( () => {
        // If we want to validate the email address
        //this.router.navigate(['/admin/verify-email-address']);
        this.router.navigate(['/admin']);
      })
      .catch( (error) => {
        this.alog.error('AuthService > SendVerificationMail: error', error);
        window.alert(error.message)
      });
  }

  /**
   * Reset Forggot password
   * @param passwordResetEmail 
   */
  ForgotPassword(passwordResetEmail) {

    return this.afAuth.auth
      .sendPasswordResetEmail(passwordResetEmail)
      .then( () => {
        window.alert('Email restablecimiento contraseña reenviado, comprueba tu correo de entrada.');
      })
      .catch((error) => {
        this.alog.error('AuthService/ForgotPassword: error', error);
        window.alert(error);
      })
  }

  /**
   * Returns true when user is looged in with email and password
   */
  get isLoggedIn(): boolean {    
    const user = JSON.parse(localStorage.getItem('user'));
    // Desactivado confirmacion de email
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return (user !== null && !user.isAnonymous) ? true : false;
  }

  /**
   * Returns true when user is looged in with email and password
   */
  get isPremiumAccount(): boolean {    
    const user = JSON.parse(localStorage.getItem('user'));
    // Desactivado confirmacion de email
    // // return (user !== null && user.emailVerified !== false) ? true : false;
    // console.log('user.role:', user.role);
    // console.log('user:', user);
    return (user !== null && user.role !== 'free') ? true : false;
  }

  /**
   * Returns true when user is looged in any type (Anonymous included)
   */
  get isLoggedInAny(): boolean {    
    const user = JSON.parse(localStorage.getItem('user'));
    // Desactivado confirmacion de email
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return (user !== null) ? true : false;
  }

  // isAuth() {
  //   return this.afAuth.authState
  //     .pipe(
  //       map( fbUser => { 
  //         fbUser != null
  //       })
  //     )
  // }


  /**
  * Create the user for FIRST access (SignUp)
  * @param user 
  */
  setInitialUserData(user, subdomain: string) {

    const userData: User = {
      uid: user.uid,
      email: user.email,
      role: 'owner', 
      subscription: 'basic', 
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      isAdminTourCompleted: false,
      subdomain: subdomain,
      roleAccessRestrictions: ''
    }

    this.ngFire.setInitialUserData(userData.uid, userData);
  }

  /**
   * 
   */
  signOut() {

    this.store.dispatch( new UnsetUserAction() );
    this.store.dispatch( new UnsetJukeboxDataAction() );
    this.store.dispatch( new UnsetJukeboxConfigurationAction() );
    
    return this.afAuth.auth
      .signOut()
      .then( () => {
          localStorage.removeItem('user');
          this.router.navigate(['/admin/sign-in']);
        });
                
  }


  getUser() {
    return { ...this.user };
  }

}