
import { Action } from '@ngrx/store'
import { Sale } from '../../models/sale.model';


export const SET_SALES = '[Sales] Set Sales';
export const UNSET_SALES = '[Sales] Unset Sales';


export class SetSalesAction implements Action {
  readonly type = SET_SALES;

  constructor( public sales: Sale[] ) { }
}

export class UnsetSalesAction implements Action {
  readonly type = UNSET_SALES;
}

export type acciones = SetSalesAction | UnsetSalesAction;