import { Song } from './song.model';

export class Lead {
  
    public id: string;
    public name: string;

    public shortText: string;
    public longText: string;

    public isChecked: boolean;

    public email: string;
    public age: string;
    public year: string;
    public gender: string;
    

    
    constructor() {
    }

    setFirebaseId() {
    }

    setName(name:string) {
      this.name = name;
    }

    setEmail(email:string) {
      this.email = email;
    }

    setAge(age:string) {
      this.age = age;
    }

    setGender(gender:string) {
      this.gender = gender;
    }

    setYear(year:string) {
      this.year = year;
    }

}