import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  subdomain: string = '';
  jukeboxesParties: any[] = [];
  imgUrlCompanyLogo: string = '';

  constructor(private http: HttpClient) { }

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      
      const domain = window.location.hostname;
      if (domain.indexOf('.') < 0 || 
          domain.split('.')[0] === 'example') {

      //this.subdomain = 'mi';
      this.subdomain = 'javi';

      } else {
        this.subdomain = domain.split('.')[0];
      }

      if (this.subdomain != 'mi') {
        const path = environment.urlFirebase + 'subdomains/' + this.subdomain + ".json";
        this.http.get(path).subscribe(
          (response: any) => {
            this.jukeboxesParties = response.jukeboxes_parties;
            this.imgUrlCompanyLogo = response.imgUrlCompanyLogo;
            resolve();
          },
          (err) => { 
            console.log(err);
            resolve();
          });

      } else {
        resolve();
      }      
      
    });
  }


  getSubdomain(): string {
    return this.subdomain;
  }

  getJukeboxesParties(): any[] {
    return this.jukeboxesParties;
  }

  getImgUrlCompanyLogo(): string {
    return this.imgUrlCompanyLogo;
  }


}
