
<header class="header">
  <nav class="navbar">
    <div class="container-fluid">
      <div class="navbar-holder d-flex align-items-center justify-content-between">
        <div class="navbar-header">
          <a id="toggle-btn" (click)="toggleSidebar()" class="menu-btn">
            <i class="fas fa-bars fa-2x"></i>
          </a>
          <a (click)="toggleSidebar()" class="navbar-brand">
            <div class="brand-text d-none d-md-inline-block">
              <strong class="text-primary">Dashboard</strong>
            </div>
          </a>
        </div>
        <div class="navbar-nav mr-auto" style="display: inline-block;">
          <a href="https://www.instagram.com/paviado_com/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram" style="color: white;padding: 10px;"></i></a>
          <a href="https://www.linkedin.com/company/paviado/" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin-in" style="color: white;padding: 10px;"></i></a>
          <!-- <a href="https://www.facebook.com/paviado/" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-f" style="color: white;padding: 10px;"></i></a> -->
          <a href="https://wa.me/34647145521" target="_blank" rel="noopener noreferrer"><i class="fab fa-whatsapp" style="color: white;padding: 10px;"></i></a>
          <a href="mailto:hola@paviado.com?Subject=Hola"><i class="far fa-envelope" style="color: white;padding: 10px;"></i></a>
        </div>
        <ul class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
          <!-- Notifications dropdown -->
          <!-- Messages dropdown -->          
          <!-- Languages dropdown -->
          <li *ngIf="jukeboxes.length > 1" joyrideStep="step-2" title="Tus Paviados" text="Con este desplegable podrás cambiar entre tus diferentes dipositivos"
              (click)="toggleJukeboxList()"
              class="nav-item dropdown" 
              [ngClass]="{ 'show': isJukeboxListExpanded }">

            <a id="languages" rel="nofollow" style="color: white;"
               data-target="#" 
               data-toggle="dropdown" aria-haspopup="true" 
               aria-expanded="false" class="nav-link dropdown-toggle language cursor">
              <!-- <img src="img/flags/16/GB.png" alt="English"> -->
              <span class="d-none d-sm-inline-block">
                {{ jukeboxActive | truncate: 16 }}
              </span>
              <!-- <i class="fas fa-caret-down"></i> -->
            </a>
            <ul *ngIf="user"
                aria-labelledby="languages" 
                class="dropdown-menu" 
                [ngClass]="{ 'show': isJukeboxListExpanded }">

              <li *ngFor="let jukebox of jukeboxes">
                <a (click)="onJukeboxSelect(jukebox.jukebox_data.id)"
                   rel="nofollow" 
                   class="dropdown-item"> 
                  <!-- <img src="img/flags/16/DE.png" alt="English" class="mr-2"> -->
                  <span>{{ jukebox.jukebox_configuration.displayName | truncate: 50 }}</span>
                </a>
              </li>
            </ul>
          </li>

          <!-- Log out-->
          <li class="nav-item">
            <a (click)="signOut()" class="nav-link logout" style="cursor: pointer; color: white;"> 
              <span class="d-none d-sm-inline-block">Cerrar Sesión</span><i class="fas fa-sign-out-alt"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>