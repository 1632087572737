
<!-- Top navigation 
<app-navigation-admin></app-navigation-admin>
-->
<br>
<!---->

<div class="container-fluid">

  <div *ngIf="isLoading" class="container text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="!isLoading">  

    <div >  <!--*ngIf="jukebox"-->

      <form [formGroup]="jukeboxConfigForm"
            (ngSubmit)="onJukeboxConfigFormSubmit()">

        <div class="row">
          
          <div class="col-md-8">
            
            <div class="text-left">
                <b> Nombre a mostrar: </b><br>  
            </div>

            <div class="input-group">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
              </div>
              <input type="text" class="form-control" 
                    id="displayName" formControlName="displayName"
                    maxlength="64" placeholder="Nombre">
              <div class="input-group-append">
                <span class="input-group-text" style="color: black;">
                  <i class="fas fa-question-circle" placement="left" 
                    popoverTitle="Nombre"
                    ngbPopover="Aparecerá en la búsqueda de canciones. Si dispones de varios locales es útil para identificarlos."></i>
                </span>
              </div>
            </div>

            <div class="text-left">
              <b> Cuenta instagram del local: </b><br>  
            </div>
            <div class="input-group">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fab fa-instagram"></i></span>
              </div>
              <input type="text" class="form-control" 
                    id="instagramUser" formControlName="instagramUser"
                    maxlength="64" placeholder="paviado_com">
              <div class="input-group-append">
                <span class="input-group-text" style="color: black;">
                  <i class="fas fa-question-circle" placement="left" 
                    popoverTitle="Instagram"
                    ngbPopover="Tras solicitar una canción. Pediremos que te sigan en instagram redirigiendo a esta cuenta."></i>
                </span>
              </div>
            </div>

            <div class="text-left">
              <b> URL recepción reviews del local: </b><br>  
            </div>
            <div class="input-group">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fab fa-tripadvisor"></i></span>
              </div>
              <input type="text" class="form-control" 
                    id="ratingSiteUrl" formControlName="ratingSiteUrl"
                    maxlength="256" placeholder="https://g.page/r/CUbt9cf3DzdyEAI/review">
              <div class="input-group-append">
                <span class="input-group-text" style="color: black;">
                  <i class="fas fa-question-circle" placement="left" 
                    popoverTitle="Reseñas"
                    ngbPopover="Tras solicitar una canción. Pediremos una valoración en la web que consideres." ></i>
                </span>
              </div>
            </div>
            
            <div class="text-left">
              <b> Tamaño de la cola de espera: </b><br>  
            </div>
            <div class="input-group">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-list-ol"></i></span>
              </div>
              <input type="number" class="form-control" 
                      oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                      formControlName="songQueueMaxSize"
                      min="1" max="50"
                      placeholder="1 - 50">
              <div class="input-group-append">
                <span class="input-group-text" style="color: black;">
                  <i class="fas fa-question-circle" placement="left" 
                    popoverTitle="Tamaño de cola"
                    ngbPopover="Este campo indica cuantas canciones como máximo permites en espera. Ten en cuenta que un valor muy alto afecta a la experiencia del cliente"></i>
                </span>
              </div>
            </div>
            
            <div class="text-left">
              <b> Tiempo trascurrido hasta repetir canción (Minutos): </b><br>  
            </div>
            <div class="input-group">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-stopwatch"></i></span>
              </div>
              <input type="number" class="form-control" 
                      oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                      formControlName="timeElapsedRepeatSong"
                      min="5" max="600"
                      placeholder="Minutos (5 - 600)">
              <div class="input-group-append">
                <span class="input-group-text" style="color: black;">
                  <i class="fas fa-question-circle" placement="left" 
                    popoverTitle="Repetición de canción" 
                    ngbPopover="Para evitar que se repitan canciones las ocultamos una vez reproducida. Este valor indica los minutos transcurridos para que aparezca de nuevo."></i>
                </span>
              </div>
            </div>

            <div *ngIf="jukeboxConfiguration.id.length < 17">
              <div class="text-left">
                <b> Código de salida del modo Kiosko: </b><br>  
              </div>
              <div class="input-group">
                <div class="input-group-append">
                  <span class="input-group-text"><i class="fas fa-sign-out-alt"></i></span>
                </div>
                <input type="number" class="form-control" 
                        oninput="if(this.value.length >= 4) { this.value = this.value.slice(0,4); }"
                        formControlName="kioskModeExitCode"
                        min="0" max="9999"
                        placeholder="1984">
                <div class="input-group-append">
                  <span class="input-group-text" style="color: black;">
                    <i class="fas fa-question-circle" placement="left" 
                      popoverTitle="Código salida" 
                      ngbPopover="Para salir del modo jukebox, mantén pulsado la barra superior e introduce este código"></i>
                  </span>
                </div>
              </div>
              <br>

              <label class="btn btn-default" style="background-color: transparent;">
                <input type="checkbox" name="conditions" 
                        formControlName="noDataButtonActive"
                        [checked]="jukeboxConfiguration.dialogConfiguration.secondaryButton.isActive"> 
                Permitir canciones sin datos
              </label>
              <br>

              <label class="btn btn-default" style="background-color: transparent;">
                <input type="checkbox" name="conditions" 
                        formControlName="isAdsModeActive"
                        [checked]="jukeboxConfiguration.ads_mode?.isAdsModeActive"> 
                Modo publicidad activo
                <br>   
              </label>
              <div *ngIf="jukeboxConfigForm.get('isAdsModeActive').value">
                <label class="btn btn-default" style="background-color: transparent;">
                  <input type="checkbox" name="conditions" 
                          formControlName="isDataGatheringActive"
                          [checked]="jukeboxConfiguration.ads_mode?.isDataGatheringActive"> 
                  Recoger datos de usuarios en modo publicidad
                  <br>   
                </label>
              </div>
            </div>

            <div class="text-center">
              <br>
              <button type="submit" name="" id="" class="btn btn-primary" 
                      [disabled]="!jukeboxConfigForm.valid || !jukeboxConfigForm.dirty">Guardar cambios</button>
            </div>
              
          </div>

          <br>
          <!-- <div class="col-md-6 text-center">
            <img class="img-fluid" src="../../../assets/images/demo_app.png" style="max-width: 300px;">  
          </div> -->

        </div>

        <br>

      </form>
    </div>

  </div>   
</div>


<ng-template #customJoyrideStepContent1>
  <i class="fas fa-images"></i>
  En "Configuración", puedes modificar:
  <ol>
    <li>El nombre que verán los usuarios para identificar este equipo.</li>
    <li>El número máximo de canciones en la cola de espera.</li>
    <li>El tiempo que se oculta una canción tras ser reproducida antes de permitir seleccionarla de nuevo.</li>
    <li>El código de salida del módo Kiosko: Si dispones de un Paviado físico, para salir de la aplicación debes mantener pulsada la barra superior e introducir este código.</li>
    <li>La posibilidad de introducir canciones sin datos.</li>
  </ol>
</ng-template>
