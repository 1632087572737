


import { Action } from '@ngrx/store'
import { JukeboxConfig } from '../../models/jukebox.model';


export const SET_JUKEBOX_CONFIGURATION = '[JukeboxConfiguration] Set jukebox configuration';
export const UNSET_JUKEBOX_CONFIGURATION = '[JukeboxConfiguration] Unset jukebox configuration';
export const UPDATE_JUKEBOX_CONFIGURATION = '[JukeboxConfiguration] Update jukebox configuration';



export class SetJukeboxConfigurationAction implements Action {
  readonly type = SET_JUKEBOX_CONFIGURATION;

  constructor( public jukeboxConfiguration: JukeboxConfig ) {}
}

export class UnsetJukeboxConfigurationAction implements Action {
  readonly type = UNSET_JUKEBOX_CONFIGURATION;
}

export class EditJukeboxConfigurationAction implements Action {
  readonly type = UPDATE_JUKEBOX_CONFIGURATION;

  constructor( public id: string, public jukeboxConfiguration: JukeboxConfig ) {}
}


export type Actions = SetJukeboxConfigurationAction 
                    | UnsetJukeboxConfigurationAction 
                    | EditJukeboxConfigurationAction;