<!--
  <div class="form-group">
    <h5>Seleccionar image:</h5>
    <input type="file" accept=".png, .jpg" (change)="onUpload($event)">
  </div>
  <div class="progress">
    <div [style.visibility]="(uploadPercent == 0) ? 'hidden' : 'visible'" class="progress-bar progress-bar-striped bg-success"
      role="progressbar" [style.width]="(uploadPercent | async) +'%'">

      <!-- <span class="progressText" *ngIf="urlImage | async">
        Ok!!</span> 
    </div>
  </div>
  <br>
  <input #imageUser type="hidden" [value]="urlImage | async">
-->
<nav class="navbar fixed-top navbar-expand-md navbar-dark bg-primary mb-3">
  <div class="flex-row d-flex">
      <button type="button" class="navbar-toggler mr-2 " data-toggle="offcanvas" title="Toggle responsive left sidebar">
          <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" href="#" title="Free Bootstrap 4 Admin Template">Admin Template</a>
  </div>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
      <span class="navbar-toggler-icon"></span>
  </button>

  <div class="navbar-collapse collapse" id="collapsingNavbar">
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
            <a class="nav-link" href="#myAlert" data-toggle="collapse">Perfil</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="" data-target="#myModal" data-toggle="modal">Salir</a>
        </li>
    </ul>
  </div>
</nav>

<div class="container-fluid" id="main">
  <div class="row row-offcanvas row-offcanvas-left">
      <div class="col-md-3 col-lg-2 sidebar-offcanvas bg-light pl-0" id="sidebar" role="navigation">
          <ul class="nav flex-column sticky-top pl-0 pt-5 mt-3">
              <li class="nav-item"><a class="nav-link" href="#">Overview</a></li>
              <li class="nav-item">
                  <a class="nav-link" href="#submenu1" data-toggle="collapse" data-target="#submenu1">Reports▾</a>
                  <ul class="list-unstyled flex-column pl-3 collapse" id="submenu1" aria-expanded="false">
                     <li class="nav-item"><a class="nav-link" href="">Report 1</a></li>
                     <li class="nav-item"><a class="nav-link" href="">Report 2</a></li>
                  </ul>
              </li>
              <li class="nav-item"><a class="nav-link" href="#">Analytics</a></li>
              <li class="nav-item"><a class="nav-link" href="#">Export</a></li>
              <li class="nav-item"><a class="nav-link" href="#">Snippets</a></li>
              <li class="nav-item"><a class="nav-link" href="#">Flexbox</a></li>
              <li class="nav-item"><a class="nav-link" href="#">Layouts</a></li>
              <li class="nav-item"><a class="nav-link" href="#">Templates</a></li>
              <li class="nav-item"><a class="nav-link" href="#">Themes</a></li>
          </ul>
      </div>
      <!--/col-->

      <div class="col main pt-5 mt-3">
          <h1 class="display-4 d-none d-sm-block">
          Bootstrap 4 Dashboard
          </h1>
          <p class="lead d-none d-sm-block">Plus off-canvas sidebar, based on Bootstrap v4</p>

          

          <a id="more"></a>
          <hr>
          <h2 class="sub-header mt-5">Use card decks for equal height rows of cards</h2>
          
          <!--/row-->

          <a id="flexbox"></a>
          <hr>
          <h2 class="mt-5">Masonry-style grid columns</h2>
          <h6>with Bootstrap 4 flexbox</h6>

          
          <!--/card-columns-->

          <a id="layouts"></a>
          <hr>
          <h2 class="sub-header mt-5">Interesting layouts and elements</h2>
          <div class="row mb-3">
              
          </div><!--/row-->

      </div>
      <!--/main col-->
  </div>

</div>
<!--/.container-->
<footer class="container-fluid">
  <p class="text-right small">©2016-2018 Company</p>
</footer>
