


<footer class="main-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <p>Paviado &copy; 2019</p>
      </div>
      <div class="col-sm-6 text-right">
        <p>Esta es una versión beta, <a href="mailto:hola@paviado.com?Subject=Sugerencia" target="_top">¿qué te gustaría cambiar?</a></p>        
        <!-- Please do not remove the backlink to us unless you support further theme's development at https://bootstrapious.com/donate. It is part of the license conditions and it helps me to run Bootstrapious. Thank you for understanding :)-->
      </div>
    </div>
  </div>
</footer>
