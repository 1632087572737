
import * as fromSales from '../actions/sale.actions';
import { Sale } from 'src/app/shared/models/sale.model';

export interface SaleState {
  sales: Sale[];
}

// export interface AppState extends AppState {
//   sales: SaleState;
// }

const estadoInicial: SaleState = {
  sales: []
};


export function salesReducer( state = estadoInicial, action: fromSales.acciones ): SaleState {

  switch ( action.type ) {

      case fromSales.SET_SALES:
          return {
              sales: [
                  ...action.sales.map( item => {
                      return {
                          ...item
                      };
                  })
              ]
          };

      case fromSales.UNSET_SALES:
          return {
              sales: []
          };

      default:
          return state;

  }
}