// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = { 
  production: false,
  name: 'test',
  urlFirebase: "https://test-paviado.firebaseio.com/",
  paypalClientId: "ASLIvVDthuWfsKK8ble0FIfDWWSo88sedm95QrCBJmqdX9YAuj411Q5np6d3GxNwU7rDgF2zcC_l75YX",
  stripeClientId: "pk_test_6rmNGUwxEwR4nzCufEX7ksH100ZdPQ9Tde",
  stripeChargeCloudFunction: "https://us-central1-test-paviado.cloudfunctions.net/charge",
  firebase: {
    apiKey: "AIzaSyBaWJQHbKEaGUG_h2wnxl68hF17ptUJFWQ",
    authDomain: "test-paviado.firebaseapp.com",
    databaseURL: "https://test-paviado.firebaseio.com",
    projectId: "test-paviado",
    storageBucket: "test-paviado.appspot.com",
    messagingSenderId: "403986992166",
    appId: "1:403986992166:web:93a1f47996973d33",
    measurementId: "G-L84B4STCBC"
  },
  spotifyClientId: '9fdf4811936b4e2d97a760e284a26a3d',
  spotifyClientSecret: 'dcf9b9aab7bd45f0ae53bd205c00dc3b',
  spotifyRedirectUri: 'https://mi.rocola.es/login/spotify/callback',
  googleRecaptchaV2: '6Lf7ONcUAAAAALkPGeZ-xGkgJDmzzY72su1qP4mw',
  googleRecaptchaV3: '6Lcp_uMUAAAAAMsAIq1SrNDpqwGQueXXh3BeMw_-', 
  // Firebase functions. First _ should be replaced with . on set get
  slack_webhook: 'https://hooks.slack.com/services/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',
  spotify_client_id: '9fdf4811936b4e2d97a760e284a26a3d',
  spotify_client_secret: 'dcf9b9aab7bd45f0ae53bd205c00dc3b',
  spotify_redirect_uri: 'https://mi.rocola.es/login/spotify/callback-auth-code'
  };
