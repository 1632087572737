import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { JukeboxConfig, AdImage } from '../models/jukebox.model';
import { QueuedTrack } from '../models/queued-track.model';
import { Sale } from '../models/sale.model';
import { take, debounceTime, map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { PartyData } from '../models/party-data.model';


@Injectable({
  providedIn: 'root'
})
export class NgfireHelperService {
    
  readonly PATH_SUBDOMAINS: string = "subdomains";
  readonly PATH_USERS: string = "users";
  readonly PATH_JUKEBOXES: string = "jukeboxes";
  readonly PATH_PLAYLISTS: string = "playlists";
  readonly PATH_PLAYLISTS_PLAYLIST: string = "playlist";
  readonly PATH_PLAYLISTS_NAME: string = "name";
  readonly PATH_PARTIES: string = "parties";
  readonly PATH_PARTY_DATA: string = "party_data";
  readonly PATH_PARTIES_SALES: string = "sales";
  readonly PATH_PARTIES_QUEUED_TRACKS: string = "queued_tracks";
  readonly PATH_PARTIES_PROMO_CODES: string = "promo_codes";
  readonly PATH_PARTIES_SONG_REQUESTS: string = "song_requests";

  readonly PATH_PLAYLIST: string = "playlist";
  readonly PATH_SONGS: string = "songs";
  readonly PATH_USER_JUKEBOXES: string = "user_jukeboxes";
  readonly PATH_JUKEBOX_CONFIGURATION: string = "jukebox_configuration";
  readonly PATH_IMGS_URL: string = "imgs_url"; // imgsUrl
  readonly PATH_IMGS_URL_POPUPS: string = "popups"; // imgsUrl
  readonly PATH_JUKEBOX_REALTIME: string = "jukebox_realtime";
  readonly PATH_JUKEBOX_DATA: string = "jukebox_data";


  constructor( private db: AngularFireDatabase ) { }


  /* *****************************/
  /* **** ADMIN           ********/
  /* *****************************/

  getPartiesId(subdomainId: string) {
    const path = this.PATH_SUBDOMAINS + '/' + subdomainId;
    return this.db.object(path).valueChanges();
  }            

  getPlaylistLoaded(jukeboxesId: string) {
    const path = this.PATH_PLAYLISTS + '/' + jukeboxesId + '/' + this.PATH_PLAYLISTS_PLAYLIST + '/' + this.PATH_PLAYLISTS_NAME;
    return this.db.object(path).valueChanges();
  }

  getSongRequests(partiesId: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SONG_REQUESTS;
    return this.db.list(path, ref => ref.limitToLast(20).orderByKey()).valueChanges();
  }

  updatePlaylist(playlistId:string, payload:any): Promise<void> {    
    const path = this.PATH_PLAYLISTS + '/' + playlistId;
    return this.db.list(path).set(this.PATH_PLAYLIST, payload);
  }

  updatePlaylistData(playlistId:string, playlist: any): Promise<void> {
    const path = this.PATH_PLAYLISTS + '/' + playlistId + '/' + this.PATH_PLAYLIST;
    return this.db.object(path).update(playlist);
  }

  updatePlaylistSongs(playlistId:string, songs: any): Promise<void> {
    const path = this.PATH_PLAYLISTS + '/' + playlistId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS;
    return this.db.object(path).set(songs);
  }

  getTracksAvailableToPlay(playlistsId: string) {
    const path = this.PATH_PLAYLISTS + '/' + playlistsId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS;
    //return this.db.list(path, ref => ref.orderByChild('isAvailableToPlay').equalTo(true)).valueChanges();
    return this.db.list(path, ref => ref.limitToFirst(500)).valueChanges();//.stateChanges();
  }

  getPlaylistTracks(playlistId: string) {
    const path = this.PATH_PLAYLISTS + '/' + playlistId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS;
    //return this.db.list(path, ref => ref.orderByChild('isAvailableToPlay').equalTo(true)).valueChanges();
    return this.db.list(path, ref => ref.limitToFirst(500)).valueChanges();//.stateChanges();
  }

  getSongs(partiesId:string) {
    const path = this.PATH_PLAYLISTS + '/' + partiesId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS;
    return this.db.list(path).valueChanges();
  }

  getJukebox(jukebox_uuid: string) {
    const path = this.PATH_JUKEBOXES + '/' + jukebox_uuid;
    return this.db.object(path).valueChanges();
  }

  subscribeJukeboxData(jukeboxId: string) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_DATA;
    return this.db.object(path).valueChanges();
  }

  subscribeJukeboxConfiguration(jukeboxId: string) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_CONFIGURATION;
    return this.db.object(path).valueChanges();
  }

  subscribeJukeboxRealtime(jukeboxId: string) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_REALTIME;
    return this.db.object(path).valueChanges();
  }

  removeTrack(jukebox_uuid:string, songKey: string): Promise<void> {
    const pathToObject = this.PATH_PLAYLISTS + '/' + jukebox_uuid + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS + '/' + songKey;
    return this.db.object(pathToObject).remove();
  }

  updateJukeboxConfig(jukeboxConfig: JukeboxConfig) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxConfig.id + '/' + this.PATH_JUKEBOX_CONFIGURATION; // Remove ": uid.replace(/['"]+/g, '')
    return this.db.object(path).update(jukeboxConfig);
  }

  pushImagePopup(jukeboxConfig: JukeboxConfig, adImage: AdImage) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxConfig.id + '/' + this.PATH_JUKEBOX_CONFIGURATION + '/' + this.PATH_IMGS_URL + '/' + this.PATH_IMGS_URL_POPUPS;
    adImage.id = this.db.createPushId();
    return this.db.list(path).set(adImage.id, adImage);
  }

  updatePopupImage(jukeboxId: string, adImage: AdImage) {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_CONFIGURATION + '/' + this.PATH_IMGS_URL + '/' + this.PATH_IMGS_URL_POPUPS + '/' + adImage.id;
    return this.db.object(path).update(adImage);
  }

  deletePopupImage(jukeboxId: string, adImageId: string): Promise<void> {
    const path = this.PATH_JUKEBOXES + '/' + jukeboxId + '/' + this.PATH_JUKEBOX_CONFIGURATION + '/' + this.PATH_IMGS_URL + '/' + this.PATH_IMGS_URL_POPUPS + '/' + adImageId;
    return this.db.object(path).remove();
  }


  /* *****************************/
  /* **** TRACK LIST      ********/
  /* *****************************/

  pushSongRequests(partiesId: string, requestedSong: string): Promise<any> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SONG_REQUESTS;
    return this.db.list(path).push(requestedSong);
  }

  subscribeSelectedTrack(playlistId: string, trackId: string) {
    const path = this.PATH_PLAYLISTS + '/' + playlistId + '/' + this.PATH_PLAYLIST + '/' + this.PATH_SONGS + '/' + trackId;
    return this.db.object(path).valueChanges();
  }


  /* *****************************/
  /* **** QUEUED TRACKS   ********/
  /* *****************************/

  getQueuedTracks(partiesId: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_QUEUED_TRACKS;
    return this.db.list(path).valueChanges();
  }

  subscribeQueuedTracks(partiesId: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_QUEUED_TRACKS;
    return this.db.list(path).valueChanges();
  }


  /* *****************************/
  /* **** CART TRACKS     ********/
  /* *****************************/

  pushQueuedTrack(partiesId: string, songQueued: QueuedTrack) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_QUEUED_TRACKS;
    songQueued.id = this.db.createPushId();
    const promise = this.db.list(path).set(songQueued.id, songQueued);
    return promise;
  }


  /* *****************************/
  /* ****    SALES        ********/
  /* *****************************/

  pushSale(partiesId: string, sale: Sale) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SALES;
    sale.id = this.db.createPushId();
    const promise = this.db.list(path).set(sale.id, sale);
    return promise;
  }

  getSalesList(partiesId: string, startAtDateStr: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SALES;
    //return this.db.list(path, ref => ref.limitToLast(600).orderByKey().limitToLast(20)).valueChanges();
    return this.db.list(path, ref => ref.orderByChild('date_transaction')
                                        .startAt(startAtDateStr))
                                        .valueChanges();
  }

  updateSale(sale: Sale, partiesId: string): Promise<void> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_SALES + '/' + sale.id;
    return this.db.object(path).update(sale);
  }


  /* *****************************/
  /* ****  PROMO CODES  **********/
  /* *****************************/

  getPromoCodesList(partiesId: string) {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_PROMO_CODES;
    return this.db.list(path).valueChanges();
  }

  removePromoCode(partiesId: string, promoCodeId: string): Promise<void> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_PROMO_CODES + '/';
    return this.db.list(path + promoCodeId).remove();
  }  

  setPromoCodes(partiesId: string, promoCodes: any): Promise<void> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/';
    return this.db.list(path).set(this.PATH_PARTIES_PROMO_CODES, promoCodes);
  }

  pushPromoCodes(partiesId: string, promoCodes: any): Promise<any> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTIES_PROMO_CODES;
    return this.db.list(path).push(promoCodes);
  }

  validatePromoCode(partyId:string, promoCode: string) {
    return this.db.object(this.PATH_PARTIES + '/' + partyId + '/' + this.PATH_PARTIES_PROMO_CODES + '/' + promoCode)
      .valueChanges()
      .pipe(
        debounceTime(500),
        take(1),
        map((promoCodeExists: any) => {
          if (!promoCodeExists) {                      
            return {
              promoCodeExists: false
            }
          }
          return null;
        })
      );
  }

  getPromoCode(partyId: string, promoCodeId: string) {
    const path = this.PATH_PARTIES + '/' + partyId + '/' + this.PATH_PARTIES_PROMO_CODES + '/' + promoCodeId
    return this.db.object(path).valueChanges();
  }


  /* *****************************/
  /* **** USER            ********/
  /* *****************************/

  updateUser(userId:string, user: User): Promise<void> {
    const path = this.PATH_USERS + '/' + userId;
    return this.db.object(path).update(user);
  }

  setInitialUserData(userId:string, user: User): Promise<void> {
    const path = this.PATH_USERS + '/' + userId;
    return this.db.object(path).set(user);
  }


  /* *****************************/
  /* ****  PARTY DATA   **********/
  /* *****************************/

  observePartyData(partiesId: string): Observable<any> {
    const path = this.PATH_PARTIES + '/' + partiesId + '/' + this.PATH_PARTY_DATA;
    return this.db.object(path).valueChanges();
  }

  updatePartyData(partyData: PartyData) {
    const path = this.PATH_PARTIES + '/' + partyData.id + '/' + this.PATH_PARTY_DATA;
    return this.db.object(path).update(partyData);
  }

}
