import { Component, OnInit, Input } from '@angular/core';
import { Sale } from 'src/app/shared/models/sale.model';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-sale-item',
  templateUrl: './sale-item.component.html',
  styleUrls: ['./sale-item.component.css']
})
export class SaleItemComponent implements OnInit {

  @Input() sale: Sale;
  @Input() partiesId: string;

  constructor( private adminService: AdminService ) { }


  ngOnInit() {
  }


  onDeleteSale() {
    this.sale.isDeletedOnMonitor = true;
    this.adminService.updateSale(this.sale, this.partiesId);
  }

}
