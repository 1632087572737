import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import * as firebase from 'firebase/app';
import { JoyrideService } from 'ngx-joyride';
//import * as analytics from 'firebase/analytics';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  
  title = 'Paviado';

  env = environment;

  constructor( ) { 
  }

  ngOnInit() {
    firebase.initializeApp(environment.firebase);
    firebase.analytics();
  }


}
