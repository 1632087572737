import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  modalCollapse: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  
  onContactModalCollapse() {
    this.modalCollapse = !this.modalCollapse;
    //open modal using jQuery
    //jQuery(this.myModal.nativeElement).modal('show'); 
  }

}
