
<br>

<div *ngIf="isLoading || isProcessingData" class="container text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading && !isProcessingData && (leadsCount == 0 && promoCount == 0)"
    class="row">
  <div class="col text-center">
    <h1>No hay canciones en las últimas 4 semanas :(</h1>
  </div>   
</div>

<div *ngIf="!isLoading && !isProcessingData && (leadsCount > 0 || promoCount > 0)">

  <div class="row">
    <div class="col text-center">
      <h1>Actividad</h1>
    </div>   
  </div>

  <div class="card" joyrideStep="step-5" [stepContent]="customJoyrideStepContent1">
    <div class="card-header">
      <h4>Canciones solicitadas</h4>
    </div>
    <div class="card-body">
      <div style="display: block;">
        <canvas baseChart 
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [colors]="lineChartColors"
                    [legend]="lineChartLegend"
                    [chartType]="lineChartType">
        </canvas>
        </div>
    </div>
  </div>

  <br>

  <div class="card" joyrideStep="step-6" [stepContent]="customJoyrideStepContent2">
    <div class="card-header">
      <h4>Top 5 grupos del mes</h4>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Grupo</th>
              <th>Solicitudes</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let iTrack of topArtistsMap | keyvalue: valueDescOrder">
              <th scope="row"> {{ iTrack.key }} </th>
              <td>{{ iTrack.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <br><br>
  
  <ng-template #customJoyrideStepContent1>
    <i class="fas fa-chart-pie"></i>
    Podrás ver cuántas canciones han sido seleccionadas la semana actual (<span style="color: red;">rojo</span>) en comparación con las pasadas (<span style="color: gray;">grís</span>).
  </ng-template>

  <ng-template #customJoyrideStepContent2>
    <i class="fas fa-chart-pie"></i>
    También te damos una vista de la música más popular en tu local según las elecciones de tus clientes.
  </ng-template>

</div>