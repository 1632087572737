import { Routes } from '@angular/router';
import { FormBaseComponent } from './jukebox/form-base/form-base.component';
import { ThanksComponent } from './jukebox/thanks/thanks.component';
import { SongListComponent } from './jukebox/song-list/song-list.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { PlacePickerComponent } from './place-picker/place-picker.component';



export const jukeboxRoutes: Routes = [
  
  { path: '', component: PlacePickerComponent, pathMatch: 'full' },
  { path: 'jukebox/:jukeboxId', component: SongListComponent },
  { path: 'jukebox/:jukeboxId/play-track/:trackId', component: FormBaseComponent },
  { path: 'jukebox/:jukeboxId/thanks', component: ThanksComponent },
  { path: 'feedback', component: FeedbackComponent }  
  
]