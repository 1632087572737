
import * as fromTracks from '../actions/tracks.actions';
import { Track } from '../../models/track.model';

export interface TracksState {
  tracks: Track[];
}

const estadoInicial: TracksState = {
  tracks: []
};


export function tracksReducer( 
                  state = estadoInicial, 
                  action: fromTracks.acciones ): TracksState {

  switch ( action.type ) {

      case fromTracks.SET_TRACKS:
          return {
              tracks: [
                  ...action.tracks.map( item => {
                      return {
                          ...item
                      };
                  })
              ]
          };

      case fromTracks.UNSET_TRACKS:
          return {
            tracks: []
          };

      default:
          return state;

  }
}