import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  showSidebar: boolean = true;

  uiSubscription: Subscription = new Subscription();

  constructor( private store: Store<AppState> ) { }

  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
        .subscribe( ui => {
          this.showSidebar = ui.showSidebar;
    });

  }


  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
  }

}
