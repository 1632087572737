


import { Action } from '@ngrx/store'
import { JukeboxData } from '../../models/jukebox.model';


export const SET_JUKEBOX_DATA = '[JukeboxData] Set jukebox data';
export const UNSET_JUKEBOX_DATA = '[JukeboxData] Unset jukebox data';
export const UPDATE_JUKEBOX_DATA = '[JukeboxData] Update jukebox data';



export class SetJukeboxDataAction implements Action {
  readonly type = SET_JUKEBOX_DATA;

  constructor( public jukeboxData: JukeboxData ) {}
}

export class UnsetJukeboxDataAction implements Action {
  readonly type = UNSET_JUKEBOX_DATA;
}

export class EditJukeboxDataAction implements Action {
  readonly type = UPDATE_JUKEBOX_DATA;

  constructor( public id: string, public jukeboxData: JukeboxData ) {}
}


export type Actions = SetJukeboxDataAction 
                    | UnsetJukeboxDataAction 
                    | EditJukeboxDataAction;