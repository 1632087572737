
<div class="card" style="min-width: 9rem; max-width: 9rem;"
     (click) = "onTrackSelected()"
     [routerLink]="['/jukebox', this.jukeboxService.jukeboxIdSubscribed, 'play-track', track.id]">

  <div class="song text-center" style="cursor: pointer;">
    
    <img src="{{ track.album.images[0].url }}" class="img-responsive" 
            style="height: 124px; width: 124px;" 
            sizes="124px">

    <p class="card-title">{{ track.name | truncate: 14}}</p>
    <p class="card-text">{{ track.artists[0].name | truncate: 15}}</p>
  </div>
</div>
