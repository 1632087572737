import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { Track } from 'src/app/shared/models/track.model';
import { filter } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import * as firebase from 'firebase/app';
import { NgfireHelperService } from '../../../shared/services/ngfire-helper.service';
import { AppState } from 'src/app/app.reducers';
import { QueuedTrack } from '../../../shared/models/queued-track.model';
import { JukeboxConfig, JukeboxData } from '../../../shared/models/jukebox.model';
import { UIService } from '../../../shared/services/ui.service';

@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.css']
})
export class FormBaseComponent implements OnInit, OnDestroy {
  
  isLoading: boolean;

  jukeboxData: JukeboxData;
  jukeboxConfiguration: JukeboxConfig;

  trackSelected: Track;
  analytics = firebase.analytics();

  uiSubscription: Subscription = new Subscription();
  queuedTracksSubscription: Subscription = new Subscription();
  selectedTrackSubscription: Subscription = new Subscription();
  jukeboxDataAndConfigurationSubscription: Subscription = new Subscription();


  queuedTracks: QueuedTrack[] = [];
  playQueueSize: number = 0;
  tooManyRequestsByUser: boolean = false;


  constructor( private router: Router,
               private alog: NGXLogger,     
               private route: ActivatedRoute,
               private store: Store<AppState>,
               private uiService: UIService,
               public jukeboxService: JukeboxService,
               private ngFireService: NgfireHelperService ) { }


  ngOnInit() {
    
    this.uiSubscription = this.store.select('ui')
      .subscribe( uiState => {
        this.isLoading = uiState.isLoading;
      });

    this.route.params.subscribe(
      (params: Params) => {
        let jukeboxId = params['jukeboxId'];
        // If jukebox is different we subscribe again
        if ( jukeboxId && jukeboxId != this.jukeboxService.jukeboxIdSubscribed ) {
          this.alog.debug('FormBaseComponent > jukeboxId', jukeboxId);
          this.jukeboxService.jukeboxIdSubscribed = jukeboxId;

          this.jukeboxService.subscribeJukeboxData(jukeboxId);
          this.jukeboxService.subscribeJukeboxConfiguration(jukeboxId);
        } 
      }
    );

    this.observeReduxJukeboxDataAndConfiguration();

    this.tooManyRequestsByUser = this.jukeboxService.userHaveRequestedTracksRecently();
  }

  observeReduxJukeboxDataAndConfiguration() {
    
    this.jukeboxDataAndConfigurationSubscription = 
      combineLatest(
        this.store.select('jukeboxData').pipe(filter( (jukeState) => jukeState.jukeboxData != null)),
        this.store.select('jukeboxConfiguration').pipe(filter( (jukeState) => jukeState.jukeboxConfiguration != null)))
        .subscribe( ([jukeboxStateData, jukeboxStateConfiguration]) => {
          // console.log('jukeboxData', jukeboxStateData.jukeboxData);
          // console.log('jukeboxStateConfiguration', jukeboxStateConfiguration.jukeboxConfiguration);
          this.jukeboxData = jukeboxStateData.jukeboxData;
          this.jukeboxConfiguration = jukeboxStateConfiguration.jukeboxConfiguration;
          this.observeSelectedTrackAndShow();
        });
    
  }
  
  observeSelectedTrackAndShow() {
    if (this.jukeboxConfiguration.isSongSearchOpen) {
      this.trackSelected = this.jukeboxService.searchOpenSelectedTrack;
      if (!this.trackSelected) {
        this.router.navigate(['/jukebox', this.jukeboxService.jukeboxIdSubscribed]);
      }

    } else {
      this.route.params.subscribe(
        (params: Params) => {
          let trackId = params['trackId'];
          if ( trackId ) {
            this.subscribeSelectedTrack( this.jukeboxData.playlistsId, trackId );                  
          } 
        }
      );
    }
  }

  subscribeReduxQueuedTracks() {
    this.queuedTracksSubscription = this.store.select('queuedTracks')
          .pipe(
            filter( (queueTracksState) => queueTracksState.queuedTracks != null)
          )
          .subscribe( (queueTracksState) => {
            this.alog.debug('FormBaseComponent > subscribeReduxQueuedTracks:', queueTracksState.queuedTracks);
            this.queuedTracks = queueTracksState.queuedTracks;            
            this.playQueueSize = queueTracksState.queuedTracks.length;
            this.checkTrackIsInQueuedTracks();
      });
  }

  checkTrackIsInQueuedTracks() {
    if (this.trackSelected) {
      for(let iQueuedTrack of this.queuedTracks) {
        if (iQueuedTrack.trackUri == this.trackSelected.uri) {
          this.router.navigate(['/jukebox', this.jukeboxService.jukeboxIdSubscribed]);
          this.uiService.showSuccess('Tu canción ya está en cola. ¡Puedes elegir otra!');
        }  
      }
    }
  }

  /**
   * 
   * @param playlistId 
   * @param trackId 
   */
  subscribeSelectedTrack(playlistId: string, trackId: string) {

    // TODO Move to redux
    this.selectedTrackSubscription = this.ngFireService.subscribeSelectedTrack(playlistId, trackId)
      .subscribe(
        (response: any) => {
          const track: Track = response as Track;
          this.alog.debug('FormBaseComponent > subscribeSelectedTrack track', track);
          if ( !track || !( track.isAvailableToPlay )) {
            //this.router.navigate(['/']);
            this.router.navigate(['/jukebox', this.jukeboxService.jukeboxIdSubscribed]);
            this.uiService.showSuccess('Tu canción ya ha sido seleccionada');            
          } else {
            this.trackSelected = track;
            this.checkTrackIsInQueuedTracks();
            this.analytics.logEvent('view_track', { track_name: track.name, track_artist: track.artists[0].name, source: 'web' });
          }
        },
        (err) => {
          this.alog.error('FormBaseComponent > subscribeSelectedTrack', err);
          this.router.navigate(['/jukebox', this.jukeboxService.jukeboxIdSubscribed]);
        }
      );
  }


  /**
   * 
   */
  subscribeQueuedTracks(partyId: string) {

    this.queuedTracksSubscription = this.jukeboxService.subscribeQueuedTracks(partyId)
      .subscribe(
        (queuedTracks: QueuedTrack[]) => {
          this.alog.debug('form-base > subscribeQueuedTrackList queuedTracks', queuedTracks);
          this.playQueueSize = queuedTracks.length;
          this.queuedTracks = queuedTracks;
          // TODO remove and toast if track is queued
          if (this.trackSelected) {
            for(let iQueuedTrack of this.queuedTracks) {
              if (iQueuedTrack.trackUri == this.trackSelected.uri) {
                this.router.navigate(['/jukebox', this.jukeboxService.jukeboxIdSubscribed]);
                this.uiService.showSuccess('Tu canción ya está en cola');
              }  
            }
          }               
        },
        (err) => {
          this.alog.error('form-base > subscribeQueuedTrackList err', err);
        }
      );
  }
	  
  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.queuedTracksSubscription.unsubscribe();
    this.selectedTrackSubscription.unsubscribe();
    this.jukeboxDataAndConfigurationSubscription.unsubscribe();
  }

}
