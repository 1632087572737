


export class QueuedTrack {
  
    id: string;

    name: string;
    durationMs: number;
    artists: string;
    trackUri: string;
    coverUrlSmall: string; // 64
    //coverUrlMedium: string; // 300
    //coverUrlLarge: string; // 640

    addedQueueBy: string;
    addedQueueDate: string;     // 2016-10-14T18:47:55Z yyyy-MM-dd'T'HH:mm:ssZ
    isQueuedInSpotify: boolean;

    source: string;

    constructor() {}

    
}