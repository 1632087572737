


<div *ngIf="isLoading" class="container text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>


<div *ngIf="!isLoading">  

  <div class="d-flex justify-content-center" style="background-color: lightgray; height: 40px;">
    <h3 class="align-self-center">Visor tiempo real</h3>
  </div>

  <div class="list-group ">
      
    <app-sale-item
      *ngFor="let iSale of sales | saleFilter:'vacio'"
      [partiesId]="jukeboxData.partiesId"
      [sale]="iSale">
    </app-sale-item>

  </div>

</div>
