


<div *ngIf="!isLoading"
     class="row">
  <div class="col-12 col-sm-12 col-md-12"
        style="color: whitesmoke">

      <!-- class="form-inline" -->
      <form #formReference
            [formGroup]="customForm"
            (ngSubmit)="onCustomFormSubmit()">

            <div class="form-horizontal">

              <input *ngIf="dialogConfigurationWeb.name.isActive"
                      type="text" class="form-control" 
                      id="name"
                      formControlName="name"
                      [ngClass]="{ 'is-invalid': customForm.get('name').invalid && customForm.get('name').touched,
                                   'is-valid': customForm.get('name').valid && customForm.get('name').dirty }"
                      [maxlength] = "dialogConfigurationWeb.email.maxLenght"
                      [placeholder] = "dialogConfigurationWeb.name.hintText">

              <input *ngIf="dialogConfigurationWeb.email.isActive"
                      type="text" class="form-control" 
                      id="email"
                      formControlName="email"
                      [ngClass]="{ 'is-invalid': customForm.get('email').invalid && customForm.get('email').touched,
                                   'is-valid': customForm.get('email').valid && customForm.get('email').dirty }"
                      oninput="if(this.value.length >= 4) { this.value = this.value.trim(); }"
                      [maxlength] = "dialogConfigurationWeb.email.maxLenght"            
                      [placeholder] = "dialogConfigurationWeb.email.hintText">

              <input *ngIf="dialogConfigurationWeb.shortText.isActive"
                      type="text" class="form-control" 
                      id="shortText"
                      formControlName="shortText"
                      [ngClass]="{ 'is-invalid': customForm.get('shortText').invalid && customForm.get('shortText').touched,
                                   'is-valid': customForm.get('shortText').valid && customForm.get('shortText').dirty }"
                      oninput="if(this.value.length >= 4) { this.value = this.value.trim(); }"
                      [maxlength] = "dialogConfigurationWeb.shortText.maxLenght"
                      [placeholder] = "dialogConfigurationWeb.shortText.hintText">

              <textarea *ngIf="dialogConfigurationWeb.longText.isActive"
                      type="text" class="form-control" 
                      rows="5"
                      id="longText"
                      formControlName="longText"
                      [ngClass]="{ 'is-invalid': customForm.get('longText').invalid && customForm.get('longText').touched,
                                   'is-valid': customForm.get('longText').valid && customForm.get('longText').dirty }"
                      style="resize: none;"
                      (keyup)="limitTextArea($event)"
                      [maxlength] = "dialogConfigurationWeb.longText.maxLenght"
                      [placeholder] = "dialogConfigurationWeb.longText.hintText">
              </textarea>

              <div *ngIf="dialogConfigurationWeb.promocode.isActive">
                <input type="text" class="form-control" 
                        id="promocode"
                        formControlName="promocode"
                        [ngClass]="{ 'is-invalid': customForm.get('promocode').invalid && customForm.get('promocode').touched,
                                     'is-valid': customForm.get('promocode').valid && customForm.get('promocode').dirty }"
                        minlength="2" 
                        [maxlength] = "dialogConfigurationWeb.promocode.maxLenght"
                        [placeholder] = "dialogConfigurationWeb.promocode.hintText"
                        oninput="if(this.value.length >= 5) { this.value = this.value.slice(0,5); }">

                        <div *ngIf="customForm.get('promocode').invalid && customForm.get('promocode').dirty" 
                             style="color: coral; font-size: small;"
                             class="notification is-danger">
                          No encontramos el código promocional...
                        </div>
                        
                        <div *ngIf="customForm.get('promocode').pending" 
                             style="color: coral; font-size: small;"
                             class="notification is-info">
                          Comprobando...
                        </div>
              </div>

              <div *ngIf="dialogConfigurationWeb.privacyText.isActive"
                   class="text-center"> 
                <small style="color: #653E72; cursor: pointer;" (click)="onOpenPrivacyPolicy(content)">Leer política de privacidad</small>   
              </div>

              <div *ngIf="dialogConfigurationWeb.termsCheckbox.isActive"
                   class="text-center"> 
                <label class="btn btn-default" style="background-color: transparent;">
                  <input type="checkbox" id="sizeXL" name="conditions" formControlName="terms"> 
                  {{ dialogConfigurationWeb.termsCheckbox.hintText }} 
                  <!-- (<a href="http://paviado.com/privacy-policy">Ver</a>) -->
                  <br>   
                </label>    
              </div>

              <div *ngIf="dialogConfigurationWeb.primaryButton.isActive"
                   class="text-center"> 
                <button type="submit" class="btn btn-primary" [disabled]="!customForm.valid">
                  {{dialogConfigurationWeb.primaryButton.hintText}}
                </button>
              </div>
            </div>

            <br>
      </form>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Política de Privacidad</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
    {{ dialogConfigurationWeb.privacyText.content }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
  </div>
</ng-template>
