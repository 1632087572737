


<div *ngIf="isLoading" class="container text-center">
  <br><br>
  <div class="spinner-border" role="status" style="color: purple">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading">

  <div *ngIf="isPiccadilly && this.jukeboxService.jukeboxes.length > 1">
    <div class="piccadilly">
      <br>
      <div class="text-center">
        <img class="img-fluid" src="{{ this.appConfigService.getImgUrlCompanyLogo() }}" style="max-width: 300px;">  
      </div>

      <br>
      <div class="row text-center" style="background-color: black;">  
        <div class="col-12" style="background-color: black; color: blanchedalmond;">
          <h2>ELIGE TU EQUIPO:</h2>
        </div>

        <div *ngFor="let iJukebox of jukeboxService.jukeboxes" class="col-12">

          <div *ngIf="iJukebox.jukebox_configuration.displayName.toLowerCase().includes('verde')">
            <div class="col-12" id="team1">
              <a [routerLink]="['/jukebox', iJukebox.jukebox_data.id]">                
                <span class="col-12" id="equipoVerde">VERDE</span>
              </a>
            </div>
          </div>

          <div *ngIf="!iJukebox.jukebox_configuration.displayName.toLowerCase().includes('verde')">
            <div class="col-12" id="team2">
              <a [routerLink]="['/jukebox', iJukebox.jukebox_data.id]">
                <span class="col-12" id="equipoAzul">AZUL</span>
              </a>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isPiccadilly">
    <div class="piccadilly">
      <br>
      <div class="text-center">
        <img class="img-fluid" src="{{ this.appConfigService.getImgUrlCompanyLogo() }}" style="max-width: 150px;">  
      </div>

      <br>
      <div class="row text-center" style="background-color: black;">  
        <div class="col-12" style="background-color: black; color: blanchedalmond;">
          <h2>¿Donde te encuentras?</h2>
        </div>
      </div>

      <div class="col-12">
        <div class="panel panel-default">
          <!-- <div class="panel-heading">
              <h3 class="panel-title">Panel Default</h3>
          </div> -->
          <ul class="list-group">
            <div *ngFor="let iJukebox of jukeboxService.jukeboxes">
              <!--class="list-group-item active" -->
              <a [routerLink]="['/jukebox', iJukebox.jukebox_data.id]" class="list-group-item">{{ iJukebox.jukebox_configuration.displayName }}</a>
            </div>
          </ul>
        </div>
      </div>
      
    </div>
  </div>
</div>