import { Component, OnInit, Input } from '@angular/core';
import { Track } from 'src/app/shared/models/track.model';

@Component({
  selector: 'app-form-track',
  templateUrl: './form-track.component.html',
  styleUrls: ['./form-track.component.css']
})
export class FormTrackComponent implements OnInit {

  @Input() track: Track;

  constructor() {
  }

  ngOnInit() {    
  }


}
