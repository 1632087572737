
import { Action } from '@ngrx/store'
import { Track } from '../../models/track.model';


export const SET_TRACKS = '[Tracks] Set Tracks';
export const UNSET_TRACKS = '[Tracks] Unset Tracks';


export class SetTracksAction implements Action {
  readonly type = SET_TRACKS;

  constructor( public tracks: Track[] ) { }
}

export class UnsetTracksAction implements Action {
  readonly type = UNSET_TRACKS;
}

export type acciones = SetTracksAction | UnsetTracksAction;