


<div class="container">

  <br>

  <div *ngIf="isLoading" class="container text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="!isLoading">
    
    <app-promo-codes-manager></app-promo-codes-manager>

  </div>  

</div>
