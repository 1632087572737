


import * as fromQueuedTracks from '../actions/queued-tracks.actions';
import { QueuedTrack } from '../../models/queued-track.model';

export interface QueuedTracksState {
  queuedTracks: QueuedTrack[];
}

const initialState: QueuedTracksState = {
  queuedTracks: []
};

export function queuedTracksReducer( 
                  state = initialState, 
                  action: fromQueuedTracks.Actions ): QueuedTracksState {

  switch ( action.type ) {

      case fromQueuedTracks.SET_QUEUED_TRACKS:
          return {
            queuedTracks: [
                  ...action.queuedTracks.map( item => {
                      return {
                          ...item
                      };
                  })
              ]
          };

      case fromQueuedTracks.UNSET_QUEUED_TRACKS:
          return {
            queuedTracks: []
          };

      default:
          return state;

  }
}