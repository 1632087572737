import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { JukeboxService } from '../../../../shared/services/jukebox.service';
import { Sale } from '../../../../shared/models/sale.model';
import { QueuedTrack } from '../../../../shared/models/queued-track.model';
import { Track } from '../../../../shared/models/track.model';
import * as firebase from 'firebase/app';
import { PromoCodeValidator } from '../promo-code-validator';
import { NgfireHelperService } from '../../../../shared/services/ngfire-helper.service';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';

@Component({
  selector: 'app-form-promos',
  templateUrl: './form-promos.component.html',
  styleUrls: ['./form-promos.component.css']
})
export class FormPromosComponent implements OnInit {

  @Input() trackSelected: Track;
  @Input() partyId: string;

  isLoading: boolean;
  promoSongForm: FormGroup;
  analytics = firebase.analytics();

  constructor( private alog: NGXLogger,
               private router: Router,
               private fb: FormBuilder,
               private jukeboxService: JukeboxService,
               private ngFireHelperService: NgfireHelperService ) { }


  ngOnInit() {

    // Form PROMO song
    this.promoSongForm = this.fb.group({
      name:  [''],
      promoCode:  ['', 
                   Validators.required, 
                   PromoCodeValidator.validatePromoCode( this.ngFireHelperService, this.partyId )
                  ],
    });

  }  

  /**
   * 
   */
  onPromoCodeFormSubmit() {

    // Get promoCode field value
    let promoCodeInput = this.promoSongForm.get('promoCode').value;

    this.jukeboxService.getPromoCode( this.partyId, promoCodeInput )
        .then((promoCode) => {
          
          this.alog.debug("FormPromosComponent > onPromoCodeFormSubmit", promoCode);

          // QueuedTrack data
          const queuedTrack: QueuedTrack = new QueuedTrack();
          queuedTrack.isQueuedInSpotify = false;

          let addedQueueBy = this.promoSongForm.get('name').value;
          if (addedQueueBy && addedQueueBy.trim().length > 0) {
            queuedTrack.addedQueueBy = addedQueueBy;
          }    
          queuedTrack.name = this.trackSelected.name;
          queuedTrack.durationMs = this.trackSelected.duration_ms;
          queuedTrack.artists = this.trackSelected.artists[0].name;
          queuedTrack.trackUri = this.trackSelected.uri;
          queuedTrack.coverUrlSmall = this.trackSelected.album.images[0].url;
          queuedTrack.source = "web";
          
          // Post on database song queue to play
          this.jukeboxService.pushQueuedTrack( this.partyId, queuedTrack );

          var now = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');

          // Create Sale class with data
          let sale: Sale = new Sale();
          sale.song = queuedTrack;
          sale.provider = "promo_code";
          sale.source = "web";
          sale.promoCode = promoCode;
          sale.date_transaction = now;

          // Post on database song selected
          this.jukeboxService.pushSale( this.partyId, sale );
          
          // Delete promo code from database
          this.jukeboxService.removePromoCode( this.partyId, promoCode.id );

          if (promoCode.content == 'winer' || promoCode.content == 'winner') {
            alert('Código premiado!! Canjéalo en la barra!');
          }

          this.jukeboxService.setRequestTimeOnLocalStorage(now);

          this.analytics.logEvent('queued_track', { track_name: queuedTrack.name, track_artist: queuedTrack.artists,
                                                    dialog_type: 'promos', input_name: queuedTrack.addedQueueBy, source: 'web' });
          
          // Redirect to thanks
          this.router.navigate(['/jukebox', this.jukeboxService.jukeboxIdSubscribed, 'thanks']);

        })
        .catch((err) => {
          this.alog.error('FormPromosComponent > onPromoCodeFormSubmit', err);
        });
        
  }
  

  ngOnDestroy() {
  }

}
