import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SongItemComponent } from './jukebox/song-list/song-item/song-item.component';
import { SongListComponent } from './jukebox/song-list/song-list.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ThanksComponent } from './jukebox/thanks/thanks.component';
import { FormBaseComponent } from './jukebox/form-base/form-base.component';
import { FormTrackComponent } from './jukebox/form-base/form-track/form-track.component';
import { FormLeadsComponent } from './jukebox/form-base/form-leads/form-leads.component';
import { FormPromosComponent } from './jukebox/form-base/form-promos/form-promos.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainPanelComponent } from './main-panel/main-panel.component';
import { AppRoutingModule } from '../app-routing.module';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FormCustomComponent } from './jukebox/form-base/form-custom/form-custom.component';
import { PlacePickerComponent } from './place-picker/place-picker.component';
import { NgbRatingModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    SongItemComponent,
    SongListComponent,
    FooterComponent,    
    ThanksComponent,
    FormBaseComponent,
    FormTrackComponent,    
    FormLeadsComponent,
    FormPromosComponent,
    MainPanelComponent,
    NavbarComponent,
    SidebarComponent,
    FeedbackComponent,
    FormCustomComponent,
    PlacePickerComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbRatingModule,
    NgbModule,
  ],
  providers: [
  ]
})
export class JukeboxModule { }
