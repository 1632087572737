import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/services/ui.service';
import { Jukebox } from '../../../shared/models/jukebox.model';
import { AuthService } from '../../../shared/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.css']
})
export class PromoCodesComponent implements OnInit, OnDestroy {
  
  jukebox: Jukebox;
  isLoading: boolean;
  userRole: string = '';

  authSubscription: Subscription = new Subscription();
  loadingSubscription: Subscription = new Subscription();


  constructor( private uiService: UIService,
               private store: Store<AppState>,
               public authService: AuthService  ) { }

  ngOnInit() {

    this.loadingSubscription = this.uiService.loadingStateChanged
      .subscribe(
        isLoading => {
          this.isLoading = isLoading;
        }
      );

    this.authSubscription = 
      this.store.select('auth')
        .pipe(
          filter( auth => auth.user != null )
        )
        .subscribe( auth => {
          this.userRole = auth.user.role;
        });

  }


  updateUI() {
  }

  
  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
  }

}
