import { Injectable } from '@angular/core';
import { Observable, EMPTY, from } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';



@Injectable()
export class AuthAnonymousService implements Resolve<any> {
 
  constructor( public authService: AuthService ) { }
  //constructor( ) { }
  //private authService: AuthService
 
  resolve(): Observable<any> {

    if (this.authService.isLoggedInAny) {
      return EMPTY;
    } else {
      return from(this.authService.SignInAnonymously());
    }

  }
 
}