import { Action } from '@ngrx/store';

export const LOADING_CHANGE = '[UI] Loading change...';
export const TOGGLE_SIDEBAR = '[UI] Toggle sidebar...';


export class LoadingChangeAction implements Action {
    readonly type = LOADING_CHANGE;

    constructor( public loading: boolean ) { }
}

export class ToggleSidebarAction implements Action {
  readonly type = TOGGLE_SIDEBAR;
}

export type acciones = ToggleSidebarAction
                      | LoadingChangeAction;


