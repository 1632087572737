import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { JukeboxService } from 'src/app/shared/services/jukebox.service';
import { AppConfigService } from '../../shared/services/app-config.service';

@Component({
  selector: 'app-place-picker',
  templateUrl: './place-picker.component.html',
  styleUrls: ['./place-picker.component.css']
})
export class PlacePickerComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  isPiccadilly: boolean = false;
  
  uiSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private router: Router,
               private store: Store<AppState>,
               public jukeboxService: JukeboxService,
               public appConfigService: AppConfigService ) { }

  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
        .subscribe( ui => {
          this.isLoading = ui.isLoading;
    });

    if ( this.appConfigService.getSubdomain().toLowerCase() == 'piccadilly' ) {  
      this.isPiccadilly = true;
    }

    // If subdomain have only 1 jukebox directly subscribe to it
    const jukeboxesId: string[] = Object.keys(this.appConfigService.getJukeboxesParties());
    if (jukeboxesId.length == 1) {
      this.router.navigate(['jukebox', jukeboxesId[0]]);
    }
  }
  
  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
  }

}
