import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { SpotifyCallbackComponent } from './admin/spotify/spotify-callback/spotify-callback.component';
import { SecureInnerPagesGuard } from './shared/guard/secure-inner-pages.guard';
import { jukeboxRoutes } from './users/jukebox.routes';
import { adminRoutes } from './admin/admin.routes';
import { AngularFireAuthGuard, AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { MainPanelComponent } from './users/main-panel/main-panel.component';
import { SpotifyAuthCodeComponent } from './admin/spotify/spotify-callback/spotify-auth-code/spotify-auth-code.component';
import { AuthAnonymousService } from './users/shared/resolvers/auth-anonymous.resolver';

const routes: Routes = [

  { path: '', 
    component: MainPanelComponent, 
    children: jukeboxRoutes,
    resolve: {
      authAnonymousResolver: AuthAnonymousService
      // TODO Retrieve token from FB. Is is expired ask again Spotify Auth
    },
  },
  
  { path: 'admin/sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'admin/sign-up', component: SignUpComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'admin/forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'admin/verify-email-address', component: VerifyEmailComponent, canActivate: [AuthGuard] },
  
  { path: 'login/spotify/callback', component: SpotifyCallbackComponent, canActivate: [AuthGuard] },  
  { path: 'login/spotify/callback-auth-code', component: SpotifyAuthCodeComponent, canActivate: [AuthGuard] },  

  { path: 'admin', 
    component: DashboardComponent, 
    children: adminRoutes,
    canActivate: [AuthGuard]
  },
  
  { path: '**', redirectTo: '', pathMatch: 'full' },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    AngularFireAuthGuardModule,
  ],
  providers: [
    AuthAnonymousService
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }