import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ToggleSidebarAction } from 'src/app/shared/redux/actions/ui.actions';
import { AppState } from 'src/app/app.reducers';
import { JukeboxService } from '../../../shared/services/jukebox.service';
import { AppConfigService } from '../../../shared/services/app-config.service';
import { NGXLogger } from 'ngx-logger';
import { Router, ActivatedRoute } from '@angular/router';
import { UnsetJukeboxDataAction } from '../../../shared/redux/actions/jukebox-data.actions';
import { UnsetJukeboxConfigurationAction } from '../../../shared/redux/actions/jukebox-configuration.actions';
import { AuthService } from '../../../shared/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  showSidebar: boolean = true;
  
  isJukeboxSubscribed: boolean = false;
  jukeboxActiveDisplayName: string = '';

  uiSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();

  constructor( private alog: NGXLogger,
               private router: Router,
               private route: ActivatedRoute,
               private store: Store<AppState>,
               private authService: AuthService,
               public jukeboxService: JukeboxService,
               private appConfigService: AppConfigService ) { }


  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
        .subscribe( ui => {
          this.showSidebar = ui.showSidebar;
    });

    const jukeboxesId: string[] = Object.keys(this.appConfigService.getJukeboxesParties());
    this.loadJukeboxes(jukeboxesId);

    this.jukeboxConfigurationSubscription =  this.store.select('jukeboxConfiguration')
        .subscribe( (jukeboxState) => {
          if (! jukeboxState.jukeboxConfiguration) {
            this.isJukeboxSubscribed = false;
            this.jukeboxActiveDisplayName = '';
            this.jukeboxService.jukeboxIdSubscribed = '';
            
          } else {
            this.isJukeboxSubscribed = true;
            this.jukeboxActiveDisplayName = jukeboxState.jukeboxConfiguration.displayName;
          }
    });

  }

  /**
   * 
   */
  loadJukeboxes(jukeboxesId: string[]) {
    // Get one by default
    for ( let key of jukeboxesId ) {
      this.jukeboxService.getJukebox(key)
          .then( (jukebox) => {
            this.alog.debug('NavbarComponent > loadJukeboxes jukebox:', jukebox);
            this.jukeboxService.jukeboxes.push(jukebox);
          })
          .catch( (err) => {
            this.alog.error('NavbarComponent > loadJukeboxes error:', err);
          });
    }
  }
  
  onClickJukeboxChange() {
    this.store.dispatch( new UnsetJukeboxDataAction() );
    this.store.dispatch( new UnsetJukeboxConfigurationAction() );
    this.jukeboxService.playlistTracks = [];
    this.router.navigate(['']);
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
    this.store.dispatch( new ToggleSidebarAction() );
  }

  ngOnDestroy() {    
    this.uiSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
    this.jukeboxService.cancellSubscriptions();
  }

}
