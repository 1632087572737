import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Sale } from 'src/app/shared/models/sale.model';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { filter } from 'rxjs/operators';
import { Label, Color } from 'ng2-charts';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as moment from 'moment';
import { KeyValue } from '@angular/common';
import { LoadingChangeAction } from '../../shared/redux/actions/ui.actions';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit, OnDestroy {

  isLoading: boolean = true;
  isProcessingData: boolean = true;
  sales: Sale[] = [];
  now = moment().locale('es');

  // GENDER: Hombres vs Mujeres
  leadsCount: number = 0;
  leadsYearSum: number = 0;
  leadsManYearSum: number = 0;
  leadsWomanYearSum: number = 0;
  leadsOtherYearSum: number = 0;

  leadsManCount: number = 0;
  leadsWomanCount: number = 0;
  leadsOtherCount: number = 0;

  leadsAgeAverage: number = 0;
  leadsAgeManAverage: number = 0;
  leadsAgeWomanAverage: number = 0;
  leadsAgeOtherAverage: number = 0;

  promoCount: number = 0;

  customCount: number = 0;

  // TOP TRACKS
  limitToTop: number = 5;
  topArtistsMap = new Map();

  valueDescOrder = (a: KeyValue<string, number>, b: KeyValue<string, number>): number => {
    return a.value > b.value ? -1 : (b.value > a.value ? 1 : 0);
  }

  // SONG REQUESTS
  week0: number[] = [0, 0, 0, 0, 0, 0, 0];
  week1: number[] = [0, 0, 0, 0, 0, 0, 0];
  week2: number[] = [0, 0, 0, 0, 0, 0, 0];
  week3: number[] = [0, 0, 0, 0, 0, 0, 0];

  uiSubscription: Subscription = new Subscription();
  jukeboxDataSubscription: Subscription = new Subscription();

  
  // Numero peticiones
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
    
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';


  // Tarta GENERO
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = [['Mujeres'], ['Hombres'], ['Otro']];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [ {
      backgroundColor: ['rgba(255,0,0,0.6)', 'rgba(0,0,255,0.6)', 'rgba(144,144,144,0.6)'],
    },];
  


  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private adminService: AdminService ) { }

  ngOnInit() {

    this.store.dispatch( new LoadingChangeAction(true) );

    this.uiSubscription = this.store.select('ui')
        .subscribe( uiState => {
          this.isLoading = uiState.isLoading;          
        });

    this.jukeboxDataSubscription = this.store.select('jukeboxData')
        .pipe(
          filter( jukeboxState => jukeboxState.jukeboxData != null)
        )
        .subscribe( jukeboxState => {
          this.resetValues();
          this.getSales(jukeboxState.jukeboxData.partiesId);
        });

  }

  resetValues() {
    this.isProcessingData = true;

    this.leadsCount = 0;
    this.leadsYearSum = 0;
    this.leadsManYearSum = 0;
    this.leadsWomanYearSum = 0;
    this.leadsOtherYearSum = 0;

    this.leadsManCount = 0;
    this.leadsWomanCount = 0;
    this.leadsOtherCount = 0;

    this.leadsAgeAverage = 0;
    this.leadsAgeManAverage = 0;
    this.leadsAgeWomanAverage = 0;
    this.leadsAgeOtherAverage = 0;

    this.promoCount = 0;
    this.topArtistsMap.clear();

    // SONG REQUESTS
    this.week0 = [0, 0, 0, 0, 0, 0, 0];
    this.week1 = [0, 0, 0, 0, 0, 0, 0];
    this.week2 = [0, 0, 0, 0, 0, 0, 0];
    this.week3 = [0, 0, 0, 0, 0, 0, 0];

    this.pieChartData = [];
    this.lineChartData = [];
  }


  private getSales(partiesId: string) {

    let date = moment().subtract('week', 5);

    this.adminService
        .getSales( partiesId, date.toISOString() )        
        .then( (sales: Sale[]) => {
          this.alog.debug('statistics.getSales sales:', sales);
          this.sales = sales;
          this.transformSales( sales );
        })
        .catch( (err) => {
          this.alog.error('jstatistics.getSales err', err);
        })
        .finally( () => {
        });
  }


  transformSales(sales: Sale[]) {

    sales.forEach( item => {

      if ( item.provider === 'lead' ) {
        this.countLeadRequests(item);  

      } else if ( item.provider === 'promo_code' ) {
        this.promoCount ++;

      } else if ( item.provider === 'custom' ) {
        this.countCustomRequests(item);
      }
      
      // Get most request tracks
      this.countTopTracksRequests(item);

      // Set graph requests by day
      let dateMoment = moment.parseZone(item.date_transaction);
      dateMoment.locale('es');

      if ( dateMoment.hours() >= 0 && dateMoment.hours() < 6 ) {  
        dateMoment.subtract('days', 1);
      }

      if ( dateMoment.week() == this.now.week() ) {
        this.week0[dateMoment.weekday()] = this.week0[dateMoment.weekday()] + 1;

      } else if ( dateMoment.week() == this.now.week() -1 ) {
        this.week1[dateMoment.weekday()] = this.week1[dateMoment.weekday()] + 1;

      } else if ( dateMoment.week() == this.now.week() -2 ) {
        this.week2[dateMoment.weekday()] = this.week2[dateMoment.weekday()] + 1;

      } else if ( dateMoment.week() == this.now.week() -3 ) {
        this.week3[dateMoment.weekday()] = this.week3[dateMoment.weekday()] + 1;
      }  
    });

    // Order Most requested artists
    this.topArtistsMap = new Map([...this.topArtistsMap.entries()].sort((a, b) => b[1] - a[1]));
    let count: number = 0;
    this.topArtistsMap.forEach( (value, key, map) => {
        if (count >= this.limitToTop) {
          this.topArtistsMap.delete(key);
        }
        count++;   
    });

    this.pieChartData =  [ this.leadsWomanCount, this.leadsManCount, this.leadsOtherCount ];

    this.lineChartData = [
      { data: this.week0, label: 'Semana Actual' },
      { data: this.week1, label: 'Semana -1' },
      { data: this.week2, label: 'Semana -2' },
      { data: this.week3, label: 'Semana -3' },
    ];

    let date = new Date();
    let year = date.getFullYear();
    
    this.leadsAgeAverage = year - (this.leadsYearSum / this.leadsCount);
    this.leadsAgeManAverage = year - (this.leadsManYearSum / this.leadsManCount);
    this.leadsAgeWomanAverage = year - (this.leadsWomanYearSum / this.leadsWomanCount);
    this.leadsAgeOtherAverage = year - (this.leadsOtherYearSum / this.leadsOtherCount);

    this.isProcessingData = false;

  }


  countTopTracksRequests(item: Sale) {
    if ( item.song ) {        
      if ( this.topArtistsMap.get(item.song.artists) ) {
        this.topArtistsMap.set(item.song.artists, this.topArtistsMap.get(item.song.artists) + 1);

      } else {
        this.topArtistsMap.set(item.song.artists, 1);      
      }
    }
  }


  countLeadRequests(item: Sale) {
    this.leadsCount ++;
    this.leadsYearSum += parseInt( item.lead.year, 10 );

    if ( item.lead.gender === 'Hombre' ) {
      this.leadsManCount ++;
      this.leadsManYearSum += parseInt( item.lead.year, 10 );

    } else if  ( item.lead.gender === 'Mujer' ) {
      this.leadsWomanCount ++;
      this.leadsWomanYearSum += parseInt( item.lead.year, 10 );

    } else if  ( item.lead.gender === 'Otro' ) {
      this.leadsOtherCount ++;
      this.leadsOtherYearSum += parseInt( item.lead.year, 10 );
    }
  }


  countCustomRequests(item: Sale) {
    this.customCount ++;    
  }

  ngOnDestroy() {
    this.uiSubscription.unsubscribe();
    this.jukeboxDataSubscription.unsubscribe();
  }


}
