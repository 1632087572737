import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  siteKey = environment.googleRecaptchaV3;
  signUpForm: FormGroup;
  constructor( public authService: AuthService ) { }

  ngOnInit() {
    this.signUpForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required]),
      'subdomain': new FormControl(null, [Validators.required]),
      // 'recaptcha': new FormControl(null, [Validators.required]),
    });
  }

  onSignUpFormSubmit(captchaResponse: string) {
    //console.log('submit captchaResponse:', captchaResponse);

    if (this.signUpForm.valid) {

      let email = this.signUpForm.get('email').value;
      let password = this.signUpForm.get('password').value;
      let subdomain = this.signUpForm.get('subdomain').value;

      this.authService.SignUp(email, password, subdomain);
    }
    
  }

  onClickCompanyFieldInfo(content) {
    //this.modalService.open(content, { size: 'xl' });
  }

}
