import { Image } from './image.model';
import { Artist } from './artist.model';

export class Track {
  album: Album;
  artists: Artist[];
  //available_markets: string[];
  disc_number: number;
  duration_ms: number;
  episode: boolean;
  explicit: boolean;
  //external_ids: any;
  //external_urls: any;
  href: string;
  id: string;
  is_local: boolean;
  name: string;
  popularity: number;
  preview_url: string;
  track: boolean;
  track_number: number;
  type: string;
  uri: string;

  //isAvaliableToPlay: boolean; // WARN! Not spotify field
  //isAvailiableToPlay: boolean; // WARN! Not spotify field
  isAvailableToPlay: boolean; // WARN! Not spotify field
}

class VideoThumbnail {
  url: string;
}

class Album {
  album_type: string;
  artists: any[];
  //available_markets: string[];
  external_urls: any;
  href: string;
  id: string;
  images: Image[];
  name: string;
  release_date: Date;
  release_date_precision: string;
  total_tracks: number;
  type: string;
  uri: string;
}
