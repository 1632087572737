


<!-- Side Navbar -->
<nav class="side-navbar " [ngClass]="{ 'show-sm': showSidebar }">
  <div class="side-navbar-wrapper">
    <!-- Sidebar Header    -->
    <div class="sidenav-header d-flex align-items-center justify-content-center">
      <!-- User Info-->
      <div class="sidenav-header-inner text-center">
        <img src="../../../../assets/images/ic_launcher.png" alt="person" class="img-fluid rounded-circle">
        <h2 class="h5"> </h2><span> </span>
      </div>
      <!-- Small Brand information, appears on minimized sidebar-->
      <div class="sidenav-header-logo">
        <a routerLink="/" class="brand-small text-center"> 
          <strong class="text-primary">P</strong>
        </a>
      </div>
    </div>
    <!-- Sidebar Navigation Menus -->
    <div class="main-menu">
      <h5 class="sidenav-heading">MENU</h5>
      <ul id="side-main-menu" class="side-menu list-unstyled">                  
        <!-- <li><a routerLink="/home"> <i class="fas fa-home"></i>Inicio</a></li> -->
        <li><a routerLink="/"> <i class="fas fa-music"></i>Música</a></li>
        <li><a routerLink="/feedback"> <i class="fas fa-comments"></i>Sugerencias</a></li>
      </ul>
    </div>
  </div>
</nav>