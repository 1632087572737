<div class="card bg-dark" style="min-width: 10rem; max-width: 10rem;"
    [ngClass]="{ 'border-warn': trackSelected }"
    (click) = "onTrackSelected()">

  <div class="song text-center" style="cursor: pointer;">
    <!--img-thumbnail-->
    <img src="{{ track.album.images[0].url }}" class="img-responsive" 
            style="height: 124px; width: 124px;" 
            sizes="124px">

    <p class="card-title"><b>{{ track.name | truncate: 14}}</b></p>
    <p class="card-text">{{ track.artists[0].name | truncate: 15}}</p>
  </div>
</div>