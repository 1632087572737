import { Component, OnInit } from '@angular/core';
import { SpotifyService } from 'src/app/shared/services/spotify.service';
import { Playlist } from 'src/app/shared/models/playlist.model';
import { SpotifySong } from 'src/app/shared/models/spotify-song.model';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/services/ui.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Jukebox, JukeboxData } from '../../../shared/models/jukebox.model';
import { AppState } from '../../../app.reducers';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.css']
})
export class PlaylistsComponent implements OnInit {

  jukeboxData: JukeboxData;

  loadedPlaylist: string;
  songs: SpotifySong[] = [];
  playlists: Playlist[] = [];

  isLoading: boolean = false;
  
  playlistConfigForm: FormGroup;

  jukeboxDataSubscription: Subscription = new Subscription();
  loadingSubscription: Subscription = new Subscription();
  getPlaylistSubscription: Subscription = new Subscription();
  authServiceSubscription: Subscription = new Subscription();


  constructor( private alog: NGXLogger,
               private router: Router,
               private store: Store<AppState>,
               private uiService: UIService,
               private adminService: AdminService,
               private spotifyService: SpotifyService ) { }


  ngOnInit() {
    
    this.loadingSubscription = this.uiService.loadingStateChanged
      .subscribe(
        isLoading => {
          this.isLoading = isLoading;
        }
      );

    this.playlistConfigForm = new FormGroup({
      'playlist': new FormControl(null, [Validators.required]),
    });

    this.jukeboxDataSubscription = this.store.select('jukeboxData')
        .subscribe( jukeboxState => {
          this.jukeboxData = jukeboxState.jukeboxData;
          this.getUsersPlaylists();
        });
  }

  /**
   * 
   */
  private getUsersPlaylists() {
    this.spotifyService.getPlaylists()
      .subscribe(
        (playlists: any) => {
            this.alog.debug('playlists/getUsersPlaylists playlists:', playlists);
            this.playlists = playlists.items;
        }
      );
  }


  /*********************
   *** USER ACTIONS ****
   *********************/

  savePlaylist(playlist: Playlist) {
    const limit = 100;
    const offset = 0;
    this.songs = [];

    this.getAllPlaylistSongs(playlist, limit, offset, () => {
      // map object keys
      const songsObject = {};
      let countOk: number = 0;
      let countKo: number = 0;

      this.songs.forEach( iSpotifyTrack => {
        //let paviadoTrack: Track;
        //paviadoTrack.id = iSpotifyTrack.track.id;
        try {
          if (iSpotifyTrack.track.name
              && iSpotifyTrack.track.album
              && iSpotifyTrack.track.album.images
              && iSpotifyTrack.track.album.images[0].url
              && iSpotifyTrack.track.artists
              && iSpotifyTrack.track.artists[0].name) {

              countOk++;
              //iSpotifyTrack.track.isAvaliableToPlay = true;
              //iSpotifyTrack.track.isAvailiableToPlay = true;
              iSpotifyTrack.track.isAvailableToPlay = true;
              songsObject[iSpotifyTrack.track.id] = iSpotifyTrack.track;

          } else {
            this.alog.info('playlists/getAllPlaylistSongs playlists:', iSpotifyTrack.track);
          }

        } catch (exception) {
          // do something else
          countKo++;
          this.alog.info('playlists/getAllPlaylistSongs playlists:', iSpotifyTrack.track, exception);
        }
        
      });

      playlist.updateDate = moment().locale('es').format('YYYY-MM-DDTHH:mm:ssZ');

      const payload = {
        ...playlist,
        songs: songsObject   
      };

      // this.alog.info('playlists/getAllPlaylistSongs countOk:', countOk);
      // this.alog.info('playlists/getAllPlaylistSongs countKo:', countKo);
      // this.alog.info('playlists/getAllPlaylistSongs this.songs.length:', this.songs.length);
      this.alog.info('playlists/getAllPlaylistSongs Playlist:', playlist);

      //playlist.songs = songsObject;
      this.adminService.updatePlaylist(this.jukeboxData.playlistsId, payload)
        .then((_) => {
          this.uiService.showSuccess('Se ha actualizado la Playlist');
        })
        .catch( (err) => {          
          this.alog.debug('playlistsComponent > updateJukeboxConfig err2', err);
          this.uiService.showError('Se ha producido un error al volcar listado. Vuelve a intentarlo mas tarde');
        })
        .finally( () => {
          this.uiService.loadingStateChanged.next(false);
        });

    });

  }

  private getAllPlaylistSongs(playlist: Playlist, limit: number, offset: number, callback: Function) {
    this.spotifyService.getPlaylistSongs(playlist.id, limit, offset)
      .subscribe((response: any) => {
        
        this.songs = [...this.songs, ...response.items];

        //this.songs.length < response.total
        this.songs.length < playlist.tracks.total
            ? this.getAllPlaylistSongs(playlist, limit, offset + limit, callback)
            : callback();
      });
  }


  onPlaylistConfigFormSubmit() {
    
    if (this.playlistConfigForm.valid) {

      this.isLoading = true;
      const playlist = this.playlistConfigForm.get('playlist').value;
      this.savePlaylist(playlist);
    }
  }


  /*********************
   *** LIFECYCLE   ****
   *********************/

  ngOnDestroy() {
    this.jukeboxDataSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
    this.getPlaylistSubscription.unsubscribe();
    this.authServiceSubscription.unsubscribe();
  }
  
}
