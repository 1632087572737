


<br><br><br><br>

  <div class="container h-100">
    <div class="d-flex justify-content-center h-100">
      <div class="user_card">
        <div class="d-flex justify-content-center">
          <div class="brand_logo_container">
            <img src="../../assets/images/ic_launcher.png" class="brand_logo" alt="Logo">
          </div>
        </div>
        <div class="d-flex justify-content-center form_container">
          <form [formGroup]="signInForm"
                (ngSubmit)="onSignInFormSubmit()">

            <div class="input-group mb-3">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-user"></i></span>
              </div>
              <input type="text" name="username" class="form-control input_user" value="" placeholder="username" formControlName="username" #userName required>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fas fa-key"></i></span>
              </div>
              <input type="password" name="password" class="form-control input_pass" value="" placeholder="password" formControlName="password" #userPassword required>
            </div>
            
          <div class="d-flex justify-content-center mt-3 login_container">
            <button type="submit" name="button" class="btn login_btn"
                    [disabled]="!signInForm.valid || !signInForm.dirty">
                    Entrar
            </button>
          </div>
          </form>
        </div>
        
        <br>
        <div class="d-flex justify-content-center links">
          <a href="/admin/sign-up" style="color: aliceblue">¿No tienes cuenta?</a>
        </div>
          
        <div class="d-flex justify-content-center links">
          <a routerLink="/admin/forgot-password" style="color: aliceblue">¿Olvidaste tu contraseña?</a>
        </div>
      </div>
    </div>
  </div>