<!-- Side Navbar -->
<nav class="side-navbar " [ngClass]="{ 'show-sm': showSidebar }">
  <div class="side-navbar-wrapper">
    <!-- Sidebar Header    -->
    <div class="sidenav-header d-flex align-items-center justify-content-center">
      <!-- User Info-->
      <div class="sidenav-header-inner text-center">
        <img src="../../../../assets/images/ic_launcher.png" alt="person" class="img-fluid rounded-circle">
        <h2 class="h5">{{ displayName }}</h2><span>Propietario</span>
      </div>
      <!-- Small Brand information, appears on minimized sidebar-->
      <div class="sidenav-header-logo">
        <a routerLink="/admin" class="brand-small text-center"> 
          <strong class="text-primary">P</strong>
        </a>
      </div>
    </div>
    
    <!-- Sidebar Navigation Menus -->
    <div class="main-menu">
      <h5 class="sidenav-heading">MENU</h5>
      <ul id="side-main-menu" class="side-menu list-unstyled">                  
        <li><a routerLink="/admin"> <i class="fas fa-home"></i>Inicio</a></li>
        <li *ngIf="jukeboxConfiguration && !roleAccessRestrictions.includes('statistics')"
            routerLinkActive="active"
            joyrideStep="step-4" title="Uso" text="Aquí encontrarás un resumen de la actividad de los usuarios">
          <a routerLink="/admin/statistics"> <i class="fas fa-chart-pie"></i>Uso</a>
        </li>
        <li *ngIf="jukeboxConfiguration && !roleAccessRestrictions.includes('jukebox-music')"
            routerLinkActive="active"
            joyrideStep="step-7" title="Música" text="Aquí puedes gestionar la música contenida en el Paviado seleccionado">
          <a routerLink="/admin/jukebox-music"> 
            <i class="fas fa-music"></i>Música
          </a>
        </li>
        <li *ngIf="jukeboxConfiguration && !roleAccessRestrictions.includes('promo-codes')"
            routerLinkActive="active"
            joyrideStep="step-11" title="Sorteos" text="Aquí podrás gestionar los códigos promocionales">
          <a routerLink="/admin/promo-codes"> <i class="fas fa-poll-h"></i>Sorteos</a>
        </li>
        <li *ngIf="jukeboxConfiguration && user.role == 'paviado'"
            routerLinkActive="active">
          <a routerLink="/admin/mail-campaign"> <i class="fas fa-mail-bulk"></i>Mails</a>
        </li>
        <li *ngIf="jukeboxConfiguration && !roleAccessRestrictions.includes('promo-images')"
            routerLinkActive="active"
            joyrideStep="step-14" title="Imagen" text="Aquí puedes personalizar las imágenes mostradas.">
          <a routerLink="/admin/promo-images"> <i class="fas fa-images"></i>Imágenes</a>
        </li>
        <li *ngIf="jukeboxConfiguration && !roleAccessRestrictions.includes('jukebox-config')"
            routerLinkActive="active"
            joyrideStep="step-16" title="Ajustes" text="Aquí puedes cambiar la configuración como el nombre, tu usuario instagram, tamaño de la cola de espera..."
            stepDefaultPosition="right">
          <a routerLink="/admin/jukebox-config"> <i class="fas fa-cogs"></i>Ajustes</a>
        </li>
        <li *ngIf="jukeboxConfiguration && !roleAccessRestrictions.includes('monitor')"
            routerLinkActive="active"
            joyrideStep="step-18" title="Monitor" text="Aquí se puede ver la actividad de las últimas seis horas.">
          <a routerLink="/admin/monitor"> <i class="fas fa-stream"></i>Monitor</a>
        </li>
        <li *ngIf="jukeboxConfiguration">
          <a (click)="onClickTutorial()"> <i class="fas fa-question-circle"></i>Tutorial</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
