import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, tap, filter } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { UIService } from './ui.service';
import { NGXLogger } from 'ngx-logger';
import { Track } from '../models/track.model';

@Injectable({
  providedIn: 'root'
})
export class SpotifyService {

  private artirstUrl: string = 'https://api.spotify.com/v1/search?q='; // ?q=abba&&type=track&market=US

  private accessToken: any;
  private tokenType: string;
  private expiration_time: string;

  constructor( private router: Router,
               private alog: NGXLogger,
               private http: HttpClient,
               private uiService: UIService ) { }

  authorize() { 
      const client_id = environment.spotifyClientId;
      const response_type = 'token';
      const state = Math.random().toString(36).substring(7);
      const redirect_uri = encodeURIComponent(environment.spotifyRedirectUri);
      const scope = encodeURIComponent('playlist-read-private playlist-read-collaborative');

      const url = 'https://accounts.spotify.com/authorize?'
                          + `response_type=${response_type}&`
                          + `client_id=${client_id}&`
                          + `redirect_uri=${redirect_uri}&`
                          + `state=${state}&`
                          + `scope=${scope}`;

      window.location.href = url;
  }

  isAuthenticated(): boolean {    
    const now = new Date();
    const expiration_time = new Date(localStorage.getItem('expiration_time'));
    return now <= expiration_time;
  }

  isAuthenticatedAnonymous(): boolean {    
    const now = new Date();
    const expiration_time = new Date(localStorage.getItem('spotify_expiration_time'));
    return now <= expiration_time;
  }

  login() {
    this.alog.debug('spotify.service > login');
    let authorizationTokenUrl = `https://accounts.spotify.com/api/token`;
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa(environment.spotifyClientId + ':' + environment.spotifyClientSecret),
        'Content-Type': 'application/x-www-form-urlencoded;'
      })
    };

    let body = 'grant_type=client_credentials';

    return this.http.post(authorizationTokenUrl, body, httpOptions)
      .pipe(catchError(this.handleUserError))
      .subscribe( 
        (response: any) => { 
          this.alog.debug('spotify.service > response:', response);
          this.accessToken = response.access_token;
          this.tokenType = response.token_type;

          const expiration_time = new Date();
          this.expiration_time = expiration_time.setSeconds(expiration_time.getSeconds() + +response.expires_in).toString();
          
          localStorage.setItem('spotify_access_token', response.access_token);
          localStorage.setItem('spotify_expires_in', response.token_type);
          localStorage.setItem('spotify_expiration_time', expiration_time.toString());
        } 
      );
      
  }

  private getOptions() {
    const accessToken = localStorage.getItem('spotify_access_token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + accessToken
      })
    };
    
    return httpOptions;
  }

  getProfile() {
    const token = localStorage.getItem('spotify_token');
    const httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' + token
        })
    };

    return this.http.get('https://api.spotify.com/v1/me', httpOptions)
                    .pipe(catchError(this.handleError));
  }

  getPlaylists() {
      const token = localStorage.getItem('spotify_token');
      const userId = localStorage.getItem('user_id');
      const httpOptions = {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http.get(`https://api.spotify.com/v1/users/${userId}/playlists?limit=50`, httpOptions)
                      .pipe(catchError(this.handleError));
  }

  getPlaylistSongs(playlistId, limit = 100, offset = 0) {
      this.uiService.loadingStateChanged.next(true);
      const fields = "total,items(track(id,name,popularity,uri,duration_ms,album(name,images),artists))";
      const token = localStorage.getItem('spotify_token');
      const httpOptions = {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + token
          })
      };
      return this.http.get(`https://api.spotify.com/v1/playlists/${playlistId}/tracks?limit=${limit}&offset=${offset}&fields=${fields}`, httpOptions)
                 .pipe(catchError(this.handleError));
  }

  private handleError = (err: ErrorEvent) => {

      const status = err.error.error.status;
      console.log('handleError status', status);

      // if unauthorized redirect to the auth page
      if (status == 401) {
          this.router.navigateByUrl('/admin');
      }

      return throwError(err);
  }

  public handleUserError = (err: ErrorEvent) => {

      const status = err.error.error.status;
      console.log('handleError status', status);

      // Redirect to the auth page
      if (status == 401) {
          this.router.navigateByUrl('/');
      }

      return throwError(err);
  }

  searchTracks(track: string) {

    const options = this.getOptions();
    return this.http.get(`https://api.spotify.com/v1/search?query=${track}&type=track`, options)
      .pipe(
        map( data => data['tracks'])        
      );
  }
}
