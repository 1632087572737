import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { finalize, filter } from 'rxjs/operators';
import { AdminService } from 'src/app/shared/services/admin.service';
import { Jukebox, AdImage } from 'src/app/shared/models/jukebox.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducers';
import { User } from 'src/app/shared/models/user.model';
import { JukeboxConfig } from '../../../shared/models/jukebox.model';

@Component({
  selector: 'app-promo-images',
  templateUrl: './promo-images.component.html',
  styleUrls: ['./promo-images.component.css']
})
export class PromoImagesComponent implements OnInit, OnDestroy {

  public readonly MAX_POPUP_IMAGES: number = 10;
  private readonly MS_IN_ONE_SECOND: number = 1000;
  private readonly MS_IN_ONE_MINUTE: number = 60000;

  private readonly URL_IMAGE_BK: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/assets%2Fbk_dialog_placeholder.png?alt=media&token=a6a4a911-219d-4a9e-8549-ab6f9c8fdae9';
  private readonly URL_IMAGE_ADS_MODE_BK: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/assets%2Fbk_dialog_placeholder.png?alt=media&token=a6a4a911-219d-4a9e-8549-ab6f9c8fdae9';
  private readonly URL_IMAGE_POPUP: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/assets%2Fbk_dialog_placeholder.png?alt=media&token=a6a4a911-219d-4a9e-8549-ab6f9c8fdae9';
  private readonly URL_IMAGE_BANNER: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/uploads%2Fbk_error_loading_bk.png?alt=media&token=beae432a-af05-4cf7-9659-46be6e561e09';
  private readonly URL_IMAGE_HEADER: string = 'https://firebasestorage.googleapis.com/v0/b/test-paviado.appspot.com/o/uploads%2Fbk_error_loading_bk.png?alt=media&token=beae432a-af05-4cf7-9659-46be6e561e09';


  user: User;
  jukeboxConfiguration: JukeboxConfig;

  isLoading = false;
  uiSubscription: Subscription = new Subscription();
  authSubscription: Subscription = new Subscription();
  jukeboxConfigurationSubscription: Subscription = new Subscription();

  downloadURL: Observable<string>;
  uploadProgress: Observable<number>;

  urlImageBk: string;
  urlImagePopup: string;
  urlImgAdsModeBk: string;
  urlImgBanner: string;
  urlImgHeader: string;
  timeElapsedPromoPopupSeconds: number = 0;
  jukeboxPopupImages: AdImage[] = [];


  constructor( private alog: NGXLogger,
               private store: Store<AppState>,
               private adminService: AdminService,
               private afStorage: AngularFireStorage ) { }


  ngOnInit() {

    this.uiSubscription = this.store.select('ui')
      .subscribe( uiState => {
        this.isLoading = uiState.isLoading;          
    });

    this.authSubscription =  this.store.select('auth')
      .pipe(
        filter( auth => auth.user != null)
      )
      .subscribe( (auth) => {
        this.user = auth.user;
    });

    this.jukeboxConfigurationSubscription = this.store.select('jukeboxConfiguration')
      .pipe(
        filter( jukeboxState => jukeboxState.jukeboxConfiguration != null)
      )
      .subscribe( (jukeState) => {
        this.jukeboxConfiguration = jukeState.jukeboxConfiguration;
        if (this.jukeboxConfiguration.imgs_url && this.jukeboxConfiguration.imgs_url.popups) {

          this.jukeboxPopupImages = Object.values(this.jukeboxConfiguration.imgs_url.popups);
          //console.log(this.jukeboxPopupImages);
        }
        this.updateUI();
    });
    
  }


  updateUI() {
    
    if (this.jukeboxConfiguration.imgUrlDialogFormBk) {
      this.urlImageBk = this.jukeboxConfiguration.imgUrlDialogFormBk;
    } else {
      this.urlImageBk = this.URL_IMAGE_BK;
    }
    
    if (this.jukeboxConfiguration.ads_mode.imgUrlDialogFormBk) {
      this.urlImgAdsModeBk = this.jukeboxConfiguration.ads_mode.imgUrlDialogFormBk;
    } else {
      this.urlImgAdsModeBk = this.URL_IMAGE_ADS_MODE_BK;
    }

    if (this.jukeboxConfiguration.imgUrlPopupInactivityBk) {
      this.urlImagePopup = this.jukeboxConfiguration.imgUrlPopupInactivityBk;
    } else {
      this.urlImagePopup = this.URL_IMAGE_POPUP;
    }

    if (this.jukeboxConfiguration.imgUrlBanner) {
      this.urlImgBanner = this.jukeboxConfiguration.imgUrlBanner;
    } else {
      this.urlImgBanner = this.URL_IMAGE_BANNER;
    }

    if (this.jukeboxConfiguration.imgUrlPlaylistHeader) {
      this.urlImgHeader = this.jukeboxConfiguration.imgUrlPlaylistHeader;
    } else {
      this.urlImgHeader = this.URL_IMAGE_HEADER;
    }

    if (this.jukeboxConfiguration.timeElapsedPromoPopup) {
      this.timeElapsedPromoPopupSeconds = this.jukeboxConfiguration.timeElapsedPromoPopup / this.MS_IN_ONE_SECOND;
    }
  }


  /**
   * Upload image 
   * When upload image with same name overwrite last version on Firebase Storage
   * Jukeboxes can only have 4 group of images asociated
   * Image file name is formed by: 
   * img_banner, img_header, img_popup, img_dialog + '' + this.jukeboxConfiguration.id;
   * 
   * File path is created by UserId. Since a user can have multiple images,
   * we concatenate the jukeboxId in the file name so is unique.
   * 
   */
  upload(event, image_section: string) {
    this.alog.debug('upload image_section: ', image_section);
    
    this.isLoading = true;

    let path: string;
    let file: string = event.target.files[0];
    let fileName: string = '';
    let adImage: AdImage = new AdImage();

    this.alog.debug('upload file: ', file);

    if (image_section === 'img_popup') {
      // Image with same name will be overwrite. We generate unique image id with random (0 - 99999)
      let random = [...Array(5)].map(i=>(~~(Math.random()*10)).toString(10)).join('');
      fileName = image_section + '_' + random + '_' + this.jukeboxConfiguration.id;
      path = '/' + this.user.uid + '/popups/' + fileName;
      this.alog.debug('upload fileName & path', fileName, path);

      adImage.isActive = true;
      adImage.storagePath = path;

    } else {
      fileName = image_section + '_' + this.jukeboxConfiguration.id;
      path = '/' + this.user.uid + '/' + fileName;
    }
    
    const ref = this.afStorage.ref(path);    
    const task = ref.put(file);
    this.uploadProgress = task.percentageChanges();

    task.snapshotChanges().pipe(
      finalize( () => {
        this.downloadURL = ref.getDownloadURL()
        this.downloadURL.subscribe(
          url => {
            this.alog.debug('upload url', url);
            if (image_section.startsWith('img_popup')) {
              adImage.url = url;
              this.adminService.pushImagePopup(this.jukeboxConfiguration, adImage);
            } else if ('img_dialog' === image_section) {
              this.jukeboxConfiguration.imgUrlDialogFormBk = url;
              this.adminService.updateJukeboxConfig(this.jukeboxConfiguration);
            } else if ('img_dialog_ad' === image_section) {
              this.jukeboxConfiguration.ads_mode.imgUrlDialogFormBk = url;
              this.adminService.updateJukeboxConfig(this.jukeboxConfiguration);
            } else if ('img_banner' === image_section) {
              this.jukeboxConfiguration.imgUrlBanner = url;
              this.adminService.updateJukeboxConfig(this.jukeboxConfiguration);
            } else if ('img_header' === image_section) {
              this.jukeboxConfiguration.imgUrlPlaylistHeader = url;
              this.adminService.updateJukeboxConfig(this.jukeboxConfiguration);
            }
            this.updateUI();
            this.isLoading = false;
          });
      })
    )
    .subscribe();
  }

  onClickDeletePopup(adImageId: string) {
    this.alog.debug('onClickDeletePopup adImageId: ', adImageId);
    this.adminService.deletePopupImage(this.jukeboxConfiguration.id, adImageId);
  }

  onClickToogleIsActive(adImageId: string) {
    this.alog.debug('onClickDeletePopup adImageId: ', this.jukeboxConfiguration.imgs_url.popups[adImageId]);
    const adImage: AdImage = this.jukeboxConfiguration.imgs_url.popups[adImageId];
    adImage.isActive = !adImage.isActive;
    this.adminService.updatePopupImage(this.jukeboxConfiguration.id, adImage);
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.jukeboxConfigurationSubscription.unsubscribe();
  }

}