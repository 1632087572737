import { Component, OnInit, Input } from '@angular/core';
import { Track } from 'src/app/shared/models/track.model';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/shared/services/admin.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-track-item-admin',
  templateUrl: './track-item-admin.component.html',
  styleUrls: ['./track-item-admin.component.css']
})
export class TrackItemAdminComponent implements OnInit {

  @Input() track: Track;

  trackSelected: boolean = false;
  tracksSelectedLength: number = 0;
  tracksSelectedSubscription: Subscription;


  constructor( private adminService: AdminService ) { }


  ngOnInit() {

    this.tracksSelectedSubscription = this.adminService.tracksToDeleteChanged
      .subscribe(
        (tracks: Track[]) => {
          this.tracksSelectedLength = tracks.length;
          if (tracks.length == 0) {
            this.trackSelected = false;
          }
          
          for (let iTrack of tracks) {
            if (iTrack.id === this.track.id) {
              this.trackSelected = true;
              break;
            }
          }
        }
      );

    this.tracksSelectedLength = this.adminService.tracksToDelete.length;

    for (let iTrack of this.adminService.tracksToDelete) {
      if (iTrack.id === this.track.id) {
        this.trackSelected = true;
      }
    }

  }


  onTrackSelected() {
    
    if (this.trackSelected) {
      this.adminService.removeSelectedTrack(this.track);
      this.trackSelected = false;
    } else {
      this.adminService.addSelectedTrack(this.track);
      this.trackSelected = true;
    }

  }


}
