


import * as fromJukeboxConfiguration from '../actions/jukebox-configuration.actions';
import { JukeboxConfig } from '../../models/jukebox.model';


export interface JukeboxConfigurationState {
  jukeboxConfiguration: JukeboxConfig;
}

const initState: JukeboxConfigurationState = {
  jukeboxConfiguration: null
};

export function jukeboxConfigurationReducer( 
                  state = initState, 
                  action: fromJukeboxConfiguration.Actions ): JukeboxConfigurationState {

    switch ( action.type ) {

        case fromJukeboxConfiguration.SET_JUKEBOX_CONFIGURATION:
          
            return {
              jukeboxConfiguration: {
                ...action.jukeboxConfiguration
              }
            };
    
        case fromJukeboxConfiguration.UPDATE_JUKEBOX_CONFIGURATION:

            return {
                ...state
            };

        case fromJukeboxConfiguration.UNSET_JUKEBOX_CONFIGURATION:

            return {
                jukeboxConfiguration: null
            };

        default:
            return state;
    }
}